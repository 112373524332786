import '../user_module.scss'
import {Select} from "antd";
import Kol from "../Collection/kol";
import {useEffect, useState} from "react";
import People from "../Collection/people";
import Meeting from "../Collection/meeting";
import Other from "../Collection/other";
import Investor from "../Collection/investor";
import Project from "../Collection/project";
import userApi from '@/api/user'
import {projectTypeObj} from "../../../enum/projectType";
import {useTranslation} from "react-i18next";
import Pagination from "../../../components/Pagination";
import useSyncCallback from "../../../utils/useSyncCallback";
import All from '../Collection/all'
import {useSelector} from "react-redux";
import {queryLikeAllList} from "../../../utils/middle";

function Collection() {
    const {t} = useTranslation()
    const lang = useSelector(state => state.language.value)
    const [type,setType] = useState("")
    const [likeCount, setLikeCount] = useState()
    const [options,setOptions] = useState([])
    const [pageSize, setPageSize] = useState(9)
    const [pageNum, setPageNum] = useState(1)
    const [listData,setListData] = useState({
        total: 0,
        list: []
    })
    useEffect(()=>{
        let list = [{value: '',label: t('all')}]
        for (const item in projectTypeObj) {
            if (projectTypeObj[item].type !== "6" && projectTypeObj[item].type !== "7") {
                list.push({
                    value: projectTypeObj[item].type,
                    label: t(projectTypeObj[item].label)
                })
                setOptions(()=>{
                    return [...list]
                })
            }
        }
        getLikeCount()
        getList()
    },[])

    useEffect(()=>{
        setListData(()=>{
            return {
                total: 0,
                list: []
            }
        })
        getList()
    },[type])

    function getLikeCount() {
        userApi.queryLikeCount().then(res=>{
            setLikeCount(res.data)
        })
    }

    const getList = useSyncCallback(()=>{
        if (type === "") {
            return queryLikeAllList({pageNum,pageSize,lang: lang.value},(res)=>{
                setListData(()=>{
                    return {
                        total : res.total,
                        list : res.rows
                    }
                })
            })
        }
        userApi.queryUserLike({
            pageNum,
            pageSize,
            inclusionType: type,
            lang: lang.value
        }).then(res=>{
            setListData(()=>{
                return {
                    total : res.total,
                    list : res.rows
                }
            })
        })
    })

    function changePage(e) {
        setPageNum(e)
        getList()
    }
    return (
        <div className={'profile_main_block'}>
            <div className={'profile_main_block_title'}>My Like</div>
            <div className={'collection_main_like_block'}>
                <div className={'collection_main_like_block_all'}>
                    <img className={'collection_main_like_icon'}
                         src={require('../../../assets/image/user/icon_all_likes.png')} alt='all'/>
                    <div className={'collection_main_like_name'}>All</div>
                    <div className={'collection_main_like_number'}>{likeCount?.total}</div>
                </div>
                <div className={'collection_main_like_block_type'}>
                    <div className={'collection_main_like_block_type_child'}>
                        <img className={'collection_main_like_icon'}
                             src={require('../../../assets/image/user/icon_Projects_likes.png')} alt='all'/>
                        <div className={'collection_main_like_name'}>Projects</div>
                        <div className={'collection_main_like_number'}>{likeCount?.proCount}</div>
                    </div>
                    <div className={'collection_main_like_block_type_child'}>
                        <img className={'collection_main_like_icon'}
                             src={require('../../../assets/image/user/icon_Investors_likes.png')} alt='all'/>
                        <div className={'collection_main_like_name'}>Investors</div>
                        <div className={'collection_main_like_number'}>{likeCount?.invCount}</div>
                    </div>
                    <div className={'collection_main_like_block_type_child'}>
                        <img className={'collection_main_like_icon'}
                             src={require('../../../assets/image/user/icon_people_likes.png')} alt='all'/>
                        <div className={'collection_main_like_name'}>People</div>
                        <div className={'collection_main_like_number'}>{likeCount?.figCount}</div>
                    </div>
                    <div className={'collection_main_like_block_type_child'}>
                        <img className={'collection_main_like_icon'}
                             src={require('../../../assets/image/user/icon_meeting_likes.png')} alt='all'/>
                        <div className={'collection_main_like_name'}>Meeting</div>
                        <div className={'collection_main_like_number'}>{likeCount?.meetCount}</div>
                    </div>
                    <div className={'collection_main_like_block_type_child'}>
                        <img className={'collection_main_like_icon'}
                             src={require('../../../assets/image/user/icon_kol_likes.png')} alt='all'/>
                        <div className={'collection_main_like_name'}>Kol</div>
                        <div className={'collection_main_like_number'}>{likeCount?.kolCount}</div>
                    </div>
                    <div className={'collection_main_like_block_type_child'}>
                        <img className={'collection_main_like_icon'}
                             src={require('../../../assets/image/user/icon_other_likes.png')} alt='all'/>
                        <div className={'collection_main_like_name'}>Other</div>
                        <div className={'collection_main_like_number'}>{likeCount?.otherCount}</div>
                    </div>
                </div>
            </div>
            <div className={'collection_select'}>
                <Select defaultValue={type} options={options} style={{width: 234, height: 32}} onChange={(e) => {
                    setPageSize(()=>{
                        let count = 9
                        if (e === "2"){
                            count = 8
                        }else if (e === "3" || e === "4"){
                            count = 6
                        }else {
                            count = 9
                        }
                        return count
                    })
                    setType(e)
                }}/>
            </div>
            <div className={'collection_list'}>
                {type === "" && (<All list={listData.list}/>)}
                {type === "0" && (<Project list={listData.list}/>)}
                {type === "1" && (<Investor list={listData.list}/>)}
                {type === "2" && (<People list={listData.list}/>)}
                {type === "3" && (<Meeting list={listData.list}/>)}
                {type === "4" && (<Kol list={listData.list}/>)}
                {(type === "5" || type === "6" || type === "7") && (<Other type={type} list={listData.list}/>)}
                <div className={'collection_page_block'}>
                    <Pagination total={listData.total} size={pageSize} page={pageNum}
                                onChange={changePage}></Pagination>
                </div>
            </div>
        </div>
    )
}

export default Collection;
