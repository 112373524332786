import "../../Included/included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import { Form, Input, DatePicker, Select, Spin } from 'antd';
import Upload from '@/components/Upload/index'
import {useSelector} from "react-redux";
import {createRef, useEffect, useState} from "react";
import {formatWithSeparator} from "@/utils/formatUtils";
import Radio from '@/components/Radio/index'
import message from "../../../utils/message";
import useSyncCallback from "../../../utils/useSyncCallback";
import projectsApi from "../../../api/projects";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import characterApi from "../../../api/character";
import Language from "../../../components/Language";
import {auditStatusObj} from "../../../enum/auditStatus";
import userApi from '@/api/user'
import commonApi from "../../../api/common";
import {getTranslation, setReview} from "../../../utils/middle";
import includedApi from "../../../api/included";
import {web3,projectContract,playerContract} from '../../../utils/web3Utils'
import Region from '@/components/Region'
import investorApi from '@/api/investor'
import Modal from '../../../components/Modal/index'

function Project() {
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const {id} = useParams()
    const [form] = Form.useForm()
    const language = useSelector(state=>state.language.value)
    const [searchParams,setSearchParams] = useSearchParams()
    const radioRef = createRef()
    const operationRef = createRef()
    const auditRadio = createRef()
    const tokenRadio = createRef()
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const userInfo = useSelector(state=>state.user.value)
    const [ledCheckList, setLedCheckList] = useState([])
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [tab,setTab] = useState([])
    const [tabList,setTabList] = useState([])
    const [ecology,setEcology] = useState('')
    const [ecologyList,setEcologyList] = useState([])
    const [mediaList,setMediaList]=useState([])
    const [spinning, setSpinning] = useState(false)
    const radioList = [
        {value: '1',label: t('UnissuedCoins'),id: '1',text: ''},
        {value: '0',label: t('IssuedCoins'),id: '0',text: ''},
        {value: '2',label: t('Empty'),id: '2',text: t('EmptyText')},
        {value: '3',label: t('NoTokens'),id: '3',text: ''}
    ]
    const methodRedioList = [
        {value: 0,label: t("PublicOfferingPrice"),id: 0,text: ''},
        {value: 1,label: t("mining"),id: 0,text: ''}
    ]
    const [companyList, setCompanyList] = useState([])
    const [auditReport,setAuditReport] = useState(null)
    const [eventList,setEventList] = useState([])
    const [logoUrl,setLogoUrl] = useState()
    const [name,setName] = useState('')
    const [officialWebsite,setOfficialWebsite] = useState('')
    const [introduction,setIntroduction] = useState('')
    const [content,setContent] = useState('')
    const [establishTime,setEstablishTime] = useState('')
    const [location,setLocation] = useState('')
    const [whitePaperLink,setWhitePaperLink] = useState('')
    const [whitePaperUrl,setWhitePaperUrl] = useState('')
    const [tokenAbbreviation, setTokenAbbreviation] = useState('')
    const [coinIssuanceSatus, setCoinIssuanceSatus] = useState('')
    const [publicOfferingPrice,setPublicOfferingPrice] = useState('')
    const [operateStatus, setOperateStatus] = useState('')
    const [auditReportStatus,setAuditReportStatus] = useState('')
    const [auditReportUrl,setAuditReportUrl] = useState('')
    const [teamMember,setTeamMember] = useState([])
    const [tokenType, setTokenType] = useState('')
    const [auditFirms, setAuditFirms] = useState('')
    const [companyLogo, setCompanyLogo] = useState('')
    const [auditTime, setAuditTime] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [lang, setLang] = useState(0)
    const [oldLang, setOldLang] = useState(0)
    const [workList,setWorkList] = useState([])
    const [createBy, setCreateBy] = useState('')
    const [updateBy, setUpdateBy] = useState('')
    const [createTime, setCreateTime] = useState('')
    const eventSelectList = [
        {value: t("TestOnlineCable"), label: t("TestOnlineCable")},
        {value: t("MainOnlineCable"), label: t("MainOnlineCable")},
        {value: t("AttackedAmountInUSDollars"), label: t("AttackedAmountInUSDollars")},
    ]
    const [investmentList,setInvestmentList] = useState([])
    const [detailInfo,setDetailInfo] = useState({})
    const [memberPage,setMemberPage] = useState(1)
    const [memberlList, setMemberList] = useState([])
    const [memberListData,setMemberListData] = useState([])
    const [checkMemberList,setCheckMemberList] = useState([])
    const [thisId, setThisId] = useState('')
    const [tagDataList, setTagDataList] = useState([])
    const [invesList,setInvesList] = useState([])
    const [remarkModel, setRemarkModel] = useState(false)
    const [qmHash, setQmHash] = useState('')
    const investmentItem = {
        round: '',
        amount: null,
        valuation: null,
        time: '',
        led: '',
        ledInstitution:'',
        investor: '',
        link: ''
    }
    const [staked,setStaked] = useState(0)
    const [address,setAddress] = useState('')
    const [investmentDatailList,setInvestmentDatailList] = useState([])
    const investmentDataItem = {
        projectName: '',
        round: '',
        amount: null,
        valuation: null,
        time: '',
        ledStatus: '',
        link: '',
        investor: ''
    }
    useEffect(() => {
        web3.eth.getAccounts().then((res)=>{
            if (!res[0]) {
                res = window.ethereum.request({method: 'eth_requestAccounts'})
            }
            playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                getTagList()
                getOfficeList()
                getMember()
                Promise.all([
                    investorApi.queryInvestorList({pageNum: 1, pageSize: 100, lang: 0, type: 0, sort: 0}),
                    characterApi.queryCharacterList({ pageNum: memberPage, pageSize: 10, name: name || void 0, sort: 0, lang: 0, type: 0}),
                    projectsApi.queryProjectsList({pageNum: 1, pageSize: 100, lang: 0, type: 0, sort: 0,})
                ]).then(res=>{
                    const resArr = [...res[0].rows,...res[1].rows,...res[2].rows]
                    setInvesList(()=>{
                        return resArr.map(item=>{
                            return {
                                label: item.name,
                                value: item.id,
                                type: 2,
                                logo: item?.logoUrl || item?.headImgUrl
                            }
                        })
                    })
                    queryProjectDetail()
                })
            })
        })
    }, []);

    const getTagList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'project_lable',
            dictLabel: text
        }).then(res=>{
            setTagDataList(res.rows)
        })
    })

    const addWorkExperience = useSyncCallback(()=>{
        setWorkList(e=>{
            let list = [...e]
            list.push({expId: '',post: '',time: [],name: '',url: '',mainId: ''})
            return [...list]
        })
    })

    const addMemberValue = useSyncCallback(()=>{
        let list = memberListData.filter(item=>{
            for (const data of teamMember) {
                if (item.id === data) return item
            }
        })
        setCheckMemberList([...list])
    })

    const delMemberValue = useSyncCallback((id)=>{
        let list = teamMember.filter(item=>item !== id)
        setTeamMember([...list])
        addMemberValue()
    })

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addTabs() {
        if (tab) {
            console.log(tab)
            setTabList((e)=>{
                return [...new Set([...e,...tab])]
            })
            setTab([])
        }
    }

    function delTabs(index) {
        const list = [...tabList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setTabList(list)
            }
        })
    }

    const getMember = useSyncCallback((name = void 0)=>{
        characterApi.queryCharacterList({
            pageNum: memberPage,
            pageSize: 10,
            name: name || void 0,
            sort: 0,
            lang: 0,
            type: 0
        }).then(res=>{
            setMemberListData(res.rows)
            setMemberList(()=>{
                return res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id,
                        url: item.headImgUrl
                    }
                })
            })
            setInvesList((e)=>{
                return [...e,...res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id,
                        type: 3,
                        logo: item.headImgUrl
                    }
                })]
            })
        })
    })

    function addEcology() {
        if (ecology) {
            setEcologyList((e)=>{
                console.log(ecology)
                return [...e,ecology]
            })
            setEcology('')
        }
    }

    function delEcology(index) {
        const list = [...ecologyList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setEcologyList(list)
            }
        })
    }

    function addMediaLink() {
        const data = {webType: media[0].value,webUrl: ''}
        const list = [...mediaList]
        list.push(data)
        setMediaList(list)
    }

    function handleChange(value) {
        console.log(value)
        setAuditFirms(value)
        if (value === companyList[companyList.length-1].value){
            setCompanyLogo('')
            setCompanyName('')
        }
    }

    function eventHandlerChange(value,index) {
        console.log(value,index)
        setEventList((e)=>{
            const list = [...e]
            list[index].event = value
            return list
        })
    }

    function logoSuccess(e) {
        console.log(e)
        setLogoUrl(e.data)
    }

    function pdfSuccess(e) {
        const helfLink = e.data
        setWhitePaperLink(baseUrl+helfLink)
        setWhitePaperUrl(helfLink)
    }
    const submitForm = useSyncCallback((submitType)=>{
        setSpinning(true)
        const eventListMap = eventList.map(item=>{
            let timeObj = {}
            if (item.time) {
                timeObj = {...item}
                timeObj.time = timeObj.time.format('YYYY-MM-DD')
            }
            return timeObj
        })

        const investmentListMap = investmentList.map(item=>{
            let timeObj = {}
            if (item.time) {
                timeObj = {...item}
                timeObj.time = timeObj.time.format('YYYY-MM-DD')
                timeObj.ledInstitution = timeObj.ledInstitution.toString()
                timeObj.investor = timeObj.investor.toString()
            }
            return timeObj
        })
        const workListMap = workList.map(item=>{
            let timeObj = {}
            if (item.time) {
                timeObj = {...item}
                const list = timeObj.time.map(params=>{
                    if (!params) return ''
                    return params.format('YYYY-MM-DD HH:mm:ss')
                })
                timeObj['remark'] = name
                timeObj['purl'] = logoUrl
                timeObj.time = list.toString()
            }
            return timeObj
        })
        let investDetailsList = []
        let investmentListDataMap = []

        for(let item of investmentListMap){
            investDetailsList = [
                ...investDetailsList,
                ...item.investor.split(",").map(res=>{
                    const obj = invesList.find(item=>item.value === res)
                    console.log("find",obj)
                    if (obj?.type !== 1) {
                        return {
                            projectName: name,
                            synopsis: introduction,
                            pid: obj?.value,
                            logo: logoUrl,
                            coinIssuanceSatus,
                            label: tabList.toString(),
                            round: item.round,
                            amount: item.amount,
                            valuation: item.valuation,
                            time:item.time,
                            led: item.ledInstitution.split(",").indexOf(res) === -1?0:1
                        }
                    }else {
                        return null
                    }
                }).filter(item=>item !== null)
            ]
            investmentListDataMap = [
                ...investmentListDataMap,
                ...item.investor.split(",").map(res=>{
                    const obj = invesList.find(item=>item.value === res)
                    console.log("find",obj)
                    if (obj?.type === 1) {
                        return {
                            projectName: name,
                            pid: obj.value,
                            round: item.round,
                            amount: item.amount,
                            valuation: item.valuation,
                            time:item.time,
                            led: item.ledInstitution.split(",").indexOf(res) === -1?0:1,
                            link: item.link
                        }
                    }else {
                        return null
                    }
                }).filter(item=>item !== null)
            ]
        }
        console.log("auditTimeauditTimeauditTimeauditTimeauditTime",auditTime)
        const data = {
            logoUrl,name,officialWebsite,introduction,content,label: tabList.toString(),ecology: ecologyList.toString(),establishTime: dayjs(establishTime).format('YYYY-MM'),location,whitePaperLink,whitePaperUrl,coinIssuanceSatus,tokenAbbreviation,publicOfferingPrice,operateStatus,auditReportStatus,auditReportUrl,auditFirms,companyLogo,auditTime: auditTime?auditTime.format('YYYY-MM-DD'):'',companyName,tokenType,smcList: mediaList,eventList: eventListMap,projectInvestDetailsList: investmentListMap,foreignInvestList: investmentListDataMap,submitType,lang,mainId: detailInfo.mainId,workExperienceList: workListMap, createBy,createTime,updateTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss"),investDetailsList
        }
        if (submitType === 0){
            projectsApi.updateProjectsDraft({...data,id: thisId}).then(res=> {
                const obj = res.data
                setSpinning(false)
                message.success(t('saveSuccess'))
            }).catch(err=>{
                includedApi.submitProjectForm(data).then(res=> {
                    const obj = res.data
                    setSpinning(false)
                    message.success(t('saveSuccess'))
                })
            })
        }else if (submitType === 1){
            if (staked < 100 ) {
                setSpinning(false)
                message.fail(t('NoIncluded'))
                return navigate("/user/wiki")
            }
            if (data.smcList.length === 0) return message.fail(t('inputMediaLink'))
            projectContract.methods.projectBase(detailInfo.mainId).call().then(res=>{
                const dataBase = web3.utils.fromWei(res["0"],'ether')
                const firstQmHash = res.firstQmHash
                if ((dataBase === '0.') || (qmHash === firstQmHash && searchParams.get("update")==='1')) {
                    data.mainId = void 0
                    includedApi.submitProjectForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")}).then(res=>{
                        const obj = res.data
                        console.log('obj',obj)
                        console.log('address',address)
                        projectContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            console.log('上链参数',res)
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        if(searchParams.get("draftId")) {
                                            userApi.delUserDraft(searchParams.get("draftId")).then(res=>{
                                                navigate(-1)
                                            })
                                        }
                                    })
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                            }
                            setSpinning(false)
                        }).catch((err)=>{
                            console.log(err)
                            setSpinning(false)
                        })
                    })
                }else {
                    if((updateBy == userInfo.userId && searchParams.get("update") !== '1') && (createBy == userInfo.userId && searchParams.get("update") !== '1')) {
                        setSpinning(false)
                        return message.fail(t("createUpdateFail"))
                    }
                    projectsApi.updateProjectsDetail({...data,oldLang: oldLang,oldId: id}).then((res)=> {
                        if (res.data.msg) {
                            if (language.value === "0"){
                                return getTranslation(res.data.msg,'zh','en',(res)=>{
                                    message.fail(res.data.TargetText)
                                })
                            }else {
                                return message.fail(res.data.msg)
                            }

                        }
                        const obj = res.data
                        projectContract.methods.updateWiki(obj.mId, obj.cId, obj.lang, obj.qmHash).send({from: walletAddress}).then((res) => {
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                    })
                                })
                            } else {
                                message.fail(t('DenialAuthorization'))
                            }
                            setSpinning(false)
                        }).catch((err) => {
                            console.log(err)
                            projectsApi.delProject(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }
            })
        }else {
            console.log("{...data,ledCheckList}",{...data,ledCheckList} )
            setReview({...data,ledCheckList},(res)=>{
                navigate('/project/review?id='+res.data)
                setSpinning(false)
            })
        }
    })

    function successComLogo(e) {
        setCompanyLogo(e.data)
    }

    function auditPdfSuccess(e) {
        setAuditReportUrl(baseUrl+e.data)
    }

    const queryProjectDetail = useSyncCallback(()=>{
        console.log("searchParams.get('type')",searchParams.get('type'),id)
        if (searchParams.get('type')) {
            projectsApi.queryProjectsDetailFromDB({id}).then(res=>{
                setDetailInfo({...res.data})
                setLang(res.data.lang)
                setLogoUrl(res.data.logoUrl)
                setName(res.data.name)
                setOfficialWebsite(res.data.officialWebsite)
                setIntroduction(res.data.introduction)
                setContent(res.data.content)
                setTabList(res.data.label?[...res.data.label.split(',')]:[])
                setEcologyList(res.data.ecology?[...res.data.ecology.split(',')]:[])
                setEstablishTime(res.data.establishTime?dayjs(res.data.establishTime):void 0)
                setLocation(res.data.location)
                setThisId(res.data.id)
                setCreateBy(res.data.createBy || void 0)
                setUpdateBy(res.data.updaeBy || void 0)
                setCreateTime(res.data.createTime || void 0)
                setQmHash(res.data.qmHash)
                setWorkList(()=>{
                    return res.data.workExperienceList.map(item=>{
                        return {
                            expId: item.expId,
                            post: item.post,
                            time: item.time?item.time.split(',').map(item=>{
                                if (!item) return null
                                return dayjs(item)
                            }):[null],
                            name: item.name,
                            url: item.url,
                            mainId: item.mainId
                        }
                    })
                })
                let list = res.data.smcList?.map(item=>{
                    return {
                        webType: item.webType,
                        webUrl: item.webUrl
                    }
                }) || []
                setMediaList([...list])
                setWhitePaperLink(res.data.whitePaperLink)
                setCoinIssuanceSatus(res.data.coinIssuanceSatus)
                setOperateStatus(Number(res.data.operateStatus))
                setAuditReportStatus(Number(res.data.auditReportStatus))
                setTokenAbbreviation(res.data.tokenAbbreviation)
                setTokenType(Number(res.data.tokenType))
                setPublicOfferingPrice(res.data.publicOfferingPrice)
                setAuditFirms(res.data.auditFirms)
                setAuditReportUrl(res.data.auditReportUrl)
                setAuditTime(res.data.auditTime?dayjs(res.data.auditTime):"")
                setCompanyName(res.data.companyName)
                setCompanyLogo(res.data.companyLogo)
                form.setFieldsValue({
                    ProjectName: res.data.name,
                    ProjectOfficialWebsite: res.data.officialWebsite,
                    ProjectIntroduction: res.data.introduction,
                    Introduction: res.data.content,
                    EstablishmentTime: res.data.establishTime?dayjs(res.data.establishTime):null,
                    location: res.data.location
                })
                const data = {
                    pageNum: 1,
                    pageSize: 20,
                    inclusionId: id
                }
                Promise.all([
                    commonApi.queryKeyEvent(data),
                    commonApi.queryForeignInvest(data),
                    commonApi.queryProjectInvestDetails(data)
                ]).then((result)=>{
                    setEventList(()=>{
                        return result[0].rows.map(item => {
                            return {
                                event: item.event,
                                time: dayjs(item.time),
                                link: item.link
                            }
                        })
                    })
                    setInvestmentDatailList(()=>{
                        return result[1].rows.map(item=>{
                            return {
                                projectName: item.projectName,
                                round: item.round,
                                amount: item.amount,
                                valuation: item.valuation,
                                time: dayjs(item.time),
                                ledStatus: item.ledStatus,
                                link: item.link
                            }
                        })
                    })
                    setInvestmentList(()=>{
                        return result[2].rows.map(item=>{


                            setLedCheckList(e=>{
                                let list = item.investor.split(',').map(res=>{
                                    return invesList.find(params=>res == params.value) || null
                                }).filter(data=>data !== null)
                                console.log("invesList.find(params=>res === params.value)",list)
                                return [...e,...list]
                            })
                            return {
                                round: item.round,
                                amount: item.amount,
                                valuation: item.valuation,
                                time: dayjs(item.time),
                                led: item.led,
                                investor: item.investor,
                                link: item.link,
                                ledInstitution: item.ledInstitution
                            }
                        })
                    })
                })
            })
        }else {
            projectsApi.queryProjectsDetail({id}).then(res=>{
                setDetailInfo({...res.data})
                setLang(res.data.lang)
                setOldLang(res.data.lang)
                setLogoUrl(res.data.logoUrl)
                setName(res.data.name)
                setOfficialWebsite(res.data.officialWebsite)
                setIntroduction(res.data.introduction)
                setContent(res.data.content)
                setTabList(res.data.label?[...res.data.label.split(',')]:[])
                setEcologyList(res.data.ecology?[...res.data.ecology.split(',')]:[])
                setEstablishTime(res.data.establishTime?dayjs(res.data.establishTime):null)
                setLocation(res.data.location)
                setCreateBy(res.data.createBy || void 0)
                setUpdateBy(res.data.updateBy || void 0)
                setCreateTime(res.data.createTime || void 0)
                setQmHash(res.data.qmHash)
                let list = res.data.smcList.map(item=>{
                    return {
                        webType: item.webType,
                        webUrl: item.webUrl
                    }
                })
                setMediaList([...list])
                setWhitePaperLink(res.data.whitePaperLink)
                setCoinIssuanceSatus(res.data.coinIssuanceSatus)
                setOperateStatus(Number(res.data.operateStatus))
                setAuditReportStatus(Number(res.data.auditReportStatus))
                setCheckMemberList(JSON.parse(res.data.teamMember))
                setThisId(res.data.id)
                setWorkList(()=>{
                    return res.data.workExperienceList.map(item=>{
                        return {
                            expId: item.expId,
                            post: item.post,
                            time: item.time?item.time.split(',').map(item=>{
                                console.log('item',item)
                                if (!item) return null
                                return dayjs(item)
                            }):[null],
                            name: item.name,
                            url: item.url,
                            mainId: item.mainId
                        }
                    })
                })
                let event = res.data.eventList.map(item=>{
                    return {
                        event: item.event,
                        time: dayjs(item.time),
                        link: item.link
                    }
                })
                setEventList([...event])
                let investmentData = res.data.projectInvestDetailsList.map(item=>{
                    console.log('invesListinvesListinvesList',invesList)
                    setLedCheckList(e=>{
                        let list = item.investor.split(',').map(res=>{
                            return invesList.find(params=>res == params.value) || null
                        }).filter(data=>data !== null)
                        console.log("invesList.find(params=>res === params.value)",list)
                        return [...e,...list]
                    })
                    return {
                        round: item.round,
                        amount: item.amount,
                        valuation: item.valuation,
                        time: dayjs(item.time),
                        led: item.led,
                        investor: item.investor,
                        link: item.link,
                        ledInstitution: item.ledInstitution
                    }
                })
                setInvestmentList([...investmentData])
                let investmentDataObj = res.data.foreignInvestList.map(item=>{
                    return {
                        projectName: item.projectName,
                        round: item.round,
                        amount: item.amount,
                        valuation: item.valuation,
                        time: dayjs(item.time),
                        ledStatus: item.ledStatus,
                        link: item.link
                    }
                })
                setInvestmentDatailList([...investmentDataObj])
                setTokenAbbreviation(res.data.tokenAbbreviation)
                setTokenType(Number(res.data.tokenType))
                setPublicOfferingPrice(res.data.publicOfferingPrice)
                setAuditFirms(res.data.auditFirms)
                setAuditReportUrl(res.data.auditReportUrl)
                setAuditTime(res.data.auditTime?dayjs(res.data.auditTime):"")
                setCompanyName(res.data.companyName)
                setCompanyLogo(res.data.companyLogo)
                form.setFieldsValue({
                    ProjectName: res.data.name,
                    ProjectOfficialWebsite: res.data.officialWebsite,
                    ProjectIntroduction: res.data.introduction,
                    Introduction: res.data.content,
                    EstablishmentTime: res.data.establishTime?dayjs(res.data.establishTime):null,
                    location: res.data.location
                })
            })
        }

    })

    const getOfficeList = useSyncCallback(()=>{
        projectsApi.queryOfficeList().then(res=>{
            console.log('12314445',res)
            const officeList = res.rows.map(item=>{
                return {
                    value: item.id,
                    icon: baseUrl+item.url,
                    name: item.name,
                    node: (
                        <div className={'included_form_audit_options_node'}>
                            <img style={{borderRadius: '50%'}} src={baseUrl + item.url} alt='logo'/>{item.name}
                        </div>)
                }
            })
            officeList.push({value: "", icon: undefined,name: t('OtherAuditFirms'), node: (
                    <div className={'included_form_audit_options_node'}>
                        {t('OtherAuditFirms')}
                    </div>)
            })
            setCompanyList(officeList)
        })
    })

    return (
        <>
            <div className={'main_back_white main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title'}>{t('projectIncluedTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <Language lang={lang} onChange={e => setLang(e)}></Language>
                <div className={'included_form'}>
                    <Form layout="vertical" initialValues={{name: 123}} name="project" autoComplete="off" form={form}>
                        <Form.Item name="logo" label={labelNode('Logo', t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={logoSuccess}>
                                {
                                    !logoUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    logoUrl && (
                                        <img src={baseUrl + logoUrl} className={'included_upload_logo'}/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'ProjectName'} label={labelNode(t('projectName'))}
                                       rules={[{required: true}]}>
                                <Input value={name} onChange={(e) => {
                                    setName(e.target.value)
                                }} className={"included_form_input"} placeholder={t("projectNamePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'ProjectOfficialWebsite'}
                                       label={labelNode(t('ProjectOfficialWebsite'))} rules={[{required: true}]}>
                                <Input value={officialWebsite} onChange={(e) => {
                                    setOfficialWebsite(e.target.value.replace(/\s/g, ""))
                                }} className={"included_form_input"}
                                       placeholder={t("ProjectOfficialWebsitePlaceholder")}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'ProjectIntroduction'} label={labelNode(t('ProjectIntroduction'))}
                                   rules={[{required: true}]}>
                            <Input value={introduction} onChange={(e) => {
                                setIntroduction(e.target.value)
                            }} maxLength={100} showCount className={"included_form_input"}/>
                        </Form.Item>
                        <Form.Item name={'Introduction'} label={labelNode(t('Introduction'))}
                                   rules={[{required: true}]}>
                            <Input.TextArea value={content} onChange={(e) => {
                                setContent(e.target.value)
                            }} className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <Form.Item name={'Tabs'} label={labelNode(t('Tabs'))} rules={[{required: true}]}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false} fieldNames={{label: 'dictLabel', value: 'dictLabel'}}
                                    className={'included_form_add_input included_form_input'}
                                    options={tagDataList} onSearch={getTagList} value={tab} onChange={e => {
                                    setTab(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                    <img src={require('@/assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {tabList.length > 0 && (
                                    <>
                                        {tabList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delTabs(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name={'ProjectEcology'} label={labelNode(t('ProjectEcology'))} rules={[{required: true}]}>
                            <div className={'included_form_tag_input'}>
                                <Input className={'included_form_add_input included_form_input'} value={ecology}
                                       onChange={(e) => {
                                           setEcology(e.target.value)
                                       }} suffix={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }/>
                                <div className={'included_form_tag_add_button'} onClick={addEcology}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {ecologyList.length > 0 && (
                                    <>
                                        {ecologyList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delEcology(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'EstablishmentTime'}
                                       label={labelNode(t('EstablishmentTime'))}
                                       rules={[{required: true}]}>
                                <DatePicker value={establishTime} picker='month' onChange={(e) => {
                                    if(!e) {
                                        return setEstablishTime(null)
                                    }
                                    setEstablishTime(e.format('YYYY-MM'))
                                }} className={"included_form_input included_form_datepicker"} inputReadOnly/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'location'}
                                       label={labelNode(t('location'))} rules={[{required: true}]}>
                                <Region value={location} change={(e) => {
                                    setLocation(e)
                                    form.setFieldsValue({location: e})
                                }}></Region>
                            </Form.Item>
                        </div>
                        <Form.Item name={'SocialMediaLinks'} label={labelNode(t('SocialMediaLinks'))}
                                   rules={[{required: true}]}>
                            <div>
                                {
                                    mediaList.map((item, index) => {
                                        return (
                                            <div className={'included_media_item'}
                                                 style={index !== 0 ? {marginTop: 12} : {}}>
                                                <Select defaultValue={item.webType} value={item.webType} options={media} onChange={(e) => {
                                                    setMediaList((item) => {
                                                        const list = [...item]
                                                        list[index].webType = e
                                                        return list
                                                    })
                                                }}
                                                        style={{width: 177, height: 50}}/>
                                                <Input value={item.webUrl} onChange={(e) => {
                                                    setMediaList((item) => {
                                                        const list = [...item]
                                                        list[index].webUrl = e.target.value.replace(/\s/g, "")
                                                        return list
                                                    })
                                                }} className={"included_form_input included_media_link_input"}/>
                                                <div className='included_form_event_del'
                                                     style={{lineHeight: '50px'}}
                                                     onClick={() => setMediaList(e => {
                                                         const list = e.filter((item, key) => index !== key)
                                                         return [...list]
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={'included_form_tag_add_button included_media_add'}
                                     onClick={addMediaLink}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    {t('ContinueToAdd')}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name={'whitePaper'} label={labelNode(t('whitePaper'))}>
                            <div className={'included_form_white_paper'}>
                                <Input value={whitePaperLink} onChange={(e) => setWhitePaperLink(e.target.value)}
                                       className={"included_form_input included_form_datepicker"}
                                       placeholder={t("whitePaperPlaceholder")}/>
                                <Upload onSuccess={pdfSuccess}>
                                    <div className={'included_upload_button'}>
                                        <img src={require("@/assets/image/included/button_upload_icon.png")}
                                             alt='icon'/>
                                        <span>{t('upload')} PDF</span>
                                    </div>
                                </Upload>
                            </div>
                        </Form.Item>
                        <Form.Item name={'CoinIssuanceSituation'} label={labelNode(t('CoinIssuanceSituation'))}
                                   rules={[{required: true}]}>
                            <Radio list={radioList} data={coinIssuanceSatus} onChange={(e) => {
                                setCoinIssuanceSatus(_=> {
                                    setTokenAbbreviation('')
                                    setPublicOfferingPrice('')
                                    setTokenType('')
                                    return e
                                })
                            }}></Radio>
                            <div style={coinIssuanceSatus !== '0' ? {display: 'none'} : {}}
                                 className={'included_form_coin_block'}>
                                <div className={'included_form_coin_tokenAdd'}>
                                    <span>{t('TokenAbbreviation')}:</span>
                                    <Input value={tokenAbbreviation}
                                           onChange={e => setTokenAbbreviation(e.target.value)}
                                           placeholder={t('TokenAbbreviationPlaceholder')}
                                           className={"included_form_input included_form_token_abbreviation"}/>
                                </div>
                                <Radio list={methodRedioList} data={tokenType} onChange={(e) => {
                                    setTokenType(e)
                                    setPublicOfferingPrice(null)
                                }}></Radio>
                                <div style={tokenType !== 0 ? {display: 'none'} : {}}
                                     className={'included_form_coin_tokenAdd included_form_coin_Price'}>
                                    <span>{t('PublicOfferingPrice')}:</span>
                                    <Input value={publicOfferingPrice}
                                           onChange={e => setPublicOfferingPrice(e.target.value)}
                                           placeholder={t('TokenAbbreviationPlaceholder')} suffix={
                                        <span className={'included_form_input_suffix_icon'}>$</span>
                                    } className={"included_form_input included_form_token_public"}/>
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name={'OperationSituation'} label={labelNode(t('OperationSituation'))}
                                   rules={[{required: true}]}>
                            <Radio ref={operationRef} data={operateStatus} list={[
                                {value: 0, label: t("Running"), id: 0, text: ''},
                                {value: 1, label: t("StoppingOperations"), id: 0, text: ''}
                            ]} onChange={(e) => setOperateStatus(e)}></Radio>
                        </Form.Item>
                        <Form.Item name={'AuditReport'} label={labelNode(t('AuditReport'))} rules={[{required: true}]}>
                            <Radio ref={auditRadio} data={auditReportStatus} list={[
                                {value: 0, label: t("NoAuditReport"), id: 0, text: ''},
                                {value: 1, label: t("HaveAuditReport"), id: 0, text: ''}
                            ]} onChange={(e) => setAuditReportStatus(e)}></Radio>
                            <div style={auditReportStatus !== 1 ? {display: 'none'} : {}}
                                 className={'included_form_audit_form'}>
                                <Select optionLabelProp="node" value={auditFirms} options={companyList}
                                        optionRender={(option) => (
                                            <div className={'included_form_audit_options'}>
                                                <div className={'included_form_audit_options_left'}>
                                                    {option.data.icon && (<img src={option.data.icon}/>)}
                                                    {option.data.name}
                                                </div>
                                                {auditFirms === option.data.value && (
                                                    <img
                                                        src={require('../../../assets/image/included/check_icon.png')}/>)}
                                            </div>
                                        )} style={auditFirms === 3 ? {
                                    width: 213,
                                    height: 50,
                                    marginRight: 10
                                } : {width: 400, height: 50, marginRight: 10}} onChange={handleChange}/>
                                {
                                    auditFirms === '' && (
                                        <div className={'included_form_audit_other'}>
                                            <Input value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                                                   placeholder={t('EnterCompanyName')}
                                                   className={"included_form_input included_form_audit_other_input"}/>
                                            <Upload onSuccess={successComLogo}>
                                                {!companyLogo &&
                                                    <div className={'included_upload_button'}
                                                         style={{width: 50, height: 50, boxSizing: 'border-box'}}>
                                                        <img src={require("@/assets/image/included/image_upload_icon.png")}
                                                             alt='icon'/>
                                                    </div>
                                                }
                                                {companyLogo &&
                                                    <div className={'included_upload_button'}
                                                         style={{
                                                             width: 50,
                                                             height: 50,
                                                             boxSizing: 'border-box',
                                                             padding: 0
                                                         }}>
                                                        <img src={baseUrl + companyLogo}
                                                             style={{width: 50, height: 50, borderRadius: 10}}
                                                             alt='icon'/>
                                                    </div>
                                                }
                                            </Upload>
                                            <span className={'included_upload_toast'}>*{t('UploadCompanyLogo')}</span>
                                        </div>
                                    )
                                }
                                <div className={'included_form_audit_upload'}>
                                    <Input value={auditReportUrl} onChange={(e) => setAuditReportUrl(e.target.value)}
                                           placeholder={t('AuditOtherPlaceholder')}
                                           className={"included_form_input included_form_audit_pdf_input"}/>
                                    <Upload onSuccess={auditPdfSuccess}>
                                        <div className={'included_upload_button'}>
                                            <img src={require("@/assets/image/included/button_upload_icon.png")}
                                                 alt='icon'/>
                                            <span>{t('upload')} {t('AuditReport').toLocaleUpperCase()}</span>
                                        </div>
                                    </Upload>
                                    <DatePicker value={auditTime} onChange={e => {
                                        setAuditTime(e)
                                    }} placeholder={t('PleaseEnterTheAuditTime')}
                                                className={"included_form_input included_form_audit_time included_form_datepicker_event"}
                                                inputReadOnly/>
                                </div>
                            </div>
                            <div style={auditReport !== 1 ? {display: 'none'} : {}}
                                 className={'included_form_upload_file'}>
                                <div className={'included_form_upload_file_item'}>
                                    <img src={require("@/assets/image/included/pdf_logo.png")} alt='logo'/>
                                    Report01.pdf
                                    <img className={'included_tag_item_close'}
                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name={'TeamMember'} label={labelNode(t('TeamMember'))}>
                            {
                                workList.map((item, index) => {
                                    return (
                                        <div key={index} style={index === 0 ? {marginTop: 0} : {}}
                                             className={'included_form_work_input_block'}>
                                            <Select
                                                style={{width: 400}} showSearch value={item.expId}
                                                className={'included_form_add_input included_form_input'}
                                                optionRender={(option) => (
                                                    <div className={'included_form_audit_options'}>
                                                        <div className={'included_form_audit_options_left'}>
                                                            <img style={{borderRadius: '50%'}} src={baseUrl + option.data.url}/>
                                                            {option.data.label}
                                                        </div>
                                                    </div>
                                                )}
                                                options={memberlList}  filterOption={(input, option) =>
                                                (option?.label.toLocaleUpperCase() ?? '').includes(input.toLocaleUpperCase())
                                            } onChange={e => {
                                                setWorkList(item => {
                                                    const list = [...item]
                                                    for (const obj of memberListData) {

                                                        console.log("1215 15125151",obj.id === e)
                                                        if (obj.id === e) {
                                                            list[index].name = obj.name
                                                            list[index].expId = obj.id
                                                            list[index].url = obj.headImgUrl
                                                            list[index].mainId = obj.mainId
                                                        }
                                                    }
                                                    return list
                                                })
                                            }} suffixIcon={
                                                <img src={require('@/assets/image/included/search_icon.png')}
                                                     style={{width: '19px', height: '19px'}}/>
                                            }
                                            />

                                            <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                                <span className={'included_form_work_input_title'}>{t('POST')}</span>
                                                <Input value={item.post} onChange={e => {
                                                    setWorkList(item => {
                                                        let list = [...item]
                                                        list[index].post = e.target.value
                                                        return list
                                                    })
                                                }} className={'included_form_post_input included_form_input'}/>
                                            </div>
                                            <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                                <span
                                                    className={'included_form_work_input_title'}>{t('WorkingHours')}</span>
                                                <DatePicker.RangePicker value={item.time} allowEmpty={[false, true]} onChange={e => {
                                                    setWorkList(item => {
                                                        let list = [...item]
                                                        list[index].time = e
                                                        return list
                                                    })
                                                }} disabledDate={(current)=>{
                                                    return current && current > dayjs().endOf('day');
                                                }} style={{width: 350}} className={"included_form_input included_form_datepicker"} inputReadOnly/>
                                            </div>
                                            <div className='included_form_event_del'
                                                 style={{lineHeight: '50px'}}
                                                 onClick={() => setWorkList(e => {
                                                     return e.filter((item, key) => index !== key)
                                                 })}>{t('delete')}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className={'included_form_tag_add_button included_media_add'}
                                 onClick={addWorkExperience}>
                                <img src={require('../../../assets/image/included/add_icon.png')}/>
                                {t('ContinueToAdd')}
                            </div>
                        </Form.Item>
                        <Form.Item name={'KeyEvents'} label={labelNode(t('KeyEvents'))}>
                            {
                                eventList.length > 0 && (
                                    eventList.map((item, index) => {
                                        console.log(item.event)
                                        return (
                                            <div className={'included_form_event_item'}>
                                                <Select style={{width: 240, height: 50, marginRight: 10}}
                                                        value={item.event} options={eventSelectList}
                                                        onChange={(e) => eventHandlerChange(e, index)}/>
                                                <DatePicker value={item.time} onChange={e => {
                                                    setEventList((item) => {
                                                        const list = [...item]
                                                        list[index].time = e
                                                        return list
                                                    })
                                                }} placeholder={t('EventDatePlaceholder')}
                                                            className={"included_form_input included_form_datepicker_event"}
                                                            inputReadOnly/>
                                                <Input value={item.link} onChange={e => {
                                                    setEventList((item) => {
                                                        const list = [...item]
                                                        list[index].link = e.target.value
                                                        return list
                                                    })
                                                }} className={'included_form_input included_form_event_link'}/>
                                                <div className='included_form_event_del'
                                                     onClick={() => setEventList(e => {
                                                         return e.filter((item, key) => index !== key)
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                )
                            }
                            <div className={'included_form_tag_add_button included_media_add'} onClick={() => {
                                const data = {
                                    event: t("TestOnlineCable"), time: null, link: ''
                                }
                                setEventList((e) => {
                                    return [...e, data]
                                })
                            }}>
                                <img src={require('../../../assets/image/included/add_icon.png')}/>
                                {t('ContinueToAdd')}
                            </div>
                        </Form.Item>
                        <Form.Item name={'InvestmentInstitutionDetails'}
                                   label={labelNode(t('InvestmentInstitutionDetails'))}>
                            {
                                investmentList.length > 0 && (
                                    <div className={'included_form_bottom_table'}>
                                        <div className={'included_form_bottom_table_head_line6'}>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_round.png")}
                                                     alt='round'/>
                                                {t('Round')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_amount.png")}
                                                     alt='amount'/>
                                                {t('amount')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_valuation.png")}
                                                     alt='valuation'/>
                                                {t('valuation')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_time.png")}
                                                     alt='time'/>
                                                {t('date')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img
                                                    src={require("../../../assets/image/included/icon_leading_investment.png")}
                                                    alt='leading_investment'/>
                                                {t('investor')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_investor.png")}
                                                     alt='investor'/>
                                                {t('leadInvestor')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_investor.png")}
                                                     alt='investor'/>
                                                {t('leadInvestor')}
                                            </div>
                                            <div className={'included_form_bottom_table_head'}>
                                                <img src={require("../../../assets/image/included/icon_link.png")}
                                                     alt='link'/>
                                                {t('link')}
                                            </div>
                                        </div>
                                        {
                                            investmentList.map((item, index) => {
                                                console.log('item', item)
                                                return (
                                                    <div className={'included_form_bottom_table_head_line6'}
                                                         style={{marginTop: 8}}>
                                                    <Input value={item.round} onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].round = e.target.value
                                                                return list
                                                            })
                                                        }} placeholder='--' className={'included_form_table_input'}/>
                                                        <Input value={item.amount} onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].amount = formatWithSeparator(e.target.value)
                                                                return list
                                                            })
                                                        }} placeholder='--' suffix={<span
                                                            className={'included_form_input_suffix_icon'}>$</span>}
                                                               className={"included_form_table_input"}/>
                                                        <Input value={item.valuation} onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].valuation = formatWithSeparator(e.target.value)
                                                                return list
                                                            })
                                                        }} placeholder='--' suffix={<span
                                                            className={'included_form_input_suffix_icon'}>$</span>}
                                                               className={"included_form_table_input"}/>
                                                        <DatePicker value={item.time} onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].time = e
                                                                return list
                                                            })
                                                        }} placeholder='--'
                                                                    className={"included_form_table_input included_form_datepicker"}
                                                                    inputReadOnly/>
                                                        <Select value={item.investor?item.investor.split(','):[]} options={invesList} mode="multiple"  filterOption={(input, option) =>
                                                            (option?.label.toLocaleUpperCase() ?? '').includes(input.toLocaleUpperCase())
                                                        } maxTagCount='responsive' onChange={e => {
                                                            console.log('responsive',e)
                                                            if(e.length === 0) {
                                                                setInvestmentList(item => {
                                                                    const list = [...item]
                                                                    list[index].investor = ''
                                                                    return list
                                                                })
                                                                setLedCheckList([])
                                                                return;
                                                            }
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].investor = e.toString()
                                                                setLedCheckList((event)=>{
                                                                    const obj = invesList.find(item=>item.value === e[e.length-1])
                                                                    console.log(obj)
                                                                    const list = [...event,obj]
                                                                    const uniqueArr = Array.from(new Set(list.map(JSON.stringify))).map(JSON.parse);
                                                                    return uniqueArr
                                                                })
                                                                return list
                                                            })
                                                        }} placeholder='--' style={{borderRadius: 10, border: 'none'}}
                                                                className='included_form_table_input'></Select>
                                                        <Select options={[
                                                            {label: t('YES'), value: "1"},
                                                            {label: t('NO'), value: "0"}
                                                        ]} value={item.led} onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].led = e
                                                                list[index].ledInstitution = ''
                                                                return list
                                                            })
                                                        }} placeholder='--' style={{borderRadius: 10, border: 'none'}}
                                                                className='included_form_table_input'></Select>
                                                        <Select value={item.ledInstitution?item.ledInstitution.split(','):[]} disabled={item.led == 0} options={ledCheckList} mode="multiple" maxTagCount='responsive' onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].ledInstitution = e.toString()
                                                                return list
                                                            })
                                                        }} placeholder='--' style={{borderRadius: 10, border: 'none'}}
                                                                className='included_form_table_input'></Select>
                                                        <Input value={item.link} onChange={e => {
                                                            setInvestmentList(item => {
                                                                const list = [...item]
                                                                list[index].link = e.target.value.replace(/\s/g, "")
                                                                return list
                                                            })
                                                        }} placeholder='--' className={'included_form_table_input'}/>
                                                        <div className='included_form_event_del'
                                                             style={{lineHeight: '50px'}}
                                                             onClick={() => setInvestmentList(e => {
                                                                 return e.filter((item, key) => index !== key)
                                                             })}>{t('delete')}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            <div className={'included_form_tag_add_button included_media_add'} onClick={() => {
                                setInvestmentList((e) => {
                                    return [...e, investmentItem]
                                })
                            }}>
                                <img src={require('../../../assets/image/included/add_icon.png')}/>
                                {t('ContinueToAdd')}
                            </div>
                        </Form.Item>
                    </Form>
                    <div className={'included_form_handler_button'}>
                        <div className={'included_form_handler_button_left'}>
                            <div onClick={() => submitForm(2)}
                                className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                            <div onClick={() => submitForm(0)}
                                 className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                        </div>
                        <div onClick={() => {
                            projectsApi.queryCheckName(name).then(res=>{
                                console.log(res)
                                if(res.data === 0){
                                    submitForm(1)
                                }else {
                                    if(searchParams.get("type") === "draft") {
                                        setRemarkModel(true)
                                    }else {
                                        submitForm(1)
                                    }
                                }
                            })
                        }}
                             className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </div>

            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin spinning={spinning} fullscreen size="large"/>
        </>
    )
}

export default Project;
