import { createSlice } from '@reduxjs/toolkit'
export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: localStorage.getItem('auth')
    },
    reducers: {
        Logout: state => {
            state.value = void 0
            localStorage.removeItem('auth')
            localStorage.removeItem('user')
        },
        Login: (state, action) => {
            state.value = action.payload
            localStorage.setItem('auth',state.value)
        }
    }
})

export const { Logout,Login } = authSlice.actions

export default authSlice.reducer
