import request from '@/utils/request'

class Api {
    queryProjectsComment(data) {
        return request.get('/userComment/list',data)
    }

    submitComment(data) {
        return request.post('/userComment/add',data)
    }

    queryProjectsList(data) {
        return request.get('/projectInclusion/esList',data)
    }

    queryProjectsDetail(data) {
        return request.get('/projectInclusion/getInfo',data)
    }

    queryProjectsDetailFromDB(data) {
        return request.get('/projectInclusion/getInfo',data)
    }

    updateProjectsDetail(data) {
        return request.post('/projectInclusion/update',data)
    }

    queryProjectRecommend(data) {
        return request.get('/projectInclusion/recommendEsList',data)
    }

    delProject(id) {
        return request.del(`/projectInclusion/${id}`)
    }

    updateProjectsDraft(data) {
        return request.post('/projectInclusion/edit',data)
    }

    queryOfficeList(data) {
        return request.get('/officeInformation/list',data)
    }

    queryOfficeInfo(id) {
        return request.get(`/officeInformation/${id}`)
    }


    queryCheckName(name) {
        return request.get('/projectInclusion/checkName',{name})
    }
}

export default new Api();
