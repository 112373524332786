import './login_module.scss'
import {useTranslation} from "react-i18next";
import {Button, Input} from "antd";
import {useEffect, useState} from "react";
import message from "../../utils/message";
import loginApi from '@/api/login'

function Forget({onButtonClick}) {
    const {t} = useTranslation()
    const [userName, setUserName] = useState('')
    const [code,setCode] = useState('')
    const [password, setPassword] = useState('')
    const [codeText,setCodeText] = useState(t("getCode"))
    const [flag, setFlag] = useState(false)
    const [codeInterval,setCodeInterval] = useState(null)

    useEffect(() => {
        return () => {
            clearInterval(codeInterval)
        };
    }, [])


    function getEmailCode() {
        if (flag) return
        let data = {
            email:userName
        }
        loginApi.getVerificationCode(data).then(()=> {
            message.success(t("success"))
            setFlag(true)
            let count = 60
            const interval = setInterval(()=>{
                count--
                setCodeText(`${t('Resend')}(${count})`)
                if (count === 0) {
                    setCodeText(t('getCode'))
                    clearInterval(interval)
                    setFlag(false)
                }
            },1000)
            setCodeInterval(interval)
        })
    }

    function toSignIn() {
        const data = {userName,password,code}
        loginApi.submitForgetPassword(data).then(res=>{
            message.success("saveSuccess")
            onButtonClick(0)
        })
    }
    return (
        <div className={'login_input_block'} style={{alignItems: "flex-start",justifyContent:"flex-start"}}>
            <div className={"login_forget_top"}>
                <div className={"login_forget_title"}>{t('ForgotPassword')}</div>
                <div className={"login_forget_content"}>{t('ForgetContent')}</div>
            </div>
            <div className={'login_input'} style={{marginBottom: '36px'}}>
            <div className={'login_input_label'}>{t("Email")}</div>
                <Input className={"login_input_el"} value={userName} onChange={(e) => {
                    setUserName(e.target.value)
                }}/>
            </div>
            <div className={'login_input'} style={{marginBottom: '36px'}}>
                <div className={'login_input_label'}>{t("VerificationCode")}</div>
                <div className={'login_div_el'}>
                    <Input className={"login_input_el"} value={code} onChange={(e) => {
                        setCode(e.target.value)
                    }}/>
                    <Button onClick={getEmailCode} className={"login_email_getCode"}>{codeText}</Button>
                </div>
            </div>
            <div className={'login_input'}>
                <div className={'login_input_label'}>{t("Password")}</div>
                <Input type="password" className={"login_input_el"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            </div>
            <Button onClick={toSignIn} className={'login_sign_button'}>{t("signIn")}</Button>
        </div>
    )
}

export default Forget;
