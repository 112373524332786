import './search_module.scss'
import {Input} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Pagination from "../../components/Pagination";
import useSyncCallback from "../../utils/useSyncCallback";
import searchApi from '@/api/search'
import {projectTypeIndex} from "../../enum/projectType";

function Search() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const baseUrl = process.env.REACT_APP_BASE_URL
    const search = searchParams.get('search')
    const {t} = useTranslation()
    const lang = useSelector(state => state.language.value)
    const user = useSelector(state => state.user.value)
    const auth = useSelector(state => state.auth.value)
    const [rightList,setRightList] = useState([])
    const [searchValue,setSearchValue] = useState('')
    const [searchText,setSearchText] = useState('')
    const [pageNum,setPageNum] = useState(1)
    const [tableData,setTableData] = useState({
        total: 0,
        list: []
    })

    function changePage(e) {
        setPageNum(e)
        getList()
    }

    const getList = useSyncCallback(()=>{
        setSearchText(searchValue)
        searchApi.querySearchList({
            pageNum,
            pageSize: 15,
            lang: lang.value,
            name: searchValue
        }).then(res=>{
            console.log(res)
            setTableData(()=>{
                return {
                    total: res.total,
                    list: res.rows
                }
            })
        })
    })

    const toDetailPage = useSyncCallback((item)=>{
        if(!auth) {
            naviToPage(item.id,item.remark)
        }else {
            searchApi.addSearchHistory({
                inclusionId: item.id,
                url: item.logoUrl,
                name: item.name,
                type: item.remark,
                label: item.label,
                remark: item.content
            }).then(()=>{
                console.log("item",item)
                naviToPage(item.id,item.remark)
            })
        }
    })

    const naviToPage = useSyncCallback((id,type)=>{
        console.log('navi',type)
        switch (type) {
            case "0":
                navigate(`/project/detail/${id}`)
                break;
            case "1":
                navigate(`/investors/detail/${id}`)
                break;
            case "2":
                navigate(`/people/detail/${id}`)
                break;
            case "3":
                navigate(`/meeting/detail/${id}`)
                break;
            case "4":
                navigate(`/kol/detail/${id}`)
                break;
            case "6":
                navigate(`/more/tool/detail/${id}`)
                break;
            case "7":
                navigate(`/more/hackathon/detail/${id}`)
                break;
        }
    })

    const getHistoryList = useSyncCallback(()=>{
        if(!auth) return
        searchApi.queryHistoryList({
            pageNum: 1,
            pageSize: 10,
            creatBy: user.userId,
        }).then(res=>{
            setRightList(res.rows)
        })
    })

    useEffect(()=>{
        setSearchValue(search)
        setSearchText(search)
        getHistoryList()
        getList()
    },[])
    return (
        <div className='main_back'>
            <PageHeader other={true}></PageHeader>
            <div className='search_page'>
                <div className='search_page_left'>
                    <Input onPressEnter={getList} value={searchValue} className={'included_form_add_input included_form_input'}
                           style={{width: 1060}}
                           onChange={(e) => {
                               setSearchValue(e.target.value)
                           }} suffix={
                        <img onClick={getList} src={require('@/assets/image/included/search_icon.png')}
                             style={{width: '19px', height: '19px'}}/>
                    }/>
                    {lang.value === 0 && (
                        <div className='search_for'>Search results for <span>"{searchText}"</span></div>)}
                    {lang.value === 1 && (<div className='search_for'><span>"{searchText}"</span>的搜索结果</div>)}
                    <div>
                        <div className='search_page_left_list'>
                            {
                                tableData.list.map((item, index) => {
                                    return (
                                            <div onClick={()=>toDetailPage(item)} key={index} className={'project_table_item'}>
                                                <div className={'project_table_item_top'}>
                                                    <img src={baseUrl + item.logoUrl} alt='logo'/>
                                                    <div className={'project_table_item_text'}>
                                                        <div className={'project_table_item_title'}>{item.name}</div>
                                                        <div
                                                            className={'project_table_item_content'}>{item.content}</div>
                                                    </div>
                                                </div>
                                                <div className={'project_table_item_bottom'}>
                                                    <div className={'project_table_item_bottom_tool'}>{item.remark && t(projectTypeIndex[item.remark])}</div>
                                                    {
                                                        item.label && item.label.split(',').map(data => {
                                                            return (
                                                                <div
                                                                    className={'project_table_item_bottom_data tag-styles'}>{data}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                    )
                                })
                            }
                        </div>

                        <div className={'project_table_page'} style={{marginTop: 0}}>
                            <Pagination total={tableData.total} size={15} page={pageNum}
                                        onChange={changePage}></Pagination>
                        </div>
                    </div>

                </div>
                <div className='search_page_right'>
                    {
                        auth && (
                            <div className='search_page_right_block'>
                                <div className='search_page_right_block_title'>{t('RecentlyViewed')}</div>
                                <div className='search_page_right_block_list'>
                                    {
                                        rightList.map(item => {
                                            return (
                                                <div onClick={() => naviToPage(item.inclusionId, item.type)}
                                                     className='search_page_right_block_item'>
                                                    <img src={baseUrl + item.url} alt='image'/>
                                                    <div className='search_page_right_block_item_right'>
                                                        <div className='search_right_title'>{item.name}</div>
                                                        <div className='search_right_content'>{item.remark}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Search;
