import "../included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Form, Input, DatePicker, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useSyncCallback from "../../../utils/useSyncCallback";
import characterApi from "../../../api/character";
import projectsApi from "../../../api/projects";
import investorApi from "../../../api/investor";
import dayjs from "dayjs";
import message from '@/utils/message'
import {projectTypeObj} from "../../../enum/projectType";
import {investorContract, playerContract, web3} from "../../../utils/web3Utils";
import {auditStatusObj} from '../../../enum/auditStatus'
import commonApi from "../../../api/common";
import userApi from "../../../api/user";
import {setReview} from "../../../utils/middle";
import {useNavigate} from "react-router-dom";
import {formatWithSeparator} from "../../../utils/formatUtils";
import Region from '@/components/Region'
import Modal from '@/components/Modal/index'
function Investor() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const regStr = new RegExp('[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\\.?');
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const lang = useSelector(state => state.language.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [tab,setTab] = useState([])
    const [tabList,setTabList] = useState([])
    const [mediaList,setMediaList]=useState([{webType: media[0].value,webUrl: ''}])
    const [eventList,setEventList] = useState([])
    const [remarkModel, setRemarkModel] = useState(false)
    const eventSelectList = [
        {value: t("TestOnlineCable"), label: t("TestOnlineCable")},
        {value: t("MainOnlineCable"), label: t("MainOnlineCable")},
        {value: t("AttackedAmountInUSDollars"), label: t("AttackedAmountInUSDollars")},
    ]
    const [investmentDatailList,setInvestmentDatailList] = useState([])
    const investmentDataItem = {
        projectName: '',
        round: '',
        amount: null,
        valuation: null,
        time: '',
        coinIssuanceSatus: null,
        label: null,
        logo: '',
        synopsis: '',
        projectId: '',
        led: ''
    }
    const [logoUrl,setLogoUrl] = useState('')
    const [name, setName] = useState('')
    const [officialWebsite, setOfficialWebsite] = useState('')
    const [content, setContent] = useState('')
    const [establishTime, setEstablishTime] = useState('')
    const [location, setLocation] = useState('')
    const [memberPage,setMemberPage] = useState(1)
    const [memberlList, setMemberList] = useState([])
    const [memberListData,setMemberListData] = useState([])
    const [checkMemberList,setCheckMemberList] = useState([])
    const [teamMember,setTeamMember] = useState([])
    const [projectPage,setProjectPage] = useState(1)
    const [projectOption, setProjectOption] = useState([])
    const [projectList, setProjectList] = useState([])
    const [staked,setStaked] = useState(0)
    const [spinning,setSpinning] = useState(false)
    const [workList,setWorkList] = useState([{expId: '',post: '',time: '',name: '',url: ''}])
    const [tagDataList, setTagDataList] = useState([])

    const addWorkExperience = useSyncCallback(()=>{
        setWorkList(e=>{
            let list = [...e]
            list.push({expId: '',post: '',time: '',name: '',url: ''})
            return [...list]
        })
    })

    const getMember = useSyncCallback((name)=>{
        characterApi.queryCharacterList({
            pageNum: memberPage,
            pageSize: 10,
            name,
            sort: 0,
            lang: 0,
            type: 0
        }).then(res=>{
            let member = res.rows.map(item=>{
                return {
                    label: item.name,
                    value: item.id,
                    url: item.headImgUrl
                }
            })
            setMemberList([...member])
            setMemberListData([...res.rows])
        })
    })

    const addMemberValue = useSyncCallback(()=>{
        let list = memberListData.filter(item=>{
            for (const data of teamMember) {
                if (item.id === data) return item
            }
        })
        setCheckMemberList([...list])
    })

    const delMemberValue = useSyncCallback((id)=>{
        let list = teamMember.filter(item=>item !== id)
        setTeamMember([...list])
        addMemberValue()
    })

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addTabs() {
        if (tab) {
            console.log(tab)
            setTabList((e)=>{
                return [...new Set([...e,...tab])]
            })
            form.setFieldsValue({tab})
            setTab([])
        }
    }

    function delTabs(index) {
        const list = [...tabList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setTabList(list)
            }
        })
    }

    function addMediaLink() {
        const data = {webType: media[0].value,webUrl: ''}
        const list = [...mediaList]
        list.push(data)
        setMediaList(list)
    }

    function eventHandlerChange(value,index) {
        console.log(value,index)
        setEventList((e)=>{
            const list = [...e]
            list[index].event = value
            return list
        })
    }

    const getProject = useSyncCallback((name = void 0)=>{
        console.log(name)
        projectsApi.queryProjectsList({
            pageNum: projectPage,
            pageSize: 10,
            lang: 0,
            sort: 0,
            type: 0,
            name
        }).then(res=>{
            console.log(res)
            setProjectList([...res.rows])
            setProjectOption(()=>{
                return res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
        })
    })

    const changeInvestmentData = useSyncCallback((id,index)=>{
        console.log(id,index)
        projectsApi.queryProjectsDetail({
            id: id
        }).then(res=>{
            console.log(res)
            const {data: item} = res
            setInvestmentDatailList(e=> {
                let list = [...e]
                list[index].projectName = item.name
                list[index].coinIssuanceSatus = item.coinIssuanceSatus
                list[index].label = item.label
                list[index].logo = item.logoUrl
                list[index].synopsis = item.introduction
                list[index].projectId = item.id
                return [...list]
            })
        })
    })

    const submitForm = useSyncCallback((type)=>{
        const investDetailsListData = investmentDatailList.map(item=>{
            console.log(item)
            const obj = {...item}
            obj.time = dayjs(obj.time).format('YYYY-MM-DD HH:mm:ss')
            return {...obj}
        })
        const eventListData = eventList.map(item=>{
            const obj = {...item}
            obj.time =  dayjs(obj.time).format('YYYY-MM-DD HH:mm:ss')
            return {...obj}
        })
        const workListMap = workList.map(item=>{
            console.log(item)
            let timeObj = {}
            if (item.time) {
                timeObj = {...item}
                const list = timeObj.time.map(params=>{
                    if (!params) return ''
                    return params.format('YYYY-MM-DD HH:mm:ss')
                })
                timeObj['remark'] = name
                timeObj['purl'] = logoUrl
                timeObj.time = list.toString()
            }
            return timeObj
        })
        const data = {
            logoUrl,name,officialWebsite,label: tabList.toString(),establishTime: establishTime?dayjs(establishTime).format('YYYY-MM-DD'):null,location,content,smcList: mediaList,eventList: eventListData,investDetailsList: investDetailsListData,lang: 0,submitType: type,remark: projectTypeObj.investor.type,workExperienceList: workListMap.filter(item=>item.mainId),createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        setSpinning(true)
        if (type === 0) {
            investorApi.submitInvestorData(data).then(res=>{
                message.success(t('saveSuccess'))
                setSpinning(false)
            })
        }else if (type === 1) {
            if (staked < 100 ) {
                setSpinning(false)
                message.fail(t('NoIncluded'))
                return navigate("/user/wiki")
            }
            if (!data.logoUrl || !data.name || !data.officialWebsite || !data.label || !data.content || !data.establishTime || !data.location || data.smcList.length === 0) {
                setSpinning(false)
                return message.fail(t('completeInfo'))
            }
            for (const item of data.smcList) {
                if (!item.webType || !item.webUrl) {
                    setSpinning(false)
                    return message.fail(t('completeInfo'))
                }
            }
            investorApi.submitInvestorData(data).then(res=>{
                message.success(t('saveSuccess'))
                if (res.data.msg === '该收录存在待审核状态，无法更新') {
                    return
                }
                const obj = res.data
                console.log('obj',obj)
                investorContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                    console.log('上链参数',res)
                    if (res.status === 1n) {
                        commonApi.updateTransactionHash({
                            qmHash: obj.qmHash,
                            transactionHash: res.transactionHash
                        }).then(_=>{
                            userApi.updateAuditStatus({
                                projectId: obj.cId,
                                status: auditStatusObj["unaudited"].type
                            }).then(_=>{
                                message.success(t('saveSuccess'))
                            })
                        })
                    }else {
                        message.fail(t('DenialAuthorization'))
                    }
                    setSpinning(false)
                }).catch((err)=>{
                    console.log(err)
                    investorApi.delInvestor(obj.cId)
                    commonApi.delExamineDataByQmHash(obj.qmHash)
                    setSpinning(false)
                })
        })
        }else {
            setReview(data,(res)=>{
                console.log(res)
                navigate('/investors/review?id='+res.data)
                setSpinning(false)
            })
        }

    })

    const getTagList = useSyncCallback((e)=>{
        commonApi.queryDictData({
            dictType: lang.value == 0?'organization_type_en':'organization_type'
        }).then(res=>{
            setTagDataList(res.rows)
        })
    })

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                getMember()
                getTagList()
                console.log(res[0])
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                })
            })
        }
    },[])

    function formFinish(e) {
        console.log(e)
        investorApi.queryCheckName(e.InvestmentInstitutionName).then(res=>{
            console.log(res)
            if(res.data === 0){
                submitForm(1)
            }else {
                setRemarkModel(true)
            }
        })
    }

    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title'}>{t('investmentInstitutions')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form} onFinish={formFinish}>
                        <Form.Item name="logo" label={labelNode('Logo', t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={(e) => {
                                setLogoUrl(e.data)
                                form.setFieldsValue({logo: e.data})
                            }}>
                                {
                                    !logoUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    logoUrl && (
                                        <img src={baseUrl + logoUrl} className={'included_upload_logo'}/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'InvestmentInstitutionName'}
                                       label={labelNode(t('InvestmentInstitutionName'))}
                                       rules={[{required: true}]}>
                                <Input value={name} onChange={(e) => setName(e.target.value)}
                                       className={"included_form_input"}
                                       placeholder={t("InvestmentInstitutionNamePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'OfficialWebsiteOfInvestmentInstitutions'}
                                       label={labelNode(t('OfficialWebsiteOfInvestmentInstitutions'))}
                                       rules={[{required: true},{pattern: regStr, message:t('linkFail')}]}>
                                <Input value={officialWebsite} onChange={e => setOfficialWebsite(e.target.value.replace(/\s/g, ""))}
                                       className={"included_form_input"}
                                       placeholder={t("OfficialWebsiteOfInvestmentInstitutionsPlaceholder")}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'tab'} label={labelNode(t('InstitutionalTabs'))}
                                   rules={[{required: true}]}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false} fieldNames={{label: 'dictLabel', value: 'dictLabel'}}
                                    className={'included_form_add_input included_form_input'}
                                    options={tagDataList} onSearch={getTagList} value={tab} onChange={e => {
                                    setTab(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {tabList.length > 0 && (
                                    <>
                                        {tabList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delTabs(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name={'IntroductionToInvestmentInstitutions'}
                                   label={labelNode(t('IntroductionToInvestmentInstitutions'))}
                                   rules={[{required: true}]}>
                            <Input.TextArea value={content} onChange={e => setContent(e.target.value)}
                                            className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'EstablishmentTime'}
                                       label={labelNode(t('EstablishmentTime'))}
                                       rules={[{required: true}]}>
                                <DatePicker value={establishTime} onChange={e => setEstablishTime(e)}
                                            className={"included_form_input included_form_datepicker"} inputReadOnly/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'location'}
                                       label={labelNode(t('location'))} rules={[{required: true}]}>
                                <Region change={(e) => {
                                    setLocation(e)
                                    form.setFieldsValue({location: e})
                                }}></Region>
                            </Form.Item>
                        </div>
                        <Form.Item name={'SocialMediaLinks'} label={labelNode(t('SocialMediaLinks'))}
                                   rules={[{required: true},({ getFieldValue }) => ({
                                       validator(_, value) {
                                           console.log('value',value)
                                           if (!regStr.test(value)) {
                                               return Promise.reject(new Error(t('linkFail')));
                                           };
                                           return Promise.resolve()
                                       },
                                   })]}>
                            <div>
                                {
                                    mediaList.map((item, index) => {
                                        return (
                                            <div className={'included_media_item'} key={index}
                                                 style={index !== 0 ? {marginTop: 12} : {}}>
                                                <Select value={item.webType} onChange={e => {
                                                    setMediaList(item => {
                                                        let list = [...item]
                                                        list[index].webType = e
                                                        return [...list]
                                                    })
                                                }} defaultValue={item.media} options={media}
                                                        style={{width: 177, height: 50}}/>
                                                <Input value={item.webUrl} onChange={e => {
                                                    setMediaList(item => {
                                                        let list = [...item]
                                                        list[index].webUrl = e.target.value.replace(/\s/g, "")
                                                        return [...list]
                                                    })
                                                }} className={"included_form_input included_media_link_input"}/>
                                                <div className='included_form_event_del' style={{lineHeight: '50px'}}
                                                     onClick={() => setMediaList(e => {
                                                         return e.filter((item, key) => index !== key)
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={'included_form_tag_add_button included_media_add'}
                                     onClick={addMediaLink}>
                                    <img src={require('../../../assets/image/included/add_icon.png')} alt="icon"/>
                                    {t('ContinueToAdd')}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name={'TeamMember'} label={labelNode(t('TeamMember'))}>
                            {
                                workList.map((item, index) => {
                                    return (
                                        <div key={index} style={index === 0 ? {marginTop: 0} : {}}
                                             className={'included_form_work_input_block'}>
                                            <Select
                                                style={{width: 400}} filterOption={false}
                                                className={'included_form_add_input included_form_input'}
                                                options={memberlList} optionRender={(option) => (
                                                <div className={'included_form_audit_options'}>
                                                    <div className={'included_form_audit_options_left'}>
                                                        <img style={{borderRadius: '50%'}} src={baseUrl + option.data.url}/>
                                                        {option.data.label}
                                                    </div>
                                                </div>
                                            )} showSearch filterOption={(input, option) =>
                                                (option?.label ?? '').includes(input)
                                            } onChange={e => {
                                                setWorkList(item => {
                                                    const list = [...item]
                                                    console.log(e)
                                                    for (const obj of memberListData) {
                                                        if (obj.id === e) {
                                                            list[index].name = obj.name
                                                            list[index].expId = obj.id
                                                            list[index].url = obj.headImgUrl
                                                            list[index].mainId = obj.mainId
                                                        }
                                                    }
                                                    return list
                                                })
                                            }} suffixIcon={
                                                <img src={require('@/assets/image/included/search_icon.png')}
                                                     style={{width: '19px', height: '19px'}}/>
                                            }
                                            />

                                            <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                                <span className={'included_form_work_input_title'}>{t('POST')}</span>
                                                <Input value={item.post} onChange={e => {
                                                    setWorkList(item => {
                                                        let list = [...item]
                                                        list[index].post = e.target.value
                                                        return list
                                                    })
                                                }} className={'included_form_post_input included_form_input'}/>
                                            </div>
                                            <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                                <span
                                                    className={'included_form_work_input_title'}>{t('WorkingHours')}</span>
                                                <DatePicker.RangePicker value={item.time} allowEmpty={[false, true]} onChange={e => {
                                                    setWorkList(item => {
                                                        let list = [...item]
                                                        list[index].time = e
                                                        return list
                                                    })
                                                }} disabledDate={(current)=>{
                                                    return current && current > dayjs().endOf('day');
                                                }} style={{width: 350}} className={"included_form_input included_form_datepicker"} inputReadOnly/>
                                            </div>
                                            <div className='included_form_event_del'
                                                 style={{lineHeight: '50px'}}
                                                 onClick={() => setWorkList(e => {
                                                     return e.filter((item, key) => index !== key)
                                                 })}>{t('delete')}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className={'included_form_tag_add_button included_media_add'}
                                 onClick={addWorkExperience}>
                                <img src={require('../../../assets/image/included/add_icon.png')}/>
                                {t('ContinueToAdd')}
                            </div>
                        </Form.Item>
                        <Form.Item name={'KeyEvents'} label={labelNode(t('KeyEvents'))}>
                            {
                                eventList.length > 0 && (
                                    eventList.map((item, index) => {
                                        return (
                                            <div key={index} className={'included_form_event_item'}>
                                                <Select style={{width: 240, height: 50, marginRight: 10}}
                                                        value={item.event} options={eventSelectList}
                                                        onChange={(e) => eventHandlerChange(e, index)}/>
                                                <div className={'included_form_tag_add_button'}>
                                                    <span
                                                        className={'included_form_work_input_title'}>{t('time')}</span>
                                                    <DatePicker value={item.time} onChange={e => setEventList(item => {
                                                        let list = [...item]
                                                        list[index].time = e
                                                        return [...list]
                                                    })} placeholder={t('EventDatePlaceholder')}
                                                                className={"included_form_input included_form_datepicker_event"}
                                                                inputReadOnly/>
                                                </div>
                                                <div className={'included_form_tag_add_button'}>
                                                    <span className={'included_form_work_input_title'}>{t('link')}</span>
                                                    <Input value={item.link} onChange={e => setEventList(item => {
                                                        let list = [...item]
                                                        list[index].link = e.target.value.replace(/\s/g, "")
                                                        return [...list]
                                                    })} className={'included_form_input included_form_event_link'}/>
                                                </div>
                                                <div className='included_form_event_del' style={{lineHeight: '50px'}}
                                                     onClick={() => setEventList(e => {
                                                         return e.filter((item, key) => index !== key)
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                )
                            }
                            <div className={'included_form_tag_add_button included_media_add'} onClick={() => {
                                const data = {
                                    event: t("TestOnlineCable"), time: null, link: ''
                                }
                                setEventList((e) => {
                                    return [...e, data]
                                })
                            }}>
                                <img src={require('../../../assets/image/included/add_icon.png')}/>
                                {t('ContinueToAdd')}
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className={'included_form_handler_button'}>
                                <div className={'included_form_handler_button_left'}>
                                    <div onClick={() => submitForm(2)}
                                         className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                                    <div onClick={() => submitForm(0)}
                                         className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                                </div>
                                <button htmlType="submit"
                                     className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</button>
                            </div>
                        </Form.Item>
                    </Form>

                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default Investor;
