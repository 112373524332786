import './project_detail_module.scss'
import {useParams, Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Spin, Tag, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "../../../utils/useSyncCallback";
import projectsApi from "../../../api/projects";
import dayjs from "dayjs";
import Comment from "../../../components/Comment";
import commonApi from '../../../api/common'
import {projectTypeObj} from "../../../enum/projectType";
import message from '../../../utils/message'
import DetailHandle from "../../../components/DetailHandle";
import CreateInfo from "../../../components/CreateInfo";
import {formatMillion} from "../../../utils/formatUtils";
import investorApi from "../../../api/investor";
import {getMediaIcon} from "../../../enum/mediaType";
import {check14Days} from "../../../utils/tools";
import {projectContract} from "../../../utils/web3Utils";
import userApi from '@/api/user'
import characterApi from "../../../api/character";

function Detail() {
    const navigate = useNavigate()
    const route = useLocation()
    const [searchParams,setSearchParams] = useSearchParams()
    const {id} = useParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const [keyEventList,setKeyEventList] = useState([])
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [remarkModel, setRemarkModel] = useState(false)
    const [spinning, setSpinning] = useState(false)
    const [detailInfo, setDetailInfo] = useState({
        projectInvestDetailsList: [],
        foreignInvestList: [],
        workExperienceList: []
    })
    const [commentData, setCommentData] = useState({
        total: 0,
        list: []
    })
    const [pageNum, setPageNum] = useState(1)
    const [createInfo,setCreateInfo] = useState({})
    const [workExperienceList, setWorkExperienceList] = useState([])
    const [officeInfo, setOfficeInfo] = useState({})
    const [recommendList, setRecommendList] = useState([])

    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=> {
        projectsApi.queryProjectsDetail({
            id: id
        }).then((res)=>{
            if (searchParams.get("status") === "2") {
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
            setKeyEventList([...res.data.eventList])
            setDetailInfo({...res.data})
            getRecommendList()
            getCreateUserInfo()
            if (res.data.auditFirms) getOfficeInfo(res.data.auditFirms)
            console.log('res.data.workExperienceList',res.data.workExperienceList)
            if (res.data.projectInvestDetailsList.length > 0){
                res.data.projectInvestDetailsList.forEach(async (item,index)=>{
                    if (item.projectInvestDetailsInvestors){
                        const projectList = item.projectInvestDetailsInvestors
                        const resultList = []
                        const resultInvList = projectList

                        setDetailInfo(e=>{
                            console.log('e.projectInvestDetailsList',e.projectInvestDetailsList)
                            if(e.projectInvestDetailsList.length > 0) {
                                e.projectInvestDetailsList[index]["invInfo"] = resultInvList
                            }
                            return {...e}
                        })
                    }
                })
            }
        })
    })

    const getAuditDetail = useSyncCallback(()=> {
        projectsApi.queryProjectsDetailFromDB({
            id: id
        }).then((res)=>{
            if (searchParams.get("status") === "2") {
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
            const data = {
                pageNum: 1,
                pageSize: 20,
                inclusionId: id
            }
            if (res.data.projectInvestDetailsList.length > 0){
                res.data.projectInvestDetailsList.forEach(async (item,index)=>{
                    if (item.projectInvestDetailsInvestors){
                        const projectList = item.projectInvestDetailsInvestors
                        const resultList = []
                        const resultInvList = projectList

                        setDetailInfo(e=>{
                            console.log('e.projectInvestDetailsList',e.projectInvestDetailsList)
                            if(e.projectInvestDetailsList.length > 0) {
                                e.projectInvestDetailsList[index]["invInfo"] = resultInvList
                            }
                            return {...e}
                        })
                    }
                })
            }
            if (res.data.auditFirms) getOfficeInfo(res.data.auditFirms)
            Promise.all([
                commonApi.queryKeyEvent(data),
                commonApi.queryForeignInvest(data),
                commonApi.queryProjectInvestDetails(data)
            ]).then((result)=>{
                setKeyEventList([...result[0].rows])
                setDetailInfo(()=>{
                    return {
                        ...res.data,
                        foreignInvestList: result[1].rows,
                        projectInvestDetailsList: result[2].rows
                    }
                })
                getRecommendList()
                getCreateUserInfo()
            })
            // if (res.data.projectInvestDetailsList.length > 0){
            //     res.data.projectInvestDetailsList.forEach(async (item,index)=>{
            //         if (item.ledInstitution){
            //             let remarkList = []
            //             if (item.ledInstitution) {
            //                 remarkList = item.ledInstitution.split(",")
            //             }
            //             const projectList = item.projectInvestDetailsInvestors
            //             const resultList = []
            //             const resultInvList = projectList
            //             for(let params of remarkList) {
            //                 const index = projectList.findIndex(item=>item.investorId === params)
            //                 let result = {}
            //                 console.log("index",index)
            //                 console.log("projectList[index]",projectList[index])
            //                 if (index !== -1){
            //                     if (projectList[index].type === "0") {
            //                         let data = await projectsApi.queryProjectsDetail({id: projectList[index].investorId})
            //                         result = {
            //                             img: data.data.logoUrl,
            //                             name: data.data.name,
            //                             id: data.data.id,
            //                             type: "0"
            //                         }
            //                     }else if(projectList[index].type === "1") {
            //                         let data = await investorApi.queryInvestorDetail({id: projectList[index].investorId})
            //                         console.log("data",data)
            //                         result = {
            //                             img: data.data.logoUrl,
            //                             name: data.data.name,
            //                             id: data.data.id,
            //                             type: "1"
            //                         }
            //                     }else {
            //                         let data = await characterApi.queryCharacterDetail({id: projectList[index].investorId})
            //                         console.log("data",data)
            //                         result = {
            //                             img: data.data.headImgUrl,
            //                             name: data.data.name,
            //                             id: data.data.id,
            //                             type: "2"
            //                         }
            //                     }
            //                     resultList.push(result)
            //                 }
            //             }
            //             setDetailInfo(e=>{
            //                 console.log('e.projectInvestDetailsList',e.projectInvestDetailsList)
            //                 if(e.projectInvestDetailsList.length > 0) {
            //                     e.projectInvestDetailsList[index]["ledInfo"] = resultList
            //                     e.projectInvestDetailsList[index]["invInfo"] = resultInvList
            //                 }
            //                 return {...e}
            //             })
            //         }
            //     })
            // }
        })
    })

    const getCommentList = useSyncCallback(()=>{
        projectsApi.queryProjectsComment({
            inclusionId: id,
            pageNum,
            pageSize: 4
        }).then(res=>{
            const data = {
                total: res.total,
                list: [...commentData.list,...res.rows]
            }
            setCommentData({...data})
        })
    })

    const getRecommendList = useSyncCallback(()=>{
        projectsApi.queryProjectRecommend({
            label: detailInfo.label,
            mainId: detailInfo.mainId,
            lang: detailInfo.lang
        }).then(res=>{
            setRecommendList(res.rows)
        })
    })

    const getOfficeInfo = useSyncCallback((id)=>{
        projectsApi.queryOfficeInfo(id).then(res=>{
            setOfficeInfo(res.data)
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            setCreateInfo({...res.data})
        })
    })

    const changeClaimStatus = useSyncCallback((qmHash)=>{
        userApi.updateClaimStatus({
            qmHash,
            claimStatus: 0
        }).then(()=>{
            detailInfo.claimStatus = '0'
        })
    })

    const toGetClaim = useSyncCallback(()=>{
        setSpinning(true)
        projectContract.methods.claimWiki(detailInfo.mainId,detailInfo.qmHash).send({from: walletAddress}).then((res)=>{
            if (res.status === 1n) {
                message.success(t('ClaimSuccess'))
                changeClaimStatus(detailInfo.qmHash)
            }else {
                message.fail(t('DenialAuthorization'))
            }
            setSpinning(false)
        }).catch((err)=>{
            message.fail(t('DenialAuthorization'))
            setSpinning(false)
        })
    })

    useEffect(()=>{
        getDetail()
        getCommentList()
    },[route.pathname])

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>
                {
                    searchParams.get("type") === 'audit'?
                        (<div className={'router_line_block'}>
                            <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                                 alt='arrow'/>
                            <Link to={-1}>
                                <span className={'router_line_block_color'}>{t('back')}</span>
                            </Link>
                        </div>):(<RouterLine name={detailInfo.name}/>)
                }

                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl + detailInfo.logoUrl} alt='Empty'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>
                                        {detailInfo.name}<span>{detailInfo.tokenAbbreviation ? `（${detailInfo.tokenAbbreviation}）` : ''}</span>
                                        <div className={'project_detail_info_running'}>
                                            <img src={require('@/assets/image/project/running.png')} alt='running'/>
                                            {t('running')}
                                        </div>
                                    </div>
                                    <div className={'project_detail_info_context'}>{detailInfo.introduction}</div>
                                    <div className={'project_detail_info_tag'}>
                                        {(check14Days(detailInfo.createTime) && detailInfo.createBy == userInfo.userId && detailInfo.claimStatus !== "0") &&
                                            <Tag style={{cursor: "pointer"}} onClick={toGetClaim}
                                                 color="success">{t("ClaimRewards")}</Tag>}
                                        {
                                            detailInfo.label && detailInfo.label.split(',').map((item, index) => {
                                                return (
                                                    <div key={index} className={'project_detail_info_tag_item'}
                                                         onClick={() => navigate(`/project/list?tag=${item}`)}>
                                                        <img
                                                            src={color === 'white' ? require('@/assets/image/project/tool.png') : require('@/assets/image/project/tool_dark.png')}
                                                            alt='tool'/>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {(detailInfo.mainId && !searchParams.get("type")) && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.project.type}
                                                                lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'project_detail_info_link_list'}>
                                {detailInfo.officialWebsite && <div className={'project_detail_info_link'} onClick={() => {
                                    let url = detailInfo.officialWebsite
                                    if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                        window.open(detailInfo.officialWebsite)
                                    } else {
                                        window.open("http://" + url.split("://")[0])
                                    }
                                }}>
                                <img src={getMediaIcon("Website", color)}/>
                                Website
                            </div>}
                            {
                                detailInfo.smcList?.length > 0 && detailInfo.smcList.map((item, index) => {
                                    return (
                                        <div key={index} className={'project_detail_info_link'} onClick={() => {
                                            let url = item.webUrl
                                            if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                                window.open(item.webUrl)
                                            } else {
                                                window.open("http://" + url.split("://")[0])
                                            }
                                        }}>
                                            <img alt='net' src={getMediaIcon(item.webType, color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                            <div className={'project_detail_info_link'}
                                 onClick={() => window.open(detailInfo.whitePaperLink)}>
                                <img
                                    src={color === 'white' ? require('@/assets/image/project/whitePaper.png') : require('@/assets/image/project/whitePaper_dark.png')}/>
                                White Paper
                            </div>
                            {(officeInfo.name || detailInfo.companyName) &&
                                <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                         color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottomLeft"
                                         title={`Audit Report From ${officeInfo.name || detailInfo.companyName}`}>
                                    <div className={'project_detail_info_link'} onClick={() => {
                                        window.open(detailInfo.auditReportUrl)
                                    }}>
                                        <img src={baseUrl + (officeInfo.url || detailInfo.companyLogo)} alt='audit1'/>
                                        {officeInfo.name || detailInfo.companyName}
                                    </div>
                                </Tooltip>}
                        </div>
                        <div className={'project_detail_text_info'}>
                            <div><span>{t('founded')}: </span>{dayjs(detailInfo.establishTime).format('YYYY')}</div>
                            {detailInfo.location !== "unknown" && detailInfo.location !== "不详" && detailInfo.location ? (
                                <div onClick={() => navigate(`/project/list?location=${detailInfo.location}`)}
                                     style={{marginLeft: 75, cursor: 'pointer'}}>
                                    <span>{t('location')}: </span>{detailInfo.location}</div>) : (<></>)}
                            <div><span>{t('ecosystem')}: </span>{detailInfo.ecology}</div>
                            {detailInfo.publicOfferingPrice && (
                                <div><span>{t('offerPrice')} </span>${detailInfo.publicOfferingPrice}</div>)}
                        </div>
                        {detailInfo.content && (
                            <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}>{t('details')}</div>
                                <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                            </div>)}
                        {
                            detailInfo.workExperienceList && <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}>{t('TeamMembers')}</div>
                                <div className={'project_form_team_list'}>
                                    {
                                        detailInfo.workExperienceList?.map((item, index) => {
                                            return (
                                                <Link to={`/people/detail/${item.expId}`}>
                                                    <div key={index} className={'project_form_team_list_item'}>
                                                        <img style={{width: 60, height: 60, borderRadius: '50%'}}
                                                             src={baseUrl + item.url}
                                                             alt='logo'/>
                                                        <div>
                                                            <div
                                                                className={'project_form_team_list_item_name'}>{item.name}</div>
                                                            <div>{item.post}</div>
                                                        </div>
                                                        <img className={'project_form_team_list_item_media'}
                                                             src={require('../../../assets/image/included/media_logo.png')}
                                                             alt='logo'/>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            keyEventList.length > 0 && <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}>{t('KeyEvents')}</div>
                                <TimeLine list={keyEventList}/>
                            </div>
                        }
                        {detailInfo.projectInvestDetailsList.length > 0 && (
                            <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}
                                     style={{marginBottom: 48}}>{t('investor')}</div>
                                {

                                    detailInfo.projectInvestDetailsList.map(item => {
                                        console.log('item', item)
                                        return (
                                            <div className={'project_investor_block'}>
                                                <div className={'project_investor_block_left'}>
                                                    <div className={'project_investor_block_left_time'}>
                                                        <img
                                                            src={require('../../../assets/image/project/investor_icon.png')}
                                                            alt='icon'/>{item.time}
                                                    </div>
                                                    <div
                                                        className={'project_investor_block_left_price'}>$ {item.amount ? formatMillion(item.amount) : item.amount}</div>
                                                    <div
                                                        className={'project_investor_block_left_series'}>{t("valuation")}：$ {item.valuation ? formatMillion(item.valuation) : item.valuation}</div>

                                                    <div className={'project_investor_block_left_series'}>{item.round}</div>
                                                    <div className={'project_investor_block_left_link'} onClick={() => {
                                                        let url = item.link
                                                        if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                                            window.open(item.link)
                                                        } else {
                                                            window.open("http://" + url.split("://")[0])
                                                        }
                                                    }}>
                                                        <img
                                                            src={require('@/assets/image/project/investor_link_icon.png')}
                                                            alt='link'/>
                                                        {t('link')}
                                                    </div>
                                                    <div className={'project_investor_block_left_line'}></div>
                                                </div>
                                                <div className={'project_investor_block_right'}>
                                                    {item.ledInstitutionListInfo && <div>
                                                        <div>{t('leadInvestor')}</div>
                                                        <div className={'project_investor_leadInvestor_list'}>
                                                            {
                                                                item.ledInstitutionListInfo ? item.ledInstitutionListInfo.map(params => {
                                                                    return (<div onClick={() => {
                                                                        if (params.type === "0") {
                                                                            navigate(`/project/detail/${params.investorId}`)
                                                                        } else if (params.type === "1") {
                                                                            navigate(`/investors/detail/${params.investorId}`)
                                                                        } else {
                                                                            navigate(`/people/detail/${params.investorId}`)
                                                                        }
                                                                    }}
                                                                                 className={'project_investor_leadInvestor_item'}>
                                                                        <img
                                                                            className={'project_investor_leadInvestor_image'}
                                                                            src={baseUrl + params.logo} alt='icon'/>
                                                                        <span>{params.name}</span>
                                                                    </div>)
                                                                }) : (<></>)
                                                            }
                                                        </div>
                                                    </div>}
                                                    {item.invInfo.length > 0 && <div>
                                                        <div
                                                            className={'project_investor_block_right_bottom'}>{t('investor')}</div>
                                                        <div className={'project_investor_leadInvestor_list'}>
                                                            {
                                                                item.invInfo ? item.invInfo.map(params => {
                                                                    return (<div onClick={() => {
                                                                        if (params.type === "0") {
                                                                            navigate(`/project/detail/${params.investorId}`)
                                                                        } else if (params.type === "1") {
                                                                            navigate(`/investors/detail/${params.investorId}`)
                                                                        } else {
                                                                            navigate(`/people/detail/${params.investorId}`)
                                                                        }
                                                                    }}
                                                                                 className={'project_investor_leadInvestor_item'}>
                                                                        <img
                                                                            className={'project_investor_leadInvestor_image'}
                                                                            src={baseUrl + params.logo} alt='icon'/>
                                                                        <span>{params.name}</span>
                                                                    </div>)
                                                                }) : (<></>)
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                        {
                            detailInfo.foreignInvestList.length > 0 && (
                                <div className={'project_detail_details'}>
                                    <div className={'project_detail_details_title'}
                                         style={{marginBottom: 50}}>{t('overseasInvestment')}</div>
                                    <div className={'project_overseas_table'}>
                                        <div className={'project_form_bottom_table'}>
                                            <div className={'project_form_bottom_table_head_line'}>
                                                <div className={'project_form_bottom_table_head_sp'}>
                                                    <img src={require("../../../assets/image/included/icon_name.png")}
                                                         alt='name'/>
                                                    {t('name')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_round.png")}
                                                         alt='round'/>
                                                    {t('Round')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_amount.png")}
                                                         alt='amount'/>
                                                    {t('amount')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_valuation.png")}
                                                         alt='valuation'/>
                                                    {t('valuation')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_time.png")}
                                                         alt='time'/>
                                                    {t('date')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_investor.png")}
                                                         alt='investor'/>
                                                    {t('leadInvestor')}
                                                </div>
                                            </div>
                                            {
                                                detailInfo.foreignInvestList.map((item, index) => {
                                                    return (
                                                        <div className={'project_form_bottom_table_item_line'}
                                                             style={{marginBottom: 10}}>
                                                            <div className={'project_form_bottom_table_item'}>
                                                                <img className={'project_name_icon'}
                                                                     src={require('@/assets/image/project/project1.png')}
                                                                     alt='project1'/>
                                                                <span>{item.projectName}</span>
                                                                <img className={'project_link_icon'}
                                                                     src={color === 'white' ? require('@/assets/image/project/link_icon.png') : require('@/assets/image/project/link_icon_dark.png')}
                                                                     alt='link'/>
                                                            </div>
                                                            <div className={'project_form_bottom_table_item'}>{item.round}</div>
                                                            <div
                                                                className={'project_form_bottom_table_item'}>$ {item.amount ? formatMillion(item.amount) : item.amount}</div>
                                                            <div
                                                                className={'project_form_bottom_table_item'}>$ {item.valuation?formatMillion(item.valuation):item.valuation}</div>
                                                            <div className={'project_form_bottom_table_item'}>{item.time}</div>
                                                            <div className={'project_form_bottom_table_item'}>{item.ledStatus === "1"?t('YES'):t('NO')}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {!searchParams.get("type") && <Comment id={id}></Comment>}
                    </div>
                    <div className={'project_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'project_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'project_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            {
                                recommendList.map(item=>{
                                    return (
                                        <div style={{cursor: 'pointer'}} onClick={() => {
                                            navigate(`/project/detail/${item.id}`)
                                            getDetail()
                                        }}>
                                            <div className={'project_contributor_project'}>
                                                <img src={baseUrl + item.logoUrl} alt='logo'/>
                                                <div>
                                                    <div
                                                        className={'project_contributor_project_title'}>{item.name}</div>
                                                    <div
                                                        className={'project_contributor_project_text'}>{item.introduction}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'project_comment_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'project_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'project_textarea'} autoSize={{ minRows: 6, maxRows: 6 }}></Input.TextArea>
                    <div className={'project_handle_btn'}>
                        <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'project_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('ReviewOpinions')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{detailInfo.examineReport}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <Link to={`/project/update/${id}?type=audit&update=1`}>
                            <div className='project_handle_btn_confirm'>{t('toUpdate')}</div>
                        </Link>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </div>
    )
}

export default Detail;
