import request from '@/utils/request'

class Api {
    submitMeetingForm(data) {
        return request.post('/meetingInclusion/add',data)
    }

    queryMeetingList(data) {
        return request.get('/meetingInclusion/esList',data)
    }

    queryMeetingDetail(data) {
        return request.get('/meetingInclusion/getEsInfo',data)
    }

    updateMeetingDetail(data) {
        return request.post('/meetingInclusion/update',data)
    }

    delMeeting(id) {
        return request.del(`/meetingInclusion/${id}`)
    }

    queryMeetingDetailFromDB(data) {
        return request.get('/meetingInclusion/getInfo',data)
    }

    updateMeetingDraft(data) {
        return request.post('/meetingInclusion/edit',data)
    }

    queryOtherMeeting(data) {
        return request.get('/meetingInclusion/otherEsList',data)
    }

    queryCheckName(name) {
        return request.get('/meetingInclusion/checkName',{name})
    }
}

export default new Api();
