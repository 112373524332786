import './modal_components.scss'

function Modal(props) {
    return (
        <>
            {
                props.open && (
                    <div className={'modal_dialog_main'}>
                        <div className={'modal_dialog_block'}>{props.children}</div>
                    </div>
                )
            }
        </>
    )
}

export default Modal;
