import request from '@/utils/request'

class Api {
    submitToolsForm(data) {
        return request.post('/toolInclusion/add',data)
    }

    submitHackForm(data) {
        return request.post('/hackathonInclusion/add',data)
    }

    queryToolsList(data) {
        return request.get('/toolInclusion/esList',data)
    }

    queryToolsDetail(data) {
        return request.get('/toolInclusion/getEsInfo',data)
    }

    updateToolsDetail(data) {
        return request.post('/toolInclusion/update',data)
    }

    queryHackList(data) {
        return request.get('/hackathonInclusion/esList',data)
    }

    queryHackDetail(data) {
        return request.get('/hackathonInclusion/getEsInfo',data)
    }

    updateHackDetail(data) {
        return request.post('/hackathonInclusion/update',data)
    }

    delTools(id) {
        return request.del(`/toolInclusion/${id}`)
    }

    queryCheckNameTools(name) {
        return request.get('/toolInclusion/checkName',{name})
    }

    delHack(id) {
        return request.del(`/hackathonInclusion/${id}`)
    }

    queryToolsDetailFromDB(data) {
        return request.get('/toolInclusion/getInfo',data)
    }

    queryHackDetailFromDB(data){
        return request.get('/hackathonInclusion/getInfo',data)
    }

    updateToolsDraft(data) {
        return request.post('/toolInclusion/edit',data)
    }

    updateHackDraft(data) {
        return request.post('/hackathonInclusion/edit',data)
    }

    queryOtherTools(data) {
        return request.get('/toolInclusion/recommendEsList',data)
    }

    queryCheckNameHack(name) {
        return request.get('/hackathonInclusion/checkName',{name})
    }
}

export default new Api();
