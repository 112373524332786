import '../../../Project/Detail/project_detail_module.scss'
import { useParams,Link } from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "@/utils/useSyncCallback";
import moreApi from "@/api/more";
import dayjs from "dayjs";
import Comment from "@/components/Comment";
import DetailHandle from "@/components/DetailHandle";
import {projectTypeObj} from "@/enum/projectType";
import CreateInfo from "@/components/CreateInfo";
import commonApi from "@/api/common";
import {getMediaIcon} from '../../../../enum/mediaType'

function Detail({id,newDetail}) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({})
    const [createInfo, setCreateInfo] = useState({})
    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        moreApi.queryToolsDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo(res.data)
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        moreApi.queryToolsDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo(res.data)
        })
    })

    useEffect(() => {
        if (newDetail) {
            getAuditDetail()
        }else {
            getDetail()
        }
    }, []);
    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>
                <RouterLine name={detailInfo.name}/>
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl+detailInfo.logoUrl} alt='project'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>{detailInfo.name}</div>
                                    <div className={'project_detail_info_context'}>{detailInfo.synopsis}</div>
                                    <div className={'project_detail_info_tag'}>
                                        {
                                            detailInfo.label && detailInfo.label.split(',').map(item => {
                                                return (
                                                    <div className={'project_detail_info_tag_item'}>
                                                        <img
                                                            src={color === 'white' ? require('@/assets/image/project/tool.png') : require('@/assets/image/project/tool_dark.png')}
                                                            alt='tool'/>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {detailInfo.mainId && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.tool.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'project_detail_info_link_list'}>
                            {detailInfo.webType && <div className={'project_detail_info_link'} onClick={() => {
                                window.open(detailInfo.officialWebsite)
                            }}>
                                <img
                                    src={getMediaIcon(detailInfo.webType, color)}/>
                                {detailInfo.webType}
                            </div>}
                            {
                                detailInfo.reportAddress && (
                                    <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                             color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottomLeft"
                                             title={'Audit Report From XXX Company'}>
                                        <div className={'project_detail_info_link'}>
                                            <img src={require('@/assets/image/project/audit1.png')} alt='audit1'/>
                                            Audit Report
                                        </div>
                                    </Tooltip>
                                )
                            }
                        </div>
                        <div className={'project_detail_text_info'}>
                            <div><span>{t('founded')}: </span>{dayjs(detailInfo.createTime).format('YYYY')}</div>
                            <div><span>{t('ecosystem')}:</span> {detailInfo.ecology}</div>
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                        </div>
                        <Comment id={id}></Comment>
                    </div>
                    <div className={'project_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'project_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'project_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'project_comment_dialog'}>
                    <img onClick={()=>setIsModalOpen(false)} className={'project_comment_dialog_close'} src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'project_textarea'} autoSize={{ minRows: 6, maxRows: 6 }}></Input.TextArea>
                    <div className={'project_handle_btn'}>
                        <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'project_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'project_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('update')}</div>
                    <div className={'project_detail_update_block'}>
                        <div className={'project_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'project_detail_update_btn_list'}>
                            <Link to={`/more/tool/update/${id}`}>
                                <div>{t('updateContent')}</div>
                            </Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'project_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
