import '../../../Project/Detail/project_detail_module.scss'
import {useParams, Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import useSyncCallback from "@/utils/useSyncCallback";
import projectsApi from "@/api/projects";
import dayjs from "dayjs";
import commonApi from '@/api/common'
import {projectTypeObj} from "@/enum/projectType";
import message from '@/utils/message'
import {getMediaIcon} from '../../../../enum/mediaType'

function Detail({id,newDetail}) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [keyEventList,setKeyEventList] = useState([])
    const [detailInfo, setDetailInfo] = useState({})

    const getDetail = useSyncCallback(()=> {
        projectsApi.queryProjectsDetailFromDB({
            id: id
        }).then((res)=>{
            setKeyEventList([...res.data.eventList])
            setDetailInfo({...res.data})
        })
    })

    const getAuditDetail = useSyncCallback(()=> {
        projectsApi.queryProjectsDetailFromDB({
            id: id
        }).then((res)=>{

            const data = {
                pageNum: 1,
                pageSize: 20,
                inclusionId: id
            }
            Promise.all([
                commonApi.queryKeyEvent(data),
                commonApi.queryForeignInvest(data),
                commonApi.queryProjectInvestDetails(data)
            ]).then((result)=>{
                setKeyEventList([...result[0].rows])
                setDetailInfo(()=>{
                    return {
                        ...res.data,
                        foreignInvestList: result[1].rows,
                        projectInvestDetailsList: result[2].rows
                    }
                })
            })
        })
    })

    useEffect(()=>{
        console.log('newDetail',newDetail)
        if(newDetail) {
            getAuditDetail()
        }else {
            getDetail()
        }
    },[])

    return (
        <div style={{paddingBottom: 100}} className={'main_back main_back_white'}>
            <div className={'project_detail_main_block'}>
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl + detailInfo.logoUrl} alt='project'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>
                                        {detailInfo.name}
                                        <div className={'project_detail_info_running'}>
                                            <img src={require('@/assets/image/project/running.png')} alt='running'/>
                                            {t('running')}
                                        </div>
                                    </div>
                                    <div className={'project_detail_info_context'}>{detailInfo.introduction}</div>
                                    <div className={'project_detail_info_tag'}>
                                        {
                                            detailInfo.label && detailInfo.label.split(',').map((item,index)=> {
                                                return (
                                                    <div key={index} className={'project_detail_info_tag_item'}>
                                                        <img
                                                            src={color === 'white' ? require('@/assets/image/project/tool.png') : require('@/assets/image/project/tool_dark.png')}
                                                            alt='tool'/>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'project_detail_info_link_list'}>
                            {
                                detailInfo.smcList?.length > 0 && detailInfo.smcList.map((item,index)=> {
                                    return (
                                        <div key={index} className={'project_detail_info_link'} onClick={()=>window.open(item.webUrl)}>
                                            <img alt='net'
                                                src={getMediaIcon(item.webType,color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                            <div className={'project_detail_info_link'} onClick={()=>window.open(detailInfo.whitePaperLink)}>
                                <img
                                    src={color === 'white' ? require('@/assets/image/project/whitePaper.png') : require('@/assets/image/project/whitePaper_dark.png')}/>
                                White Paper
                            </div>
                            <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                     color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottomLeft"
                                     title={'Audit Report From XXX Company'}>
                                <div className={'project_detail_info_link'}>
                                    <img src={require('@/assets/image/project/audit1.png')} alt='audit1'/>
                                    Audit Report
                                </div>
                            </Tooltip>
                            <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                     color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottomLeft"
                                     title={'Audit Report From XXX Company'}>
                                <div className={'project_detail_info_link'}>
                                    <img src={require('@/assets/image/project/audit2.png')} alt='audit2'/>
                                    Audit Report
                                </div>
                            </Tooltip>
                        </div>
                        <div className={'project_detail_text_info'}>
                            <div><span>{t('founded')}: </span>{dayjs(detailInfo.createTime).format('YYYY')}</div>
                            <div style={{marginLeft: 75}}><span>{t('location')}: </span>{detailInfo.location}</div>
                            <div><span>{t('ecosystem')}: </span>--</div>
                            <div><span>{t('offerPrice')} </span>$0.5280</div>
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                        </div>
                        {
                            detailInfo.teamMember && (
                                <div className={'project_detail_details'}>
                                    <div className={'project_detail_details_title'}>{t('TeamMembers')}</div>
                                    <div className={'project_form_team_list'}>
                                        {
                                            JSON.parse(detailInfo.teamMember).map((item,index)=>{
                                                return (
                                                    <div key={index} className={'project_form_team_list_item'}>
                                                        <img style={{width: 60,height: 60,borderRadius: '50%'}}
                                                            src={baseUrl+item.headImgUrl}
                                                            alt='logo'/>
                                                        <div>
                                                            <div className={'project_form_team_list_item_name'}>{item.name}</div>
                                                            <div>{item.content}</div>
                                                        </div>
                                                        <img className={'project_form_team_list_item_media'}
                                                             src={require('@/assets/image/included/media_logo.png')}
                                                             alt='logo'/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('KeyEvents')}</div>
                            <TimeLine list={keyEventList}/>
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}
                                 style={{marginBottom: 48}}>{t('investor')}</div>
                            {
                                detailInfo.projectInvestDetailsList && (
                                    detailInfo.projectInvestDetailsList.map(item=> {
                                        return (
                                            <div className={'project_investor_block'}>
                                                <div className={'project_investor_block_left'}>
                                                    <div className={'project_investor_block_left_time'}>
                                                        <img
                                                            src={require('@/assets/image/project/investor_icon.png')}
                                                            alt='icon'/>{item.time}
                                                    </div>
                                                    <div
                                                        className={'project_investor_block_left_price'}>$ {item.amount}</div>
                                                    <div
                                                        className={'project_investor_block_left_series'}>{item.round}</div>
                                                    <div className={'project_investor_block_left_link'} onClick={()=>{
                                                        window.open(item.link)
                                                    }}>
                                                        <img
                                                            src={require('@/assets/image/project/investor_link_icon.png')}
                                                            alt='link'/>
                                                        {t('link')}
                                                    </div>
                                                    <div className={'project_investor_block_left_line'}></div>
                                                </div>
                                                <div className={'project_investor_block_right'}>
                                                    <div>
                                                        <div>{t('leadInvestor')}</div>
                                                        <div className={'project_investor_leadInvestor_list'}>
                                                            <div className={'project_investor_leadInvestor_item'}>
                                                                <img className={'project_investor_leadInvestor_image'}
                                                                     src={require('@/assets/image/project/project_investor_icon.png')}
                                                                     alt='icon'/>
                                                                <span>{item.led}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={'project_investor_block_right_bottom'}>{t('investor')}</div>
                                                        <div className={'project_investor_tag_list'}>
                                                            <div className={'project_investor_tag_item'}>{item.investor}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}
                                 style={{marginBottom: 50}}>{t('overseasInvestment')}</div>
                            <div className={'project_overseas_table'}>
                                <div className={'project_form_bottom_table'}>
                                    <div className={'project_form_bottom_table_head_line'}>
                                        <div className={'project_form_bottom_table_head_sp'}>
                                            <img src={require("@/assets/image/included/icon_name.png")}
                                                 alt='name'/>
                                            {t('name')}
                                        </div>
                                        <div className={'project_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_round.png")}
                                                 alt='round'/>
                                            {t('Round')}
                                        </div>
                                        <div className={'project_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_amount.png")}
                                                 alt='amount'/>
                                            {t('amount')}
                                        </div>
                                        <div className={'project_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_valuation.png")}
                                                 alt='valuation'/>
                                            {t('valuation')}
                                        </div>
                                        <div className={'project_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_time.png")}
                                                 alt='time'/>
                                            {t('date')}
                                        </div>
                                        <div className={'project_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_investor.png")}
                                                 alt='investor'/>
                                            {t('leadInvestor')}
                                        </div>
                                    </div>
                                    {
                                        detailInfo.foreignInvestList && (
                                            detailInfo.foreignInvestList.map((item, index) => {
                                                return (
                                                    <div className={'project_form_bottom_table_item_line'}
                                                         style={{marginBottom: 10}}>
                                                        <div className={'project_form_bottom_table_item'}>
                                                            <img className={'project_name_icon'}
                                                                 src={require('@/assets/image/project/project1.png')}
                                                                 alt='project1'/>
                                                            <span>{item.projectName}</span>
                                                            <img className={'project_link_icon'}
                                                                 src={color === 'white' ? require('@/assets/image/project/link_icon.png') : require('@/assets/image/project/link_icon_dark.png')}
                                                                 alt='link'/>
                                                        </div>
                                                        <div className={'project_form_bottom_table_item'}>{item.round}</div>
                                                        <div
                                                            className={'project_form_bottom_table_item'}>$ {item.amount}</div>
                                                        <div
                                                            className={'project_form_bottom_table_item'}>$ {item.valuation}</div>
                                                        <div className={'project_form_bottom_table_item'}>{item.time}</div>
                                                        <div className={'project_form_bottom_table_item'}>{item.ledStatus === "1"?t('YES'):t('NO')}</div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'project_detail_main_right'}>
                        <div className={'project_detail_contributor'}>
                            <div className={'project_detail_top'}>
                                <span>{t('informationContributor')}</span>
                                <Link to={'/project/history'}>
                                    <span>
                                        {t('HistoricalInclusion')}
                                        <img src={require('@/assets/image/project/history_arrow.png')} alt='arrow'/>
                                    </span>
                                </Link>
                            </div>
                            <div className={'project_contributor_avatar'}>
                                <img src={require('@/assets/image/home/avatar.png')} alt='avatar'/>
                                <div>Mark</div>
                            </div>
                            <div className={'project_contributor_msg'}>
                                <div className={'project_contributor_msg_title'}>IPFS</div>
                                <div
                                    className={'project_contributor_msg_key'}>QmWBbKvLhVnkryKG6F5YdkcnoVahwD7Qi3CeJeZgM6Tq68
                                </div>
                            </div>
                            <div className={'project_contributor_msg'}>
                                <div className={'project_contributor_msg_title'}>TX Hash</div>
                                <div
                                    className={'project_contributor_msg_key'}>QmWBbKvLhVnkryKG6F5YdkcnoVahwD7Qi3CeJeZgM6Tq68
                                </div>
                            </div>
                            <div className={'project_contributor_msg'}>
                                <div className={'project_contributor_msg_title'}>Events</div>
                                <div
                                    className={'project_contributor_msg_key'}>QmWBbKvLhVnkryKG6F5YdkcnoVahwD7Qi3CeJeZgM6Tq68
                                </div>
                            </div>
                            <div className={'project_contributor_msg'}>
                                <div className={'project_contributor_msg_title'}>Created By</div>
                                <div
                                    className={'project_contributor_msg_key'}>QmWBbKvLhVnkryKG6F5YdkcnoVahwD7Qi3CeJeZgM6Tq68
                                </div>
                            </div>
                        </div>
                        <div className={'project_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'project_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detail;
