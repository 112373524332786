import '../user_module.scss'
import Pagination from "../../../components/Pagination";
import {Link} from "react-router-dom";
import {checkDetailPage, projectTypeIndex} from "../../../enum/projectType";
import {useTranslation} from "react-i18next";

function Project(props) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const list = props.list || []
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3_small'}>
                {
                    list.map((item, index) => {
                        return (
                            <Link to={checkDetailPage(item.type,item.id)}>
                                <div className={'project_table_item collection_project_table'}>
                                    <div className={'project_table_item_top'}>
                                        <img src={baseUrl+item.url} alt='logo'/>
                                        <div className={'project_table_item_text'}>
                                            <div className={'project_table_item_title'}>{item.name}</div>
                                            <div className={'project_table_item_content'}>{item.type?t(projectTypeIndex[item.type]):''}</div>
                                            <div className={'project_table_item_content'}>{item.content}</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Project;
