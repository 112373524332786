import './meeting_detail_module.scss'
import {useParams, Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "../../../utils/useSyncCallback";
import meetingApi from "../../../api/meeting";
import dayjs from 'dayjs'
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import CreateInfo from "../../../components/CreateInfo";
import commonApi from "../../../api/common";
import {getMediaIcon} from "../../../enum/mediaType";

function Detail() {
    const navigate = useNavigate()
    const route = useLocation()
    const {id} = useParams()
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({})
    const [createInfo, setCreateInfo] = useState({})
    const [otherList,setOtherList] = useState([])
    const [remarkModel, setRemarkModel] = useState(false)

    function setComment() {
        setIsModalOpen(false)
    }

    const getOtherMeeting = useSyncCallback(()=>{
        meetingApi.queryOtherMeeting({
            lang: detailInfo.lang,
            theme: detailInfo.theme
        }).then(res=>{
            setOtherList(res.rows)
        })
    })

    const getDetail = useSyncCallback(()=>{
        meetingApi.queryMeetingDetail({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
            getOtherMeeting()
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        meetingApi.queryMeetingDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
            getOtherMeeting()
            if (searchParams.get("status") === "2") {
                console.log("userInfo.userId",userInfo.userId)
                console.log("userInfo.userId....res",res)
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    useEffect(() => {
        if (searchParams.get("type") || (searchParams.get("status") === "2" || searchParams.get("status") === "0")){
            getAuditDetail()
        }else {
            getDetail()
        }
    }, [route.pathname]);

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'meeting_detail_main_block'}>
                {
                    searchParams.get("type") === 'audit'?
                        (<div className={'router_line_block'}>
                            <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                                 alt='arrow'/>
                            <Link to={-1}>
                                <span className={'router_line_block_color'}>{t('back')}</span>
                            </Link>
                        </div>):(<RouterLine name={detailInfo.name}/>)
                }
                <div className={'meeting_detail_main'}>
                    <div className={'meeting_detail_main_left'}>
                        <div className={'meeting_detail_left_info'}>
                            <div className={'meeting_detail_left_info_content'}>
                                <img className={'meeting_detail_info_image'} style={{borderRadius: 20}}
                                     src={baseUrl+detailInfo.meetingUrl} alt='Empty'/>
                            </div>

                            {(detailInfo.mainId && !searchParams.get("type")) && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.meeting.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'meeting_detail_left_text'}>{detailInfo.theme}</div>
                        <div className={'meeting_detail_info_link_list'}>
                            <div className={'meeting_detail_info_link_time'}>
                                <img src={require('@/assets/image/meeting/meeting_time_icon.png')} alt='time'/>
                                {dayjs(detailInfo.startTime).format('YYYY-MM-DD')}~{dayjs(detailInfo.endTime).format('YYYY-MM-DD')}
                            </div>
                            <div className={'meeting_detail_info_link'} onClick={() => {
                                let url = detailInfo.website
                                if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                    window.open(detailInfo.website)
                                }else {
                                    window.open("http://"+url.split("://")[0])
                                }
                            }}>
                                <img
                                    src={getMediaIcon("Website", color)}/>
                                Website
                            </div>
                            <div className={'meeting_detail_info_link'} onClick={() => {
                                let url = detailInfo.source
                                if (url.split("://")[0] === "http" || url.split("://")[0 ] === "https"){
                                    window.open(detailInfo.source)
                                }else {
                                    window.open("http://"+url.split("://")[0])
                                }
                            }}>
                                <img
                                    src={getMediaIcon("Website", color)}/>
                                {t("source")}
                            </div>
                        </div>

                        <div className={'meeting_detail_details_content'}>{detailInfo.content}</div>
                        <div className={'meeting_detail_details'} style={{paddingBottom: 40}}>
                            <div className={'meeting_detail_details_title'}>{t('Participants')}</div>
                            <div className={'meeting_form_team_list'}>
                                {
                                    JSON.parse(detailInfo.participants || '[]').map(item=>{
                                        return (
                                            <div className={'meeting_form_team_list_item'} onClick={()=>{
                                                navigate(`/people/detail/${item.id}`)
                                            }}>
                                                <img src={baseUrl+item.headImgUrl} style={{width: 60,height: 60,borderRadius: '50%'}}
                                                     alt='logo'/>
                                                <div style={{width: '100%'}}>
                                                    <div className={'meeting_form_team_list_item_name'}>{item.name}</div>
                                                    <div className={'meeting_form_team_list_item_content'}>{item.content}</div>
                                                </div>
                                                <img className={'meeting_form_team_list_item_media'}
                                                     src={require('../../../assets/image/included/media_logo.png')}
                                                     alt='logo'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                        {!searchParams.get("type") && <Comment id={id}></Comment>}
                    </div>
                    <div className={'meeting_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'meeting_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'meeting_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('otherMeetings')}</span>
                            </div>
                            {
                                otherList.map(item=>{
                                    return (

                                        <div style={{cursor: 'pointer'}} onClick={()=>{
                                            navigate(`/meeting/detail/${item.id}`)
                                            getDetail()
                                        }}>
                                            <div className={'meeting_contributor_project'}>
                                                <img src={baseUrl + item.meetingUrl} style={{borderRadius: 20}}
                                                     alt='logo'/>
                                                <div>
                                                    <div className={'meeting_contributor_title'}>{item.theme}</div>
                                                    <div className={'meeting_contributor_text'}>
                                                        <img
                                                            src={require('../../../assets/image/meeting/time_icon.png')}
                                                            alt='time'/>
                                                        {item.startTime}~{item.endTime}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'meeting_comment_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'meeting_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'meeting_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'meeting_textarea'}
                                    autoSize={{minRows: 6, maxRows: 6}}></Input.TextArea>
                    <div className={'meeting_handle_btn'}>
                    <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'meeting_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'meeting_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'meeting_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'meeting_comment_dialog_title'}>{t('update')}</div>
                    <div className={'meeting_detail_update_block'}>
                        <div className={'meeting_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'meeting_detail_update_btn_list'}>
                            <Link to={`/meeting/update/${id}`}>
                                <div>{t('updateContent')}</div>
                            </Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'meeting_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('ReviewOpinions')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{detailInfo.examineReport}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <Link to={`/meeting/update/${id}?type=audit&update=1`}>
                            <div className='project_handle_btn_confirm'>{t('toUpdate')}</div>
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
