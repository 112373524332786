import { createSlice } from '@reduxjs/toolkit'
import message from "../../utils/message";
import Web3 from "web3";
import {walletEqualsStatus} from "../wallet/walletSlice";
import store from "../../app/store";
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')) : {}
    },
    reducers: {
        setUserInfo: (state, action) => {
            state.value = action.payload
            const value = state.value
            if (value?.address) {
                if(window.ethereum !== void 0) {
                    const web3 = new Web3(window.ethereum)
                    web3.eth.getAccounts().then((res)=>{
                        if (res.length === 0) {
                            window.ethereum.request({ method: 'eth_requestAccounts' })
                        }else {
                            if (res[0].toUpperCase() === value.address.toUpperCase()){
                                store.dispatch(walletEqualsStatus(false))
                            }else {
                                store.dispatch(walletEqualsStatus(true))
                            }
                        }
                    })
                }
            }
            localStorage.setItem('user',JSON.stringify(state.value))
        },
    }
})

export const { setUserInfo } = userSlice.actions

export default userSlice.reducer
