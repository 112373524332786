import '../user_module.scss'
import {Input} from "antd";
import {useTranslation} from "react-i18next";
import userApi from '@/api/user'
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import message from "../../../utils/message";
import dayjs from 'dayjs'

function Friend() {
    const { t } = useTranslation()
    const location = useLocation()
    const user = useSelector(state => state.user.value)
    const [link,setLink] = useState('')
    const [tableList,setTableList] = useState([])
    const [inviteLink, setInviteLink] = useState('')

    useEffect(()=>{
        setInviteLink(()=>{
            return `${window.location.origin}/login?invite=${user.invitationCode}&inviteId=${user.userId}`
        })
        getFriendList()
    },[])
    function getFriendList() {
        userApi.queryInviteFriendList({
            invitationId: user.userId
        }).then(res=>{
            console.log("inviteList.....",res)
            setTableList(res.rows)
        })
    }


    return (
        <div className={'profile_main_block'}>
            <div className={'profile_main_block_title'}>{t('FriendInvitation')}</div>
            <div className={'profile_main_nickname'} style={{marginTop: 60}}>
                <div className={'profile_main_nickname_title'}>{t('InvitationLink')}</div>
                <Input value={inviteLink} disabled suffix={
                    <>
                        <img className={'friend_invite_link_copy_icon'} onClick={()=>{
                            try {
                                navigator.clipboard.writeText(inviteLink)
                                message.success(t('copySuccess'))
                            }catch (err) {
                                message.success(t('copyFail'))
                            }
                        }}
                             src={require('../../../assets/image/user/copy_icon.png')} alt='copy'/>
                    </>
                } className={'friend_invite_link included_form_input'}/>
            </div>
            {
                tableList.length > 0 && (
                    <div className={'friend_table_block'}>
                        <div className={'wiki_table_title'}>
                            <div>
                                <img src={require('../../../assets/image/user/icon_E-MailAddress.png')}/>
                                {t('e-mailAddress')}
                            </div>
                            <div>
                                <img src={require('../../../assets/image/user/icon_AuditTime.png')}/>
                                {t('RegistrationTime')}
                            </div>
                            <div>
                                <img src={require('../../../assets/image/user/icon_address.png')}/>
                                {t('CertificationOfInclusion')}
                            </div>
                        </div>
                        {
                            tableList.map(item => {
                                return (
                                    <div className={'wiki_table_cell'}>
                                        <div>{item.mailbox}</div>
                                        <div>{dayjs(item.createTimeStamp).format('YYYY-MM-DD HH:mm:ss')}</div>
                                        <div>
                                            {
                                                item.status === "1" &&
                                                <img style={{width: 48, height: 48}}
                                                     src={require('../../../assets/image/user/incluesion_icon.png')}
                                                     alt='icon'/>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            {/*<div className={'friend_save_btn'}>
                <div className={'friend_main_save'}>{t('save')}</div>
            </div>*/}
        </div>
    )
}

export default Friend;
