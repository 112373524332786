import '../user_module.scss'
import {Link, useNavigate} from "react-router-dom";
import Pagination from "../../../components/Pagination";

function Other(props) {
    const tableList = props.list || []
    const baseUrl = process.env.REACT_APP_BASE_URL
    const navigate = useNavigate()

    function toPage(item) {
        if (item.type === '6') {
            navigate(`/more/tool/detail/${item.id}?status=${item.status}`)
        }else if(props.type === '7'){
            navigate(`/more/hackathon/detail/${item.id}?status=${item.status}`)
        }
    }
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3_small'}>
                {
                    tableList.map((item, index) => {
                        return (
                            <div onClick={()=>toPage(item)} className={'project_table_item'}>
                                { item.status === "0" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/audit.png')} alt='audit'/>}
                                { item.status === "1" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/collecteo.png')} alt='audit'/>}
                                { item.status === "2" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/peview.png')} alt='audit'/>}
                                <div className={'project_table_item_top'}>
                                    <img src={baseUrl+item.logoUrl} alt='logo'/>
                                    <div className={'project_table_item_text'}>
                                        <div className={'project_table_item_title'}>{item.name}</div>
                                        <div className={'project_table_item_content'}>{item.content}</div>
                                    </div>
                                </div>
                                <div className={'project_table_item_bottom'}>
                                    {   item.label &&
                                        item.label.split(",").map(data=>{
                                            return (
                                                    <div className={'project_table_item_bottom_tool'}>{data}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Other;
