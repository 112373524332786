import request from '@/utils/request'

class api {
    submitCharacterForm(data) {
        return request.post('/figureInclusion/add',data)
    }

    queryCharacterList(data) {
        return request.get('/figureInclusion/esList',data)
    }

    queryCharacterDetail(data) {
        return request.get('/figureInclusion/getEsInfo',data)
    }

    updateCharacterForm(data) {
        return request.post('/figureInclusion/update',data)
    }

    queryCharacterOverview(data) {
        return request.get('/figureInclusion/getInvestmentSurvey',data)
    }

    queryCharacterDetailFromDB(data) {
        return request.get('/figureInclusion/getInfo',data)
    }

    delCharacter(id) {
        return request.del(`/figureInclusion/${id}`)
    }

    updateCharacterDraft(data) {
        return request.post('/figureInclusion/edit',data)
    }

    queryRecommendPeople(data) {
        return request.get('/figureInclusion/getRelatedFigureList',data)
    }

    queryCheckName(name) {
        return request.get('/figureInclusion/checkName',{name})
    }
}

export default new api();
