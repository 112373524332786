import { createSlice } from '@reduxjs/toolkit'

export const colorSlice = createSlice({
    name: 'color',
    initialState: {
        value: sessionStorage.getItem('theme') || 'white'
    },
    reducers: {
        isDark: state => {
            state.value = 'dark'
            document.documentElement.setAttribute('theme', 'dark');
            sessionStorage.setItem('theme','dark')
        },
        isWhite: state => {
            state.value = 'white'
            document.documentElement.setAttribute('theme', 'light');
            sessionStorage.setItem('theme','white')
        }
    }
})

export const { isDark,isWhite } = colorSlice.actions

export default colorSlice.reducer
