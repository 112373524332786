function SoOn() {
    return (
        <div className='soon_block'>
            <img src={require('../../../assets/image/more/soonImage.png')} alt='soon'/>
            <div style={{fontSize: 18,fontWeight: 500}}>Coming Soon</div>
        </div>
    )
}

export default SoOn;
