import "../../Included/included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, DatePicker, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import { SearchOutlined } from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Radio from '@/components/Radio/index'
import useSyncCallback from "../../../utils/useSyncCallback";
import commonApi from "../../../api/common";
import characterApi from "../../../api/character";
import message from "../../../utils/message";
import investorApi from "../../../api/investor";
import projectsApi from "../../../api/projects";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import Language from "../../../components/Language";
import {setReview} from "../../../utils/middle";
import {peopleContract, playerContract, projectContract, web3} from "../../../utils/web3Utils";
import {auditStatusObj} from "../../../enum/auditStatus";
import userApi from '@/api/user'
import {formatWithSeparator} from "../../../utils/formatUtils";
import Modal from "../../../components/Modal";

function Project() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const {id} = useParams()
    const lang = useSelector(state => state.language.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const userInfo = useSelector(state=>state.user.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [tab,setTab] = useState('')
    const [tabList,setTabList] = useState([])
    const [ecology,setEcology] = useState('')
    const [ecologyList,setEcologyList] = useState([])
    const [mediaList,setMediaList]=useState([{webType: media[0].value,webUrl: ''}])
    const [investmentDatailList,setInvestmentDatailList] = useState([])
    const [schoolPage,setSchoolPage] = useState(1)
    const [schoolList,setSchoolList] = useState([])
    const [schoolName,setSchoolName] = useState('')
    const [schoolValue, setSchoolValue] = useState([])
    const [checkSchoolValue, setCheckSchoolValue] = useState([])
    const [schoolListData,setSchoolListData] = useState([])
    const [iPage,setIPage] = useState(1)
    const [iList,setIList] = useState([])
    const [language, setLanguage] = useState('0')
    const [remarkModel, setRemarkModel] = useState(false)
    const investmentDataItem = {
        projectName: '',
        synopsis:'',
        projectId: '',
        logo: '',
        coinIssuanceSatus: '',
        label:'',
        round: '',
        amount: null,
        valuation: null,
        time: '',
        led:''
    }
    const [headImgUrl,setHeadImgUrl] = useState('')
    const [name, setName] = useState('')
    const [content,setContent] = useState('')
    const [projectPage,setProjectPage] = useState(1)
    const [projectOption, setProjectOption] = useState([])
    const [projectList, setProjectList] = useState([])
    const [invesList,setInvesList] = useState([])
    const [mainId,setMainId] = useState('')
    const [oldLang, setOldLang] = useState(0)
    const [spinning, setSpinning] = useState(false)
    const [staked, setStaked] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [thisId, setThisId] = useState("")
    const [createBy, setCreateBy] = useState('')
    const [createTime, setCreateTime] = useState('')
    const [qmHash, setQmHash] = useState('')

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addTabs() {
        setTabList((e)=>{
            return [...e,tab]
        })
        setTab('')
    }

    function delTabs(index) {
        const list = [...tabList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setTabList(list)
            }
        })
    }

    function addMediaLink() {
        const data = {webType: media[0].value,webUrl: ''}
        const list = [...mediaList]
        list.push(data)
        setMediaList(list)
    }

    const getUniversity = useSyncCallback((e = void 0)=>{
        const data = {
            pageNum: schoolPage,
            pageSize: 10,
            name: e
        }
        commonApi.queryUniversityList(data).then(res=>{
            let school = res.rows.map(item=>{
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setSchoolList([...school])
            setSchoolListData([...res.rows])
        })
    })

    const addSchoolValue = useSyncCallback(()=>{
        console.log(schoolList)
        let list = schoolListData.filter(item=>{
            for (const data of schoolValue) {
                if (item.id === data) return item
            }
        })
        setCheckSchoolValue([...list])
    })

    const delSchoolValue = useSyncCallback((id)=>{
        let list = schoolValue.filter(item=>item !== id)
        setSchoolValue([...list])
        addSchoolValue()
    })

    const submitForm = useSyncCallback((type)=>{
        setSpinning(true)
        const investmentListDataMap = investmentDatailList.map(item=>{
            let timeObj = {}
            if (item.time) {
                timeObj = {...item}
                timeObj.time = timeObj.time.format('YYYY-MM-DD HH:mm:ss')
            }
            return timeObj
        })
        const data = {
            mainId,
            headImgUrl,name,content,educationExp: checkSchoolValue.map(item=>item.id).toString(),smcList: mediaList,investDetailsList: investmentListDataMap,lang: language,submitType: type,createTime,createBy,updateTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        if(type === 2) {
            setReview(data,(res)=>{
                console.log(res)
                setSpinning(false)
                navigate('/people/review?id='+res.data)
            })
        }else if(type === 1){
            if (staked < 100 ) {
                setSpinning(false)
                message.fail(t('NoIncluded'))
                return navigate("/user/wiki")
            }
            console.log("mainIdmainIdmainIdmainIdmainId",mainId)
            peopleContract.methods.projectBase(mainId).call().then((res)=>{
                console.log("resresresresres",res)
                const dataBase = web3.utils.fromWei(res["0"],'ether')
                const firstQmHash = res.firstQmHash
                console.log('dataBase === 0.',dataBase === '0.')
                console.log('qmHash === firstQmHash && searchParams.get("update")===\'1\'',qmHash === firstQmHash && searchParams.get("update")==='1')
                if ((dataBase === '0.') || (qmHash === firstQmHash && searchParams.get("update")==='1')) {
                    data.mainId = void 0
                    characterApi.submitCharacterForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")}).then(res=>{
                        const obj = res.data
                        console.log(obj.mId,obj.cId,obj.lang,obj.qmHash,walletAddress)
                        peopleContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        if(searchParams.get("draftId")) {
                                            userApi.delUserDraft(searchParams.get("draftId")).then(res=>{
                                                navigate(-1)
                                            })
                                        }
                                        setSpinning(false)
                                    }).catch(err=>{
                                        setSpinning(false)
                                    })
                                }).catch(err=>{
                                    setSpinning(false)
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                                setSpinning(false)
                            }
                        }).catch(err=>{
                            message.fail(t('DenialAuthorization'))
                            characterApi.delCharacter(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }else {
                    if(createBy == userInfo.userId && searchParams.get("update") !== '1') {
                        setSpinning(false)
                        return message.fail(t("createUpdateFail"))
                    }
                    characterApi.updateCharacterForm({...data,oldLang,oldId: id}).then(res=>{
                        const obj = res.data
                        console.log(obj.mId,obj.cId,obj.lang,obj.qmHash,walletAddress)
                        peopleContract.methods.updateWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        setSpinning(false)
                                    }).catch(err=>{
                                        setSpinning(false)
                                    })
                                }).catch(err=>{
                                    setSpinning(false)
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                                setSpinning(false)
                            }
                        }).catch(err=>{
                            message.fail(t('DenialAuthorization'))
                            characterApi.delCharacter(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }
            })
        }else {
            characterApi.updateCharacterDraft({...data,id: thisId}).then(res=>{
                message.success(t('saveSuccess'))
                setSpinning(false)
            }).catch(err=>{
                characterApi.submitCharacterForm(data).then(res=>{
                    message.success(t('saveSuccess'))
                    setSpinning(false)
                })
            })
        }
    })

    const getProject = useSyncCallback((name = void 0)=>{
        console.log(name)
        projectsApi.queryProjectsList({
            pageNum: projectPage,
            pageSize: 10,
            lang: 0,
            sort: 0,
            type: 0,
            location: void 0,
            label: void 0,
            coinIssuanceSatus: void 0,
            financingStatus: void 0,
            name
        }).then(res=>{
            console.log(res)
            setProjectList([...res.rows])
            setProjectOption(()=>{
                return res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
        })
    })

    const getInvestorList = useSyncCallback(()=>{
        investorApi.queryInvestorList({
            pageNum: iPage,
            pageSize: 20,
            lang: lang === 'zh'?1:0,
            type: 0,
            sort: 0,
            label:void 0,
            location: void 0,
            establishTime: void 0,
            name: void 0,
            timeFrame: void 0
        }).then(res=>{
            console.log(res)
            setInvesList(res.rows)
            setIList(e=>{
                let list = res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
                return [...list]
            })
        })
    })

    const changeInvestmentData = useSyncCallback((id,index)=>{
        console.log(id,index)
        projectsApi.queryProjectsDetail({
            id: id
        }).then(res=>{
            console.log(res)
            const {data: item} = res
            setInvestmentDatailList(e=> {
                let list = [...e]
                list[index].projectName = item.name
                list[index].coinIssuanceSatus = item.coinIssuanceSatus
                list[index].label = item.label
                list[index].synopsis = item.introduction
                list[index].projectId = item.id
                list[index].logo = item.logoUrl
                return [...list]
            })
        })
    })

    const getDetail = useSyncCallback(()=>{
        characterApi.queryCharacterDetail({id}).then((res)=>{
            console.log(res)
            setHeadImgUrl(res.data.headImgUrl)
            setName(res.data.name)
            setContent(res.data.content)
            setMainId(res.data.mainId)
            setLanguage(res.data.lang)
            setThisId(res.data.id)
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            setQmHash(res.data.qmHash)
            setMediaList(()=>{
                return res.data.smcList.map(item=>{
                    return {
                        webType: item.webType,
                        webUrl: item.webUrl
                    }
                })
            })
            if(res.data.educationExp) {
                res.data.educationExp.split(',').forEach(item=>{
                    commonApi.queryUniversityInfo({id: item}).then(res=>{
                        setCheckSchoolValue(e=>{
                            let list = {
                                name: res.data.name,
                                url: res.data.url,
                                id: res.data.id
                            }
                            return [...e,list]
                        })
                    })
                })
                setSchoolValue(res.data.educationExp.split(','))
            }
            setInvestmentDatailList(()=>{
                return res.data.investDetailsList.map(item=>{
                    return {
                        inclusionId: item.inclusionId,
                        projectName: item.projectName,
                        synopsis:item.synopsis,
                        projectId: item.projectId,
                        logo: item.logo,
                        coinIssuanceSatus: item.coinIssuanceSatus,
                        label:item.label,
                        round: item.round,
                        amount: item.amount,
                        valuation: item.valuation,
                        time: dayjs(item.time),
                        led:item.led
                    }
                })
            })
            form.setFieldsValue({
                CharacterName: res.data.name,
                BasicIntroduction: res.data.content
            })
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        characterApi.queryCharacterDetailFromDB({id}).then((res)=>{
            console.log(res)
            setHeadImgUrl(res.data.headImgUrl)
            setName(res.data.name)
            setContent(res.data.content)
            setMainId(res.data.mainId)
            setLanguage(res.data.lang)
            setThisId(res.data.id)
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            setQmHash(res.data.qmHash)
            setMediaList(()=>{
                return res.data.smcList.map(item=>{
                    return {
                        webType: item.webType,
                        webUrl: item.webUrl
                    }
                })
            })
            // setCheckSchoolValue(educationExpJSON)
            if (res.data.educationExp) {

                res.data.educationExp.split(',').forEach(item=>{
                    commonApi.queryUniversityInfo({id: item}).then(res=>{
                        setCheckSchoolValue(e=>{
                            let list = {
                                name: res.data.name,
                                url: res.data.url,
                                id: res.data.id
                            }
                            return [...e,list]
                        })
                    })
                })

                setSchoolValue(res.data.educationExp.split(','))
            }
            setInvestmentDatailList(()=>{
                return res.data.investDetailsList.map(item=>{
                    return {
                        inclusionId: item.inclusionId,
                        projectName: item.projectName,
                        synopsis:item.synopsis,
                        projectId: item.projectId,
                        logo: item.logo,
                        coinIssuanceSatus: item.coinIssuanceSatus,
                        label:item.label,
                        round: item.round,
                        amount: item.amount,
                        valuation: item.valuation,
                        time: dayjs(item.time),
                        led:item.led
                    }
                })
            })
            form.setFieldsValue({
                CharacterName: res.data.name,
                BasicIntroduction: res.data.content
            })
        })
    })

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                getUniversity()
                getInvestorList()
                getProject()
                if (searchParams.get("type")){
                    getAuditDetail()
                }else {
                    getDetail()
                }
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                })
            })
        }
    },[])
    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title'}>{t('CharacterTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <Language lang={language} onChange={e => setLanguage(e)}></Language>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form}>
                        <Form.Item name="CharacterAvatar"
                                   label={labelNode(t('CharacterAvatar'), t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={e => {
                                setHeadImgUrl(e.data)
                            }}>
                                {
                                    !headImgUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    headImgUrl && (
                                        <img src={baseUrl + headImgUrl} className={'included_upload_logo'}/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <Form.Item style={inlineFormStyle} name={'CharacterName'} label={labelNode(t('CharacterName'))}
                                   rules={[{required: true}]}>
                            <Input value={name} onChange={e => setName(e.target.value)}
                                   className={"included_form_input"} placeholder={t("CharacterNamePlaceholder")}/>
                        </Form.Item>
                        <Form.Item name={'BasicIntroduction'} label={labelNode(t('BasicIntroduction'))}
                                   rules={[{required: true}]}>
                            <Input.TextArea value={content} onChange={e => setContent(e.target.value)}
                                            className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <Form.Item name={'SocialMediaLinks'} label={labelNode(t('SocialMediaLinks'))}
                                   rules={[{required: true}]}>
                            <div>
                                {
                                    mediaList.map((item, index) => {
                                        return (
                                            <div key={index} className={'included_media_item'}
                                                 style={index !== 0 ? {marginTop: 12} : {}}>
                                                <Select value={item.webType} onChange={e => {
                                                    setMediaList(item => {
                                                        const list = [...item]
                                                        list[index].webType = e
                                                        return list
                                                    })
                                                }} defaultValue={item.webType} options={media}
                                                        style={{width: 177, height: 50}}/>
                                                <Input value={item.webUrl} onChange={e => {
                                                    setMediaList(item => {
                                                        const list = [...item]
                                                        list[index].webUrl = e.target.value.replace(/\s/g, "")
                                                        return list
                                                    })
                                                }} className={"included_form_input included_media_link_input"}/>
                                                <div className='included_form_event_del'
                                                     style={{lineHeight: '50px'}}
                                                     onClick={() => setMediaList(e => {
                                                         return e.filter((item, key) => index !== key)
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={'included_form_tag_add_button included_media_add'}
                                     onClick={addMediaLink}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    {t('ContinueToAdd')}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name={'EducationalExperience'} label={labelNode(t('EducationalExperience'))}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false}
                                    className={'included_form_add_input included_form_input'}
                                    options={schoolList} onSearch={getUniversity} value={schoolValue} onChange={e => {
                                    setSchoolValue(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addSchoolValue}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_form_team_list'} style={{gap: 20}}>
                                {checkSchoolValue.map(item => {
                                    return (
                                        <div className={'included_form_study_list_item'}>
                                            <img src={baseUrl + item.url}
                                                 style={{width: 60, height: 60, borderRadius: '50%'}}
                                                 alt='logo'/>
                                            <div>{item.name}</div>
                                            <img className={'included_tag_item_close'}
                                                 onClick={() => delSchoolValue(item.id)}
                                                 src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </Form.Item>
                    </Form>
                    <div className={'included_form_handler_button'}>
                        <div className={'included_form_handler_button_left'}>
                            <div onClick={() => submitForm(2)}
                                className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                            <div onClick={() => submitForm(0)}
                                 className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                        </div>
                        <div onClick={() => {
                            characterApi.queryCheckName(name).then(res=>{
                                console.log(res)
                                if(res.data === 0){
                                    submitForm(1)
                                }else {
                                    if(searchParams.get("type") === "draft") {
                                        setRemarkModel(true)
                                    }else {
                                        submitForm(1)
                                    }
                                }
                            })
                        }}
                             className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default Project;
