import './message_module.scss'
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import deleteImage from '@/assets/image/message/delete.png'
import deleteDarkImage from '@/assets/image/message/deleteDark.png'
import {useSelector} from "react-redux";
import notice from '@/assets/image/message/notice_list.png'
import audit from '@/assets/image/message/audit_list.png'
import collect from '@/assets/image/message/collect_list.png'
import like from '@/assets/image/message/like_list.png'
import {Badge} from 'antd'
import Pagination from '@/components/Pagination/index'
import PageFooter from "../../components/PageFooter";
import useSyncCallback from "../../utils/useSyncCallback";
import messageApi from "../../api/message";
import message from "../../utils/message";

function Message() {
    const {t} = useTranslation()

    const typeList = [
        {image: require('../../assets/image/message/all-check.png'),check: require('../../assets/image/message/all-check.png'),type: 'all',value: void 0},
        {image: require('../../assets/image/message/notice.png'),check: require('../../assets/image/message/notice-check.png'),type: 'notice',value: 1},
        {image: require('../../assets/image/message/audit.png'),check: require('../../assets/image/message/audit-check.png'),type: 'audit',value: 2},
        {image: require('../../assets/image/message/collect.png'),check: require('../../assets/image/message/collect-check.png'),type: 'collect',value: 3},
        {image: require('../../assets/image/message/like.png'),check: require('../../assets/image/message/like-check.png'),type: 'like',value: 4 }
    ]
    const [messageList,setMessageList] = useState([])
    const [total, setTotal] = useState(0)
    const [unReadTotal, setUnReadTotal] = useState(0)
    const [checkIndex,setCheckIndex] = useState(0)
    const [listType,setListType] = useState(0)
    const [page,setPage] = useState(1)
    const color = useSelector(state => state.color.value)
    const header = useRef(null)

    useEffect(() => {
        getList()
        messageApi.queryMessageList({
            status: 0,
            pageNum: page,
            pageSize: 6
        }).then(res=>{
            setUnReadTotal(res.total)
        })
    }, []);

    function changeListType(type) {
        setListType(type)
        if (type === 1) {
            setMessageList((e)=>{
                return e.filter(item=>item.status === '0')
            })
        }else {
            getList()
        }
    }

    function changePage(e) {
        console.log(e)
        setPage(e)
        getList()
    }

    const getList = useSyncCallback(()=>{
        messageApi.queryMessageList({
            type: typeList[checkIndex].value,
            pageNum: page,
            pageSize: 6
        }).then(res=>{
            console.log(res)
            setMessageList(()=>{
                return [...res.rows]
            })
            setTotal(res.total)
        })
        messageApi.queryMessageList({
            type: typeList[checkIndex].value,
            status: 0,
            pageNum: page,
            pageSize: 6
        }).then(res=>{
            setUnReadTotal(res.total)
        })
    })

    const toRead = useSyncCallback(()=>{
        messageApi.readMessage({type: typeList[checkIndex].value}).then(res=>{
            message.success(t("success"))
            header.current.getUnreadCount()
            getList()
        })
    })

    const toDel = useSyncCallback(()=>{
        messageApi.delMessage({type: typeList[checkIndex].value || 0}).then(res=>{
            message.success(t("success"))
            getList()
        })
    })

    return (
        <div className={'main_back'}>
            <PageHeader other={true} ref={header}></PageHeader>
            <div className={'message_main_block'}>
                <div className={'message_type_block'}>
                    {
                        typeList.map((item,index)=>{
                            return (
                                <div className={'message_icon_item'} onClick={()=> {
                                    if (checkIndex === index) return
                                    setCheckIndex(index)
                                    setPage(1)
                                    getList()
                                }}>
                                    <img src={checkIndex === index?item.check:item.image} alt={item.type}/>
                                    <div className={checkIndex === index?'message_icon_check':''}>{t(item.type)}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={'message_info_list'}>
                    <div className={'message_info_handle'}>
                        <div className={'message_info_handle_left'}>
                            <div onClick={()=>changeListType(0)} className={listType === 0? 'message_info_handle_left_check':''}>{t("all")}<span>({total})</span></div>
                            <div onClick={()=>changeListType(1)} className={listType === 1? 'message_info_handle_left_check':''}>{t("unread")}<span>({unReadTotal})</span></div>
                        </div>
                        <div className={'message_info_handle_right'}>
                            <div className={'message_handle_button'} onClick={toDel}>
                                <img className={'delete_image'} src={color === 'white' ? deleteImage : deleteDarkImage} alt='delete'/>
                                {t("deleteAll")}
                            </div>
                            <div className={'message_handle_button'} onClick={toRead}>
                                <img src={require("@/assets/image/message/mark_read.png")} alt='delete'/>
                                {t("allRead")}
                            </div>
                        </div>
                        <div className={'message_bottom_line'}></div>
                    </div>
                    {
                        messageList.map((item,index)=> {
                            return (
                                <div className={'message_item'}>
                                    <Badge dot={item.read == 0}>
                                        <img src={item.type == 1?notice:item.type == 2?audit:item.type == 3?collect:like} alt='logo'/>
                                    </Badge>
                                    <div className={'message_item_info'}>
                                        <div className={'message_item_title'}>{item.title}</div>
                                        <div className={'message_item_content'}>{item.content}</div>
                                        <div className={'message_item_time'}>{item.time}</div>
                                    </div>
                                    {index !== messageList.length-1 && (<div className={'message_bottom_line'}></div>)}
                                </div>
                            )
                        })
                    }
                </div>
                <Pagination total={total} size={6} page={page} onChange={changePage}></Pagination>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Message;
