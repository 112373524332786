import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function CreateInfo({createInfo,createBy,createTime}){
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    return (
        <div className={'project_detail_contributor'}>
            <div className={'project_detail_top'}>
                <span>{t('informationContributor')}</span>
                <Link to={`/project/history?create=${createBy}`}>
                                    <span>
                                        {t('HistoricalInclusion')}
                                        <img src={require('@/assets/image/project/history_arrow.png')} alt='arrow'/>
                                    </span>
                </Link>
            </div>
            <div className={'project_contributor_avatar'}>
                {createInfo?.avatar?(<img style={{borderRadius: '50%'}} src={baseUrl + createInfo?.avatar} alt='avatar'/>): (
                    <div style={{fontSize: 18}} className={'pageHeader_avatar_image'}>
                        <span>{createInfo?.nickName ? createInfo?.nickName[0] : ''}</span>
                    </div>)}
                <div style={{
                    width: 300,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: 10
                }}>{createInfo?.nickName}</div>
            </div>
            <div className={'project_contributor_msg'}>
                <div className={'project_contributor_msg_title'}>IPFS</div>
                <div className={'project_contributor_msg_key'}>{createInfo?.qmHash}</div>
            </div>
            <div className={'project_contributor_msg'}>
                <div className={'project_contributor_msg_title'}>TX Hash</div>
                <div className={'project_contributor_msg_key'}>{createInfo?.txHash}</div>
            </div>
            <div className={'project_contributor_msg'}>
                <div className={'project_contributor_msg_title'}>Create Time</div>
                <div className={'project_contributor_msg_key'}>{createTime}</div>
            </div>
        </div>
    )
}

export default CreateInfo;
