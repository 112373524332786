import "../../Included/included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Form, Input, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import includedApi from '@/api/included'
import message from "@/utils/message";
import useSyncCallback from "../../../utils/useSyncCallback";
import kolApi from "../../../api/kol";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {projectTypeObj} from "../../../enum/projectType";
import {kolContract, playerContract, web3} from "../../../utils/web3Utils";
import commonApi from "../../../api/common";
import userApi from "../../../api/user";
import {auditStatusObj} from "../../../enum/auditStatus";
import {setReview} from "../../../utils/middle";
import Language from "../../../components/Language";
import dayjs from "dayjs";
import Region from "../../../components/Region";
import Modal from "../../../components/Modal";
import investorApi from "../../../api/investor";

function Kol() {
    const {id} = useParams()
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const media = useSelector(state => state.media.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const userInfo = useSelector(state=>state.user.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [mediaList,setMediaList]=useState([{webType: media[0].value,webUrl: ''}])
    const [kolUrl, setKolUrl] = useState('')
    const [nickname,setNickname] = useState('')
    const [country,setCountry] = useState('')
    const [content,setContent] = useState('')
    const [mainId, setMainId] = useState('')
    const [detailLang,setDetailLang] = useState(0)
    const [spinning, setSpinning] = useState(false)
    const [staked, setStaked] = useState(0)
    const [lang, setLang] = useState(0)
    const [oldLang, setOldLang] = useState(0)
    const [createBy, setCreateBy] = useState('')
    const [createTime, setCreateTime] = useState('')
    const [remarkModel, setRemarkModel] = useState(false)
    const [qmHash, setQmHash] = useState('')
    useEffect(()=>{
        web3.eth.getAccounts().then((res)=>{
            if (!res[0]) {
                res = window.ethereum.request({method: 'eth_requestAccounts'})
            }
            playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                if (searchParams.get("type")){
                    getAuditKolDetail()
                }else {
                    getKolDetail()
                }
            })
        })

    },[])

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addMediaLink() {
        const data = {webType: media[0].value,webUrl: ''}
        const list = [...mediaList]
        list.push(data)
        setMediaList(list)
    }

    function submitForm(submitType) {
        console.log(1213435,submitType)
        setSpinning(true)
        const form = {
            kolUrl, nickname, country, content, submitType, lang, smcList: mediaList, remark: projectTypeObj.kol.type, mainId, createTime, createBy, updateTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        if (submitType === 0) {
            kolApi.updateKolDraft({...form,id}).then(res=>{
                setSpinning(false)
                message.success(t('saveSuccess'));
            }).catch(err=>{
                includedApi.submitKolForm(form).then(res=>{
                    setSpinning(false)
                    message.success(t('saveSuccess'));
                })
            })
        }else if (submitType === 1) {
            if (staked < 100 ) {
                setSpinning(false)
                message.fail(t('NoIncluded'))
                return navigate("/user/wiki")
            }
            kolContract.methods.projectBase(mainId).call().then(res=> {
                const dataBase = web3.utils.fromWei(res["0"], 'ether')
                const firstQmHash = res.firstQmHash
                if ((dataBase === '0.') || (qmHash === firstQmHash && searchParams.get("update")==='1')) {
                    form.mainId = void 0
                    includedApi.submitKolForm({...form,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")}).then(res=>{
                        const obj = res.data
                        kolContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        if(searchParams.get("draftId")) {
                                            userApi.delUserDraft(searchParams.get("draftId")).then(res=>{
                                                navigate(-1)
                                            })
                                        }
                                        setSpinning(false)
                                    }).catch(err=>{
                                        setSpinning(false)
                                    })
                                }).catch(err=>{
                                    setSpinning(false)
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                                setSpinning(false)
                            }
                        }).catch(err=>{
                            message.fail(t('DenialAuthorization'))
                            kolApi.delKol(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }else {
                    if(createBy == userInfo.userId && searchParams.get("update") !== '1') {
                        setSpinning(false)
                        return message.fail(t("createUpdateFail"))
                    }
                    kolApi.updateKolDetail({...form,oldLang, oldId: id}).then(res=>{
                        const obj = res.data
                        kolContract.methods.updateWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        setSpinning(false)
                                    }).catch(err=>{
                                        setSpinning(false)
                                    })
                                }).catch(err=>{
                                    setSpinning(false)
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                                setSpinning(false)
                            }
                        }).catch(err=>{
                            message.fail(t('DenialAuthorization'))
                            kolApi.delKol(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }
            })

        }else {
            setReview(form,(res)=>{
                navigate('/kol/review?id='+res.data)
                setSpinning(false)
            })
        }
    }

    const getKolDetail = useSyncCallback(()=> {
        kolApi.queryKolDetail({id}).then(res=>{
            setKolUrl(res.data.kolUrl)
            setNickname(res.data.nickname)
            setCountry(res.data.country)
            setContent(res.data.content)
            setQmHash(res.data.qmHash)
            setMainId(res.data.mainId)
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            setMediaList(()=>{
                let list = res.data.smcList.map(item=>{
                    return {
                        webType: item.webType,
                        webUrl: item.webUrl
                    }
                })
                return [...list]
            })
            setLang(res.data.lang)
            setOldLang(res.data.lang)
            form.setFieldsValue({
                nickname: res.data.nickname,
                country: res.data.country,
                Introduction: res.data.content
            })
        })
    })

    const getAuditKolDetail = useSyncCallback(()=> {
        kolApi.queryKolDetailFromDB({id}).then(res=>{
            setKolUrl(res.data.kolUrl)
            setNickname(res.data.nickname)
            setCountry(res.data.country)
            setContent(res.data.content)
            setMainId(res.data.mainId)
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            setQmHash(res.data.qmHash)
            setMediaList(()=>{
                let list = res.data.smcList.map(item=>{
                    return {
                        webType: item.webType,
                        webUrl: item.webUrl
                    }
                })
                return [...list]
            })
            setLang(res.data.lang)
            setOldLang(res.data.lang)
            form.setFieldsValue({
                nickname: res.data.nickname,
                country: res.data.country,
                Introduction: res.data.content
            })
        })
    })
    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title'}>{t('kolIncluedTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <Language lang={lang} onChange={e => setLang(e)}></Language>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form}>
                        <Form.Item name="logo" label={labelNode(t('KOLAvatar'), t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={(e) => {
                                setKolUrl(e.data)
                            }}>
                                {
                                    !kolUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    kolUrl && (
                                        <img src={baseUrl + kolUrl} className={'included_upload_logo'}/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'nickname'} label={labelNode(t('nickname'))}
                                       rules={[{required: true}]}>
                                <Input value={nickname} onChange={e => setNickname(e.target.value)}
                                       className={"included_form_input"} placeholder={t("nicknamePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'country'}
                                       label={labelNode(t('country'))} rules={[{required: true}]}>
                                <Region change={(e) => {
                                    setCountry(e)
                                    form.setFieldsValue({country: e})
                                }}></Region>
                            </Form.Item>
                        </div>
                        <Form.Item name={'Introduction'} label={labelNode(t('Introduction'))}
                                   rules={[{required: true}]}>
                            <Input.TextArea value={country} onChange={e => setContent(e.target.value)}
                                            className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <Form.Item name={'SocialMediaLinks'} label={labelNode(t('SocialMediaLinks'))}
                                   rules={[{required: true}]}>
                            <div>
                                {
                                    mediaList.map((item, index) => {
                                        return (
                                            <div className={'included_media_item'}
                                                 style={index !== 0 ? {marginTop: 12} : {}}>
                                                <Select onChange={e => {
                                                    setMediaList(item => {
                                                        const list = [...item]
                                                        list[index].webType = e
                                                        return list
                                                    })
                                                }} value={item.webType} options={media}
                                                        style={{width: 177, height: 50}}/>
                                                <Input value={item.webUrl} onChange={e => {
                                                    setMediaList(item => {
                                                        const list = [...item]
                                                        list[index].webUrl = e.target.value.replace(/\s/g, "")
                                                        return list
                                                    })
                                                }} className={"included_form_input included_media_link_input"}/>
                                                <div className='included_form_event_del'
                                                     style={{lineHeight: '50px'}}
                                                     onClick={() => setMediaList(e => {
                                                         const list = e.filter((item, key) => index !== key)
                                                         return [...list]
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={'included_form_tag_add_button included_media_add'}
                                     onClick={addMediaLink}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    {t('ContinueToAdd')}
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                    <div className={'included_form_handler_button'}>
                        <div className={'included_form_handler_button_left'}>
                            <div onClick={() => submitForm(2)}
                                 className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                            <div onClick={() => submitForm(0)}
                                 className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                        </div>
                        <div onClick={() => {
                            kolApi.queryCheckName(nickname).then(res=>{
                                console.log(res)
                                if(res.data === 0){
                                    submitForm(1)
                                }else {
                                    if(searchParams.get("type") === "draft") {
                                        setRemarkModel(true)
                                    }else {
                                        submitForm(1)
                                    }
                                }
                            })
                        }}
                             className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin spinning={spinning} fullscreen size="large"/>
        </>
    )
}

export default Kol;
