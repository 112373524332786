import './kol_list_module.scss'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import Pagination from "@/components/Pagination";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import kolApi from '@/api/kol'
import useSyncCallback from "../../../utils/useSyncCallback";


function List() {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_BASE_URL
    const lang = useSelector(state => state.language.value)
    const color = useSelector(state => state.color.value)
    const [peopleListLocation,setPeopleListLocation] = useState(1)
    const [peopleArray,setPeopleArray] = useState([])
    const [width,setWidth] = useState(1920)
    const [hoverKey,setHoverKey] = useState(null)
    const [pageNum,setPageNum] = useState(1)
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const pageSize = 12
    const [tableData, setTableData] = useState({
        total: 0,
        data: []
    })
    console.log(width)
    useEffect(() => {
        setWidth(document.documentElement.clientWidth)
        getKolList()
        getRecommendList()
    }, []);

    const getKolList = useSyncCallback(()=> {
        const data = {
            pageNum,
            pageSize,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
            lang: lang.value
        }
        kolList(data).then(res=>{
            setTableData({
                total: res.total,
                data: res.rows
            })
        })
    })

    const getRecommendList = useSyncCallback(()=> {
        const data = {
            pageNum,
            pageSize,
            sort: 0,
            type: 0,
            lang: lang.value,
            recommend: 1
        }
        kolList(data).then(res=>{
            setPeopleArray(res.rows)
            if (res.rows.length === 1){
                setPeopleListLocation(-1)
            }else if(res.rows.length === 2){
                setPeopleListLocation(0)
            }else {
                setPeopleListLocation(1)
            }
        })
    })

    async function kolList(params) {
        return await kolApi.queryKolList(params)
    }

    function changePage(e) {
        console.log(e)
        setPageNum(e)
        getKolList()
    }

    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getKolList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getKolList()
    })

    function clickLocaiton(flag) {
        if (flag) {
            setPeopleListLocation((e)=>{
                return e+1
            })
        }else {
            setPeopleListLocation((e)=>{
                return e-1
            })
        }
    }
    return (
        <div className='main_back'>
            <PageHeader other={true}></PageHeader>
            <div className='kol_list_block'>
                <div className='kol_list_title'>KOL</div>
                <div className='kol_list_text'>{t('MeetingText')}</div>
                <div className='kol_list_popular_figures_block'>
                    <span className='kol_list_popular_figures_title'>{t('RecommendedKOLs')}</span>
                    <div className='kol_list_popular_figures' style={{width: width}}>
                        <img onClick={() => clickLocaiton(true)} className='kol_list_arrow'
                             src={require('@/assets/image/people/people_arrow_l.png')} alt='arrow'/>
                        <img onClick={() => clickLocaiton(false)} className='kol_list_arrow'
                             src={require('@/assets/image/people/people_arrow_r.png')} alt='arrow'/>
                        <div className='kol_list_left_modal'></div>
                        <div className='kol_list_right_modal'></div>
                        {
                            peopleArray.map((item, index) => {
                                return (
                                    <Link to='/kol/detail/123456'>
                                        <div className={`kol_list_popular_figures_item ${hoverKey === index?'kol_list_popular_figures_item_hover':''}`}
                                             onMouseOver={()=>setHoverKey(index)}
                                             onMouseLeave={()=>setHoverKey(null)}
                                             style={{left: -312 * peopleListLocation}}>
                                            <div className='kol_list_popular_figures_image'>
                                                <img className='kol_list_popular_figures_kol_image' src={baseUrl + item.kolUrl} alt='kol'/>
                                                <img className='kol_list_popular_figures_kol_icon' src={require('@/assets/image/kol/kol_icon.png')} alt='icon'/>
                                            </div>
                                            <div className='kol_list_popular_figures_item_name'>{item.nickname}</div>
                                            <div className='kol_list_popular_figures_item_location'>{item.country}</div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='kol_list_popular_figures_block kol_list_character_block'
                     style={{paddingBottom: 45}}>
                    <span className='kol_list_popular_figures_title'
                          style={{marginLeft: 0}}>{t('KOLList')}</span>
                    <div className='kol_list_character_main'>
                        <div className='kol_list_character_sort'>
                            <div className='kol_list_character_sort_block'>
                                <div className={'project_list_left_menu_top_grid_item'}
                                     onClick={() => checkHottest(0)}>
                                    {requestType !== 0 && (
                                        <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                                    {(requestType === 0 && requestSort === 1) && (
                                        <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                                    {(requestType === 0 && requestSort === 0) && (
                                        <img src={require('@/assets/image/project/sort_icon_down.png')}
                                             alt='sort'/>)}
                                    {t('Hottest')}
                                </div>
                                <div className={'project_list_left_menu_top_grid_item'}
                                     onClick={() => checkRecently(1)}>
                                    {requestType !== 1 && (
                                        <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                                    {(requestType === 1 && requestSort === 1) && (
                                        <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                                    {(requestType === 1 && requestSort === 0) && (
                                        <img src={require('@/assets/image/project/sort_icon_down.png')}
                                             alt='sort'/>)}
                                    {t('Recently')}
                                </div>
                            </div>
                        </div>
                        <div className='kol_list_character_list'>
                            {
                                tableData?.data.map((item) => {
                                    return (
                                        <Link to={`/kol/detail/${item.id}`}>
                                            <div className='kol_list_popular_figures_item'>
                                                <div className='kol_list_popular_figures_info'>
                                                    <img className='kol_list_popular_figures_info_image'
                                                         src={baseUrl + item.kolUrl} alt='logo'/>
                                                    <div className='kol_list_popular_info_right'>
                                                        <div className='kol_list_popular_info_right_top'>
                                                            <div>{item.nickname}</div>
                                                            <img className='kol_list_popular_figures_kol_icon'
                                                                 src={require('@/assets/image/kol/kol_icon.png')}
                                                                 alt='icon'/>
                                                        </div>
                                                        <div
                                                            className='kol_list_popular_info_location'>{item.country}</div>
                                                    </div>
                                                </div>
                                                <div className='kol_list_popular_info_text text-overflow-line-3-style'>{item.content}</div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <div className='kol_page_block'>
                            <Pagination total={tableData?.total} size={pageSize} page={pageNum}
                                        onChange={changePage}></Pagination>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default List;
