import { Enum } from '@/utils/enum'

export const auditStatusObj = Enum({
    unaudited: {type: "0",label: 'Unaudited'},
    approved: {type: "1",label: 'Approved'},
    reviewFailed: {type: "2",label: 'ReviewFailed'},
    appealing: {type: "3",label: 'Appealing'},
    appealingSuccess: {type: "4",label: 'appealingSuccess'},
    appealingFail: {type: "5",label: 'appealingFail'},
    voting: {type: "6",label: 'Voting'},
    votingSuccess: {type: "7",label: 'votingSuccess'},
    votingFail: {type: "8",label: 'votingFail'},
    update: {type: "9",label: 'update'},
})

export const auditStatusIndex = Enum({
    "0": "Unaudited",
    "1": "Approved",
    "2": "ReviewFailed",
    "3": "Appealing",
    "4": "appealingSuccess",
    "5": "appealingFail",
    "6": "Voting",
    "7": "votingSuccess",
    "8": "votingFail",
    "9": "update",
})
