import request from '../utils/request'
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(tz);

class Api {
    queryUserInfo() {
        return request.get('/system/user/getInfo')
    }

    updateUserInfo(data) {
        return request.post('/system/user/edit',data)
    }

    updatePassword(data) {
        return request.get('/system/user/updatePwd',data)
    }

    getDraftList(data) {
        return request.get('/draftBox/list',data)
    }

    queryInvitationLink() {
        return request.get('/invitationLink/getLink')
    }

    queryLikeCount() {
        return request.get('/myLikes/likeCount')
    }

    queryReviewList(data) {
        return request.get('/inclusionExamine/list',data)
    }

    submitAuditExamine(data) {
        return request.post('/inclusionExamine/examine',data)
    }

    queryUserInclusion(data) {
        return request.get('/inclusionExamine/myList',data)
    }

    queryUserCollection(data) {
        return request.get('/myCollection/collectionList',data)
    }

    queryUserLike(data) {
        return request.get('/myLikes/myLikesList',data)
    }

    updateAuditStatus(data) {
        const obj = {
            ...data,
            remark: dayjs.tz.guess()
        }
        return request.post('/inclusionExamine/updateStatusByProject',obj)
    }

    delUserDraft(id) {
        return request.del(`/draftBox/${id}`)
    }

    async sendGoogleCode() {
        const res = await request.get('/personcenter/getSecret')
        console.log("res-----secret",res)
        return {
            data: await request.get('/personcenter/getCode',{secretKey: res.msg}),
            key: res.msg
        }
    }

    verifyGoogleCode(data) {
        return request.get("/personcenter/checkCode",data)
    }

    updateClaimStatus(data) {
        return request.get("/inclusionExamine/updateClaimStatus",data)
    }

    queryInviteFriendList(data) {
        return request.get("/invitationFriend/list",data)
    }
}

export default new Api();
