import './people_university_module.scss'
import PageHeader from "../../../components/PageHeader";
import PageFooter from "../../../components/PageFooter";
import Pagination from "../../../components/Pagination";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import commonApi from "../../../api/common";
import {Link, useParams} from "react-router-dom";
import useSyncCallback from "../../../utils/useSyncCallback";
import characterApi from "../../../api/character";

function University() {
    const {id} = useParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const lang = useSelector(state => state.language.value)
    const [pageNum, setPageNum] = useState(1)
    const [universityInfo, setUniversityInfo] = useState({})
    const [tableData,setTableData] = useState({list:[],total:0})
    function changePage(e) {
        console.log(e)

    }

    const getList = useSyncCallback(()=>{
        characterApi.queryCharacterList({
            pageNum,
            pageSize: 24,
            lang: lang.value,
            type: 0,
            sort: 0,
            school: id,
        }).then(res=>{
            setTableData(e=>{
                let obj = {...e}
                obj.list = res.rows
                obj.total = res.total
                return {...obj}
            })
        })
    })

    useEffect(() => {
        commonApi.queryUniversityInfo({id}).then(res=>{
            setUniversityInfo(res.data)
        })
        getList()
    }, []);
    return (
        <div className={'main_back'}>
            <PageHeader other={true}></PageHeader>
            <div className={'people_university_banner'}>
                <img className={'people_university_banner_img'} src={require('../../../assets/image/people/banner.png')} alt='banner'/>
                <div className={'people_university_info'}>
                    <img className={'people_university_logo'} src={baseUrl+universityInfo.url} alt='university'/>
                    <div className={'people_university_name'}>{universityInfo.name}</div>
                </div>
            </div>
            <div className={'people_list_character_list'}>
                {
                    tableData.list.map((item)=>{
                        return (
                            <Link to={`/people/detail/${item.id}`}>
                                <div className={'people_list_character_list_item'}>
                                    <img className={'people_list_popular_figures_item_img'}
                                         src={baseUrl + item.headImgUrl}
                                         alt='logo'/>
                                    <div className={'people_list_popular_figures_item_name'}>{item.name}</div>
                                    <div className={'people_list_popular_figures_item_video_img'}>
                                        <img
                                            src={color === 'white' ? require('../../../assets/image/people/twitter.png') : require('../../../assets/image/people/twitter_dark.png')}/>
                                        <img
                                            src={color === 'white' ? require('../../../assets/image/people/ins.png') : require('../../../assets/image/people/ins_dark.png')}/>
                                    </div>
                                    <div className={'people_list_popular_figures_item_tag'}>{item.workExperienceList[item.workExperienceList.length-1]?.remark}</div>
                                    <div className={'people_list_popular_figures_item_tag2'}>{item.workExperienceList[item.workExperienceList.length-1]?.post}</div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            <div className={'people_list_page_block'}>
                <Pagination total={tableData.total} size={24} page={pageNum} onChange={changePage}></Pagination>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default University;
