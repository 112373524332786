import '../user_module.scss'
import {Link} from "react-router-dom";
import dayjs from 'dayjs'

function Investor(props) {
    const list = props.list || []
    const baseUrl = process.env.REACT_APP_BASE_URL
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3_small'}>
                {
                    list.map((item,index)=>{
                        return (
                            <Link to={`/investors/detail/${item.id}`}>
                                <div className={'investors_table_item'}>
                                    <div className={'investors_table_item_top'}>
                                        <img src={baseUrl+item.logoUrl} alt='logo'/>
                                        <div className={'investors_table_item_text'}>
                                            <div className={'investors_table_item_title'}>{item.name}</div>
                                            <div style={{width: 200}} className={'investors_table_item_content text-overflow-style'}>{item.content}</div>
                                        </div>
                                    </div>
                                    <div className={'investors_table_item_bottom'}>
                                        <div>
                                            <span>Founded</span>
                                            <span className={'investors_table_item_bottom_data'}>{dayjs(item.establishTime).format('YYYY')}</span>
                                        </div>
                                        <div>
                                            <span>Portfolio</span>
                                            <span className={'investors_table_item_bottom_data'}>{item?.investDetailsList?.length || 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Investor;
