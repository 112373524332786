import './radio_components.scss'
import {forwardRef, useImperativeHandle, useState} from "react";

const Radio = forwardRef((props,ref)=> {
    const [check,setCheck] = useState(undefined)
    function checkItem(value) {
        if (check === value) return
        props.onChange(value)
        setCheck(value)
    }

    useImperativeHandle(ref, () => ({
        checkData: (e) => {
            setCheck(e)
        }
    }));

    return (
        <div className={'radio_list'}>
            {
                props.list.map((item)=>{
                    return (
                        <div className={'radio_item'} onClick={()=>checkItem(item.value)}>
                            {
                                (check === item.value || props.data === item.value) &&
                                (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                            }
                            {
                                (check !== item.value && props.data !== item.value) &&
                                (<div className={'radio_no_check'}></div>)
                            }
                            <span className={'radio_label'}>{item.label}</span>
                            {item.text && (<span className={'radio_text'}>({item.text})</span>)}
                        </div>
                    )
                })
            }
        </div>
    )
})

export default Radio;
