import './pageHeader_components.scss'
import logoSmall from '@/assets/image/home/logo-small.png'
import { useSelector,useDispatch } from 'react-redux'
import homeDarkLogo from '@/assets/image/home/home-dark-logo.png'
import homeWhiteLogo from '@/assets/image/home/home-white-logo.png'
import whiteNight from '@/assets/image/home/white-night.png'
import whiteSun from '@/assets/image/home/white-sun.png'
import darkNight from '@/assets/image/home/dark-night.png'
import darkSun from '@/assets/image/home/dark-sun.png'
import darkDowmMore from '@/assets/image/home/dark-down-more.png'
import whiteDownMore from '@/assets/image/home/white-down-more.png'
import messageWhite from '@/assets/image/home/message_white.png'
import messageDark from '@/assets/image/home/message_dark.png'
import { useTranslation } from 'react-i18next';
import {Input, Button, Dropdown, Badge, Popover} from 'antd';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {isDark,isWhite} from "@/features/color/colorSlice.js";
import {chinese,english} from "@/features/language/langSlice.js";
import messageApi from '@/api/message'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import tz from "dayjs/plugin/timezone"
import {Logout} from "../../features/auth/authSlice";
import {web3} from "../../utils/web3Utils";
import useSyncCallback from "../../utils/useSyncCallback";
dayjs.extend(utc);
dayjs.extend(tz);

const PageHeader = forwardRef((props,ref)=>{
    const location = useLocation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const swiper = [
        'Congratulations on the new launch of binest website',
        'Congratulations on the new launch of binest website',
        'Congratulations on the new launch of binest website',
        'Congratulations on the new launch of binest website',
        'Congratulations on the new launch of binest website'
    ]
    const navigate = useNavigate()
    const color = useSelector(state => state.color.value)
    const token = useSelector(state => state.auth.value)
    const userInfo = useSelector(state => state.user.value)
    const walletAddress = useSelector(state=>state.walletAddress.value)
    const {t,i18n} = useTranslation()
    const [naviBack,setNaviBack] = useState('pageHeader_navi_main_color')
    const [searchValue,setSearchValue] = useState('')
    const [messageShow,setMessageShow] = useState(true)
    const dispatch = useDispatch()
    const langObj = useSelector(state => state.language.value)
    const languageList = [{key: '1', label: (<div>En</div>)}, {key: '2', label: (<div>中文</div>)}]

    const title = (
        <div className='pageHeader_title_address'>
            <div className={'pageHeader_title_address_text'}>{t('WalletAddress')}</div>
            <div>{walletAddress}</div>
        </div>
    )

    const content = (
        <div className={'pageHeader_title_body'}>
            <div className={'pageHeader_title_timeZone'}>
                <div className={'pageHeader_title_left'}>{t('timezone')}</div>
                <div className={'pageHeader_title_right'}>{dayjs.tz.guess()}</div>
            </div>
            <div onClick={toLogout} className={'pageHeader_logout'}>{t('Logout')}</div>
        </div>
    );

    function toLogout() {
        dispatch(Logout())
        navigate('/index',{replace: true})
        if (web3.currentProvider && web3.currentProvider.disconnect) {
            web3.currentProvider.disconnect(() => {
                console.log('Wallet connection terminated');
            });
        }else {
            web3.setProvider(null);
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('lang')) {
            i18n.changeLanguage(sessionStorage.getItem('lang'))
        }
        if (props.other) {
            setNaviBack('pageHeader_navi_other_color')
        }else {
            setNaviBack('pageHeader_navi_main_color')
        }
        if(token) {
            getUnreadCount()
        }
    }, []);

    function changeSearchInput(event){
        setSearchValue(event.target.value)
    }

    function enterToSearch() {
        if (!searchValue) return
        navigate(`/search?search=${searchValue}`)
    }

    function changeMode() {
        console.log(color)
        if (color === 'white'){
            dispatch(isDark())
        }else {
            dispatch(isWhite())
        }
    }

    function changeLang(e) {
        if (e.key === '2') {
            dispatch(chinese())
            i18n.changeLanguage('zh')
        }else {
            dispatch(english())
            i18n.changeLanguage('en')
        }
    }

    function checkColor(path) {
        if (location.pathname.indexOf(path) !== -1){
            return {
                color: "#B034B7"
            }
        }
        return {}
    }

    const getUnreadCount = useSyncCallback(()=>{
        messageApi.queryMessageList({
            status: 0
        }).then(res=>{
            console.log(res)
            if (res.total > 0){
                setMessageShow(true)
            }else {
                setMessageShow(false)
            }
        })
    })

    useImperativeHandle(ref, () => ({
        getUnreadCount
    }));


    return (
        <>
            <div style={{height: '126px'}}></div>
            <div className={'pageHeader_fixed'}>
                <div className={'pageHeader_block'}>
                    <div className={'pageHeader_block_inline'}>
                        {swiper.map((item,index)=>{
                            return (<div className={'pageHeader_text'} key={index}><img src={logoSmall} alt='logo'/><div>{item}</div></div>)
                        })}
                    </div>
                </div>
                <div className={`pageHeader_navi ${naviBack}`}>
                    <div className={'pageHeader_navi_block'}>
                        <div className={'pageHeader_navi_left'}>
                            <Link to={'/index'}>
                                <img src={color === 'white' ? homeWhiteLogo : homeDarkLogo} className={'pageHeader_navi_logo'} alt='logo'/>
                            </Link>
                            <Link to={'/project/list'}>
                                <div style={checkColor('project')} className={'pageHeader_navi_text'}>{t('projects')}</div>
                            </Link>
                            <Link to={'/investors/list'}>
                                <div style={checkColor('investor')} className={'pageHeader_navi_text'}>{t('investors')}</div>
                            </Link>
                            <Link to={'/people/list'}>
                                <div style={checkColor('people')} className={'pageHeader_navi_text'}>{t('people')}</div>
                            </Link>
                            <Link to={'/meeting/list'}>
                                <div style={checkColor('meeting')} className={'pageHeader_navi_text'}>{t('meeting')}</div>
                            </Link>
                            <Link to={'/kol/list'}>
                                <div style={checkColor('kol')} className={'pageHeader_navi_text'}>{t('kol')}</div>
                            </Link>
                            <Link to={'/more/tool'}>
                                <div style={checkColor('more')} className={'pageHeader_navi_text'}>{t('more')}</div>
                            </Link>
                        </div>
                        <div className={'pageHeader_navi_right'}>
                            <Input id="pageHeader_navi_input" value={searchValue} placeholder={t("search_placeholder")}
                                   onChange={changeSearchInput} onPressEnter={enterToSearch}/>
                            <Badge dot={messageShow}>
                                <Link to={'/message'}><img src={color === 'white' ? messageWhite : messageDark}
                                                           className={'pageHeader_message_icon'} alt='message'/></Link>
                            </Badge>
                            <div className={'pageHeader_navi_lang'}>
                                <Dropdown menu={{
                                    items: languageList,
                                    onClick: changeLang
                                }}>
                                    <div className={'pageHeader_navi_lang_text'}>
                                        {langObj.text}
                                        <img src={color === 'white' ? whiteDownMore : darkDowmMore} alt='more'/>
                                    </div>
                                </Dropdown>
                            </div>
                            <div className={'pageHeader_navi_mode_switch'} onClick={changeMode}>
                                <img src={color === 'white' ? whiteSun : darkSun}
                                     className={color === 'white' ? 'pageHeader_sun_white' : 'pageHeader_sun_night'}
                                     alt='sun'/>
                                <img src={color === 'white' ? whiteNight : darkNight}
                                     className={color === 'white' ? 'pageHeader_night_white' : 'pageHeader_night_night'}
                                     alt='night'/>
                            </div>
                            {!token && <Link to={{pathname: '/login'}} className={'pageHeader_login'}>
                                <Button id="pageHeader_navi_button">{t('signIn')}</Button>
                            </Link>}
                            {token && <Popover title={title} content={content}><Link to={'/user/profile'} className={'pageHeader_avatar'}>
                                {userInfo.avatar && <img className={'pageHeader_avatar_image'} src={baseUrl + userInfo.avatar} alt='avatar'/>}
                                {!userInfo.avatar && <div className={'pageHeader_avatar_image'}>
                                    <span>{userInfo.nickName?userInfo.nickName[0]:''}</span>
                                </div>}
                            </Link></Popover>}
                        </div>
                    </div>
                    <div className={props.grayBorder === true ?'pageHeader_navi_other_downLine': 'pageHeader_navi_downLine'}></div>
                </div>
            </div>
        </>
    )
})

export default PageHeader
