import { Enum } from '@/utils/enum'

export const projectTypeObj = Enum({
    project: {type: "0",label: 'type_project'},
    investor: {type: "1",label: 'type_investor'},
    people: {type: "2",label: 'type_people'},
    meeting: {type: "3",label: 'type_meeting'},
    kol: {type: "4",label: 'type_kol'},
    other: {type: "5",label: 'type_other'},
    tool: {type: "6",label: 'type_tool'},
    hackathon: {type: "7",label: 'type_hackathon'},
})

export const projectTypeIndex = Enum({
    "0": "type_project",
    "1": "type_investor",
    "2": "type_people",
    "3": "type_meeting",
    "4": "type_kol",
    "5": "type_other",
    "6": "type_tool",
    "7": "type_hackathon",
})

export const checkDetailPage = (type,id) => {
    const pageObj = {
        "0": `/project/detail/${id}`,
        "1": `/investors/detail/${id}`,
        "2": `/people/detail/${id}`,
        "3": `/meeting/detail/${id}`,
        "4": `/kol/detail/${id}`,
        "6": `/more/tool/detail/${id}`,
        "7": `/more/hackathon/detail/${id}`
    }
    console.log(pageObj[type])
    return pageObj[type]
}
