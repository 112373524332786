import { createBrowserRouter } from "react-router-dom";

import Home from '../view/Home'
import Login from '../view/Login'
import Message from '../view/Message'
import Included_Project from '../view/Included/Project'
import Included_Kol from '../view/Included/KOL'
import Included_Investor from '../view/Included/Investor'
import Included_Character from '../view/Included/Character'
import Included_Meeting from '../view/Included/Meeting'
import Included_Other_Tool from '../view/Included/Other/Tool'
import Included_Other_Hackathon from '../view/Included/Other/Hackathon'
import Project_List from '../view/Project/List'
import Project_Detail from '../view/Project/Detail'
import Project_History from '../view/Project/History'
import Project_Update from '../view/Project/Update'
import Project_Review from '../view/Project/Review'
import Investor_Lists from "../view/Investors/List";
import Investor_Detail from "../view/Investors/Detail"
import Investor_Update from "../view/Investors/Update"
import Investor_Review from "../view/Investors/Review"
import People_List from '../view/People/List'
import People_Detail from '../view/People/Detail'
import People_University from '../view/People/University'
import People_Update from '../view/People/Update'
import People_Review from '../view/People/Review'
import Meeting_List from '../view/Meeting/List'
import Meeting_Detail from "../view/Meeting/Detail";
import Meeting_Review from "../view/Meeting/Review";
import Meeting_Update from "../view/Meeting/Update";
import Kol_List from '../view/KOL/List'
import Kol_Detail from '../view/KOL/Detail'
import Kol_Update from '../view/KOL/Update'
import Kol_Review from '../view/KOL/Review'
import More from '../view/More'
import More_Tool from '../view/More/Tool'
import More_Hackathon from '../view/More/Hackathon'
import More_SoOn from '../view/More/SoOn'
import More_Tool_Detail from '../view/More/Tool/detail'
import More_Tool_Review from '../view/More/Tool/review'
import More_Tool_Update from '../view/More/Tool/update'
import More_Hackathon_Detail from '../view/More/Hackathon/detail'
import More_Hackathon_Update from '../view/More/Hackathon/update'
import More_Hackathon_Review from '../view/More/Hackathon/review'
import User from '../view/User'
import User_Profile from '../view/User/Profile'
import User_Account from '../view/User/Account'
import User_Wiki from '../view/User/WiKi'
import User_Collection from '../view/User/Collection'
import User_Like from '../view/User/Like'
import User_Draft from '../view/User/Draft'
import User_Submissions from '../view/User/Submission'
import User_Password from '../view/User/Password'
import User_Review from '../view/User/Review'
import User_Contrast from '../view/User/Review/contrast'
import User_Friend from '../view/User/Friend'
import Search from "../view/Search";
import { Navigate } from 'react-router-dom';
import KeepAlive from "react-activation";

const routes =[
    {
        path: '/index',
        element: <Home></Home>
    },
    {
        path: '/login',
        element: <Login></Login>
    },
    {
        path: '/message',
        element: <Message></Message>
    },
    {
        path: '/included',
        children: [
            {
                path: "project",
                element: <KeepAlive id="Included_Project" name="Included_Project"><Included_Project></Included_Project></KeepAlive>
            },
            {
                path: "kol",
                element: <KeepAlive id="Included_Kol" name="Included_Kol"><Included_Kol></Included_Kol></KeepAlive>
            },
            {
                path: "investor",
                element: <KeepAlive id="Included_Investor" name="Included_Investor"><Included_Investor></Included_Investor></KeepAlive>
            },
            {
                path: "character",
                element: <KeepAlive id="Included_Character" name="Included_Character"><Included_Character></Included_Character></KeepAlive>
            },
            {
                path: "meeting",
                element: <KeepAlive id="Included_Meeting" name="Included_Meeting"><Included_Meeting></Included_Meeting></KeepAlive>
            },
            {
                path: "other",
                children: [
                    {
                        path: "tool",
                        element: <KeepAlive id="Included_Other_Tool" name="Included_Other_Tool"><Included_Other_Tool></Included_Other_Tool></KeepAlive>
                    },
                    {
                        path: "hackathon",
                        element: <KeepAlive id="Included_Other_Hackathon" name="Included_Other_Hackathon"><Included_Other_Hackathon></Included_Other_Hackathon></KeepAlive>
                    }
                ]
            }
        ]
    },
    {
      path: '/project',
      children: [
          {
              path: 'list',
              element: <Project_List></Project_List>
          },
          {
              path: 'detail/:id',
              element: <Project_Detail></Project_Detail>
          },
          {
              path: 'history',
              element: <Project_History></Project_History>
          },
          {
              path: 'update/:id',
              element: <KeepAlive id="Project_Update" name="Project_Update"><Project_Update></Project_Update></KeepAlive>
          },
          {
              path: 'review',
              element: <Project_Review></Project_Review>
          }
      ]
    },
    {
        path: '/investors',
        children: [
            {
                path: 'list',
                element: <Investor_Lists></Investor_Lists>
            },
            {
                path: 'detail/:id',
                element: <Investor_Detail></Investor_Detail>
            },
            {
                path: 'update/:id',
                element: <KeepAlive id="Investor_Update" name="Investor_Update"><Investor_Update></Investor_Update></KeepAlive>
            },
            {
                path: 'review',
                element: <Investor_Review></Investor_Review>
            }
        ]
    },
    {
        path: '/people',
        children: [
            {
                path: 'list',
                element: <People_List></People_List>
            },
            {
                path: 'detail/:id',
                exact: true,
                element: <People_Detail></People_Detail>
            },
            {
                path: 'university/:id',
                element: <People_University></People_University>
            },
            {
                path: 'update/:id',
                element: <KeepAlive id="People_Update" name="People_Update"><People_Update></People_Update></KeepAlive>
            },
            {
                path: 'review',
                element: <People_Review></People_Review>
            },
        ]
    },
    {
        path: "/meeting",
        children: [
            {
                path: 'list',
                element: <Meeting_List></Meeting_List>
            },
            {
                path: 'detail/:id',
                element: <Meeting_Detail></Meeting_Detail>
            },
            {
                path: 'update/:id',
                element: <KeepAlive id="Meeting_Update" name="Meeting_Update"><Meeting_Update></Meeting_Update></KeepAlive>
            },
            {
                path: 'review',
                element: <Meeting_Review></Meeting_Review>
            }
        ]
    },
    {
        path: "/kol",
        children: [
            {
                path: 'list',
                element: <Kol_List></Kol_List>
            },
            {
                path: 'detail/:id',
                element: <Kol_Detail></Kol_Detail>
            },
            {
                path: 'update/:id',
                element: <KeepAlive id="Kol_Update" name="Kol_Update"><Kol_Update></Kol_Update></KeepAlive>
            },
            {
                path: "review",
                element: <Kol_Review></Kol_Review>
            }
        ]
    },
    {
        path: '/more',
        element: <More></More>,
        children: [
            {
                path: 'tool',
                element: <More_Tool></More_Tool>
            },
            {
                path: 'hackathon',
                element: <More_Hackathon></More_Hackathon>
            },
            {
                path: 'soon',
                element: <More_SoOn></More_SoOn>
            }
        ]
    },
    {
        path: '/more/tool',
        children: [
            {
                path: 'detail/:id',
                element: <More_Tool_Detail></More_Tool_Detail>
            },
            {
                path: 'update/:id',
                element: <KeepAlive id="More_Tool_Update" name="More_Tool_Update"><More_Tool_Update></More_Tool_Update></KeepAlive>
            },
            {
                path: 'review',
                element: <More_Tool_Review></More_Tool_Review>
            }
        ]
    },
    {
        path: '/more/hackathon',
        children: [
            {
                path: 'detail/:id',
                element: <More_Hackathon_Detail></More_Hackathon_Detail>
            },
            {
                path: 'update/:id',
                element: <KeepAlive id="More_Hackathon_Update" name="More_Hackathon_Update"><More_Hackathon_Update></More_Hackathon_Update></KeepAlive>
            },
            {
                path: 'review',
                element: <More_Hackathon_Review></More_Hackathon_Review>
            }
        ]
    },
    {
        path: '/contrast/:oId/:nId',
        element: <User_Contrast></User_Contrast>
    },
    {
        path: '/user',
        element: <User></User>,
        children: [
            {
                path: 'profile',
                element: <User_Profile></User_Profile>
            },
            {
                path: 'account',
                element: <User_Account></User_Account>
            },
            {
                path: 'wiki',
                element: <User_Wiki></User_Wiki>
            },
            {
                path: 'collection',
                element: <User_Collection></User_Collection>
            },
            {
                path: 'like',
                element: <User_Like></User_Like>
            },
            {
                path: 'draft',
                element: <User_Draft></User_Draft>
            },
            {
                path: 'submissions',
                element: <User_Submissions></User_Submissions>
            },
            {
                path: 'password',
                element: <User_Password></User_Password>
            },
            {
                path: 'review',
                element: <KeepAlive id="User_Review" name="User_Review"><User_Review></User_Review></KeepAlive>
            },
            {
                path: 'friend',
                element: <User_Friend></User_Friend>
            }
        ]
    },
    {
      path: '/search',
      element: <Search></Search>
    },
    {
        path: '/',
        element: <Navigate to='/index' />
    }
]

export default routes
