import { message } from 'antd';


function success(msg) {
    message.open({
        type: 'success',
        content: (<span className={'content-msg'}>{msg}</span>),
        icon: (<img src={require("../assets/image/utils_notice/success.png")} className={'content-icon'} alt='success'/>),
        className: 'message-success'
    })
}

function fail(msg) {
    message.open({
        type: 'error',
        content: (<span className={'content-msg'}>{msg}</span>),
        icon: (<img src={require("../assets/image/utils_notice/fail.png")} className={'content-icon'} alt='success'/>),
        className: 'message-fail'
    })
}

export default {
    success,
    fail
}
