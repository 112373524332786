import request from '@/utils/request'

class api {
    queryMessageList(data) {
        return request.get('/webMessage/list',data)
    }

    readMessage(data) {
        return request.get("/webMessage/updateAll",data)
    }

    delMessage(data) {
        return request.get("/webMessage/delAll",data)
    }
}

export default new api;
