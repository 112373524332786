import '../../../Investors/Detail/investors_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip, Dropdown} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import project1 from '@/assets/image/project/project1.png'
import project2 from '@/assets/image/project/project2.png'
import investorApi from "@/api/investor";
import useSyncCallback from "@/utils/useSyncCallback";
import dayjs from 'dayjs'
import Comment from "@/components/Comment";
import DetailHandle from "@/components/DetailHandle";
import {projectTypeObj} from "@/enum/projectType";
import projectsApi from "@/api/projects";
import commonApi from "@/api/common";
import CreateInfo from "@/components/CreateInfo";
import {getMediaIcon} from "../../../../enum/mediaType";

function Detail({id,newDetail}) {
    const { t } = useTranslation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const keyEventList = [
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'},
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'},
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'},
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'}
    ]
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [listType,setListType] = useState(1)
    const [detailInfo, setDetailInfo] = useState({})
    const [overviewData,setOverviewData] = useState({})
    const [searchParams,setSearchParams] = useSearchParams()
    const [createInfo,setCreateInfo] = useState()

    const items = [
        {
            key: '1',
            label: (
                <span className={'Dropdown_tag_text'}>All</span>
            ),
        },{
            key: '2',
            label: (
                <span className={'Dropdown_tag_text'}>with token</span>
            ),
        },{
            key: '3',
            label: (
                <span className={'Dropdown_tag_text'}>No token</span>
            ),
        }
    ]

    useEffect(()=>{
        if (newDetail) {
            getAuditDetail()
        }else {
            getDetail()
        }
    },[])

    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        investorApi.queryInvestmentDetailFromDB({id}).then(res=>{
            setDetailInfo({...res.data})
            getOverview()
            getCreateUserInfo()
        })
    })

    const getAuditDetail = useSyncCallback(()=> {
        investorApi.queryInvestmentDetailFromDB({id}).then((res)=>{
            setDetailInfo({...res.data})
            getCreateUserInfo()
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    const getOverview = useSyncCallback(()=>{
        investorApi.queryInvestmentOverview({
            id,
            lang: detailInfo.lang
        }).then(res=>{
            console.log('123',res)
            setOverviewData(res.data)
        }).catch(err=>{
            setOverviewData({})
        })
    })

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'investors_detail_main_block'}>
                <RouterLine name={detailInfo.name}/>
                <div className={'investors_detail_main'}>
                    <div className={'investors_detail_main_left'}>
                        <div className={'investors_detail_left_info'}>
                            <div className={'investors_detail_left_info_content'}>
                                <img className={'investors_detail_info_image'}
                                     src={baseUrl+detailInfo.logoUrl} alt='project'/>
                                <div className={'investors_detail_info_text'}>
                                    <div className={'investors_detail_info_name'}>{detailInfo.name}</div>
                                    <div className={'investors_detail_info_context text-overflow-style'}>{detailInfo.content}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'investors_detail_info_link_list'}>
                            {
                                detailInfo.smcList?.map((item,index)=>{
                                    return (
                                        <div className={'investors_detail_info_link'} onClick={()=>{
                                            window.open(item.webUrl)
                                        }}>
                                            <img
                                                src={getMediaIcon(item.webType,color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={'investors_detail_text_info'}>
                            <div><span>{t('founded')}: </span>{dayjs(detailInfo.establishTime).format('YYYY')}</div>
                            <div style={{marginLeft: 75}}><span>{t('location')}: </span>{detailInfo.location}</div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'}>{t('details')}</div>
                            <div className={'investors_detail_details_content'}>{detailInfo.content}</div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'}>{t('TeamMembers')}</div>
                            <div className={'investors_form_team_list'}>
                                {
                                    detailInfo.workExperienceList?.map((item,index)=>{
                                        return (
                                            <div key={index} className={'investors_form_team_list_item'}>
                                                <img src={baseUrl+item.url} style={{width: 60,height: 60,borderRadius: '50%'}}
                                                     alt='logo'/>
                                                <div>
                                                    <div className={'investors_form_team_list_item_name'}>{item.name}</div>
                                                    <div>{item.post}</div>
                                                </div>
                                                <img className={'investors_form_team_list_item_media'}
                                                     src={require('@/assets/image/included/media_logo.png')}
                                                     alt='logo'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'}>{t('InvestmentOverview')}</div>
                            <div className={'investors_overview_data'}>
                                <div className={'investors_overview_data_1 investors_overview_data_border'}>
                                    <div className={'investors_overview_data_title'}>{t('RoundsInThePastYear')}</div>
                                    <div className={'investors_overview_data_number'}>{overviewData.yearCount || 0}</div>
                                </div>
                                <div className={'investors_overview_data_2 investors_overview_data_border'}>
                                    <div className={'investors_overview_data_title'}>{t('TotalRounds')}</div>
                                    <div className={'investors_overview_data_number'}>{overviewData.count || 0}</div>
                                </div>
                                <div className={'investors_overview_data_1 investors_overview_data_border'}>
                                    <div className={'investors_overview_data_title'}>{t('PortfolioNumbers')}</div>
                                    <div className={'investors_overview_data_number'}>{overviewData.composeCount || 0}</div>
                                </div>
                                <div className={'investors_overview_data_3'}>
                                    <div className={'investors_overview_data_title'}>{t('LeadInvestment')}</div>
                                    <div className={'investors_overview_data_number'}>{overviewData.historyCount || 0}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'} >{t('KeyEvents')}</div>
                            <TimeLine list={detailInfo.eventList}/>
                        </div>
                        <div className={'investors_detail_details investors_detail_details_investments'}>
                            <div className={'investors_detail_details_title investors_detail_details_top_handle'}>
                                {t('Investments')}
                                <div className={'investors_detail_handle_btn'}>
                                    <div onClick={()=>setListType(1)} className={listType === 1?'investors_detail_handle_portfolio':''}>{t('Portfolio2')}</div>
                                    <div onClick={()=>setListType(2)} className={listType === 2?'investors_detail_handle_portfolio':''}>{t('Rounds')}</div>
                                </div>
                            </div>
                            <div className={'investors_detail_tag_check'}>
                                <div>
                                    Tag
                                    <img src={require('@/assets/image/investors/nocheck_icon.png')} alt='noCheck'/>
                                </div>
                                <Dropdown menu={{items}} trigger={['click']}>
                                    <div>
                                        All
                                        <img src={require('@/assets/image/investors/check_icon.png')}
                                             alt='check'/>
                                    </div>
                                </Dropdown>
                            </div>
                            {
                                listType === 1 && (
                                    <div className={'investors_detail_investors_list'}>
                                        {
                                            detailInfo.investDetailsList?.map((item,index)=>{
                                                return (
                                                    <div className={'investors_table_item_block'}>
                                                        <div className={'investors_table_item_top'}>
                                                            <img src={baseUrl + item.logo} alt='logo'/>
                                                            <div className={'investors_table_item_text'}>
                                                                <div
                                                                    className={'investors_table_item_title'}>{item.projectName}</div>
                                                                <div
                                                                    className={'investors_table_item_content'}>{item.synopsis}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            {
                                listType === 2 && (
                                    <div style={{marginTop: 50}} className={'investors_overseas_table'}>
                                        <div className={'investors_form_bottom_table'}>
                                            <div className={'investors_form_bottom_table_head_line'}>
                                                <div className={'investors_form_bottom_table_head_sp'}>
                                                    <img src={require("@/assets/image/included/icon_name.png")}
                                                         alt='name'/>
                                                    {t('name')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("@/assets/image/included/icon_round.png")}
                                                         alt='round'/>
                                                    {t('Round')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("@/assets/image/included/icon_amount.png")}
                                                         alt='amount'/>
                                                    {t('amount')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("@/assets/image/included/icon_valuation.png")}
                                                         alt='valuation'/>
                                                    {t('valuation')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("@/assets/image/included/icon_time.png")}
                                                         alt='time'/>
                                                    {t('date')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("@/assets/image/included/icon_investor.png")}
                                                         alt='investor'/>
                                                    {t('leadInvestor')}
                                                </div>
                                            </div>
                                            {
                                                detailInfo.investDetailsList?.map((item, index) => {
                                                    return (
                                                        <div className={'investors_form_bottom_table_item_line'}
                                                             style={{marginBottom: 10}}>
                                                            <div className={'investors_form_bottom_table_item'}>
                                                                <img className={'investors_name_icon'}
                                                                     src={baseUrl+item.logo}
                                                                     alt='project1'/>
                                                                <span>{item.projectName}</span>
                                                                <img className={'investors_link_icon'}
                                                                     src={color === 'white' ? require('@/assets/image/project/link_icon.png') : require('@/assets/image/project/link_icon_dark.png')}
                                                                     alt='link'/>
                                                            </div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>{item.round}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>$ {item.amount}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>$ {item.valuation}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>{item.time}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>{item.led == '1'?'YES':item.led == '0'?'NO':'--'}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <Comment id={id}></Comment>
                    </div>
                    <div className={'investors_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'investors_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'investors_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            <div className={'investors_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'investors_contributor_investors_title'}>Newscrypto</div>
                                    <div className={'investors_contributor_investors_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'investors_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'investors_contributor_investors_title'}>Newscrypto</div>
                                    <div className={'investors_contributor_investors_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'investors_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'investors_contributor_investors_title'}>Newscrypto</div>
                                    <div className={'investors_contributor_investors_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'investors_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'investors_contributor_investors_title'}>Newscrypto</div>
                                    <div className={'investors_contributor_investors_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'investors_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'investors_contributor_investors_title'}>Newscrypto</div>
                                    <div className={'investors_contributor_investors_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'investors_comment_dialog'}>
                    <img onClick={()=>setIsModalOpen(false)} className={'investors_comment_dialog_close'} src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'investors_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'investors_textarea'} autoSize={{ minRows: 6, maxRows: 6 }}></Input.TextArea>
                    <div className={'investors_handle_btn'}>
                        <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'investors_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'investors_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'investors_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'investors_comment_dialog_title'}>{t('update')}</div>
                    <div className={'investors_detail_update_block'}>
                        <div className={'investors_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'investors_detail_update_btn_list'}>
                            <div>{t('updateContent')}</div>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'investors_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
