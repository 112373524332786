import request from '@/utils/request'

class Api {
    queryKolList(data) {
        return request.get('/kolInclusion/esList',data)
    }

    queryKolDetail(data) {
        return request.get('/kolInclusion/getEsInfo',data)
    }

    updateKolDetail(data) {
        return request.post('/kolInclusion/update',data)
    }

    delKol(id) {
        return request.del(`/kolInclusion/${id}`)
    }

    queryKolDetailFromDB(data) {
        return request.get('/kolInclusion/getInfo',data)
    }

    updateKolDraft(data) {
        return request.post('/kolInclusion/edit',data)
    }

    queryCheckName(name) {
        return request.get('/kolInclusion/checkName',{name})
    }
}

export default new Api();
