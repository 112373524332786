import '../user_module.scss'
import {Tag,Tooltip} from "antd"
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Modal from "@/components/Modal";
import {useEffect, useState} from "react";
import {Input, Spin} from "antd";
import Pagination from "@/components/Pagination";
import userApi from '@/api/user'
import useSyncCallback from "@/utils/useSyncCallback";
import {projectTypeIndex} from "@/enum/projectType";
import {auditStatusIndex} from "@/enum/auditStatus";
import message from "../../../utils/message";
import {auditStatusObj} from "../../../enum/auditStatus";
import {useNavigate} from "react-router-dom";
import {
    hackathonContract,
    investorContract,
    kolContract,
    meetingContract,
    peopleContract,
    projectContract, toolContract,
    web3
} from '../../../utils/web3Utils'
import dayjs from "dayjs";
import {check14Days} from "../../../utils/tools";

function Review() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const color = useSelector(state => state.color.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const userInfo = useSelector(state => state.user.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [pageNum,setPageNum] = useState(1)
    const pageSize = 8
    const [tableData,setTableData] = useState({total: 0,tableList: []})
    const [checkData, setCheckData] = useState({})
    const [examineReport, setExamineReport] = useState('')
    const [spinning,setSpinning] = useState(false)
    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                getReviewList()
            })
        }
    },[])

    function changePage(e) {
        setPageNum(e)
        getReviewList()
    }

    const getReviewList = useSyncCallback(()=>{
        userApi.queryReviewList({
            pageNum,
            pageSize
        }).then(res=>{
            const list = res.rows.map(item=>{
                item.typeLabel = projectTypeIndex[item.type]
                item.auditLabel = auditStatusIndex[item.status]
                return item
            })
            const data = {
                total: res.total,
                tableList: list
            }
            setTableData({...data})
        })
    })

    const reviewRejection = useSyncCallback((status)=>{
        console.log(checkData)
        if (status.toString() === "2") {
            userApi.submitAuditExamine({
                id: checkData.id,
                status,
                type: checkData.type,
                projectId: checkData.projectId,
                examineReport,
                name: checkData.name,
                mainId: checkData.mainId
            }).then(res=>{
                setExamineReport("")
                setCheckData({})
                getReviewList()
            })
        }
    })

    const submitAudit = useSyncCallback((status)=>{
        console.log('checkData',checkData)
        if (checkData.updateBy && checkData.updateBy == userInfo.userId) {
            setUpdateModalOpen(false)
            setIsModalOpen(false)
            return message.fail(t("updateReviewFail"))
        }else if(checkData.updateBy === null && checkData.createBy == userInfo.userId){
            setUpdateModalOpen(false)
            setIsModalOpen(false)
            return message.fail(t("createReviewFail"))
        }
        setSpinning(true)
        console.log("setExamineReport",examineReport)
        if (checkData.type === '0'){
            projectContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                console.log("resresresresresres",res)
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (checkData.type === '1') {
            investorContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                    getReviewList()
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (checkData.type === '2') {
            peopleContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                    getReviewList()
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (checkData.type === '3') {
            meetingContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                    getReviewList()
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (checkData.type === '4') {
            kolContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                    getReviewList()
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (checkData.type === '6') {
            toolContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                    getReviewList()
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (checkData.type === '7') {
            hackathonContract.methods.approveWiki(checkData.mainId,status,checkData.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('resultWait'))
                    reviewRejection(status)
                    getReviewList()
                }else {
                    message.fail(t('DenialAuthorization'))
                }

                setUpdateModalOpen(false)
                setIsModalOpen(false)
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }
    })

    const toDetail = useSyncCallback((type,id)=>{
        switch (type) {
            case "0":
                navigate(`/project/detail/${id}?type=audit`)
                break;
            case "1":
                navigate(`/investors/detail/${id}?type=audit`)
                break;
            case "2":
                navigate(`/people/detail/${id}?type=audit`)
                break;
            case "3":
                navigate(`/meeting/detail/${id}?type=audit`)
                break;
            case "4":
                navigate(`/kol/detail/${id}?type=audit`)
                break;
            case "6":
                navigate(`/more/tool/detail/${id}?type=audit`)
                break;
            case "7":
                navigate(`/more/hackathon/detail/${id}?type=audit`)
                break;
        }
    })

    const changeClaimStatus = useSyncCallback((qmHash)=>{
        userApi.updateClaimStatus({
            qmHash,
            claimStatus: 0
        }).then(()=>{
            getReviewList()
        })
    })

    const toGetClaim = useSyncCallback((item)=>{
        setSpinning(true)
        if (item.type === '0'){
            projectContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (item.type === '1') {
            investorContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (item.type === '2') {
            peopleContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (item.type === '3') {
            meetingContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (item.type === '4') {
            kolContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (item.type === '6') {
            toolContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }else if (item.type === '7') {
            hackathonContract.methods.claimWiki(item.mainId,item.qmHash).send({from: walletAddress}).then((res)=>{
                if (res.status === 1n) {
                    message.success(t('ClaimSuccess'))
                    changeClaimStatus(item.qmHash)
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
            }).catch((err)=>{
                message.fail(t('DenialAuthorization'))
                setSpinning(false)
            })
        }
    })

    return (
        <>
            <div className='profile_main_block'>
                <div className='profile_main_block_title'>Collection Review</div>
                <div className='wiki_table_block'>
                    <div className='audit_table_cell'>
                        <div>
                            <img src={require('@/assets/image/user/icon_name.png')}/>
                            {t('name')}
                        </div>
                        <div>
                            <img src={require('@/assets/image/user/icon_CollectionType.png')}/>
                            {t('CollectionType')}
                        </div>
                        <div>
                            <img src={require('@/assets/image/user/icon_LastChangeTime.png')}/>
                            {t('SubmissionTime')}
                        </div>
                        <div>
                            <img src={require('@/assets/image/user/icon_LastChangeTime.png')}/>
                            {t('ReviewStatus')}
                        </div>
                        <div>
                            <img src={require('@/assets/image/user/icon_LastChangeTime.png')}/>
                            {t('AuditTime')}
                        </div>
                        <div>
                            <img src={require('@/assets/image/user/icon_LastChangeTime.png')}/>
                            {t('AuditBy')}
                        </div>
                        <div>
                            <img src={require('@/assets/image/user/icon_Operate.png')}/>
                            {t('Operate')}
                        </div>
                    </div>
                    {
                        tableData.tableList.map(item => {
                            return (
                                <div className='audit_table_cell'>
                                    <div>
                                        <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                                 color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottom"
                                                 title={item.name}>
                                        <div className='audit_table_title'>{item.name}</div>
                                        </Tooltip>
                                        {
                                            (check14Days(item.createTime) && item.userId == userInfo.userId && item.claimStatus !== "0"  ) && (<>&nbsp;&nbsp;<Tag onClick={()=>toGetClaim(item)} style={{cursor:"pointer"}} color="success">{t("ClaimRewards")}</Tag></>)
                                        }
                                    </div>
                                    <div>{t(item.typeLabel)}</div>
                                    <div>{item.remark || '--'}</div>
                                    <div>
                                        {(item.status !== "1" && item.status !== "2") && (
                                            <span style={{color: 'var(--text-color-sec)'}}>{t(item.auditLabel)}</span>)}
                                        {item.status === "1" && (
                                            <span style={{color: '#23AB2C'}}>{t(item.auditLabel)}</span>)}
                                        {item.status === "2" && (
                                            <span style={{color: '#CE3939'}}>{t(item.auditLabel)}</span>)}
                                    </div>
                                    <div>{item.examineTime? dayjs(new Date(item.examineTime*1000)).format("YYYY-MM-DD HH:mm:ss"): '--'}</div>
                                    <div>{item.examineName}</div>
                                    {
                                        color === 'white' && (
                                            <div className='audit_operate_icon'>
                                                <img onClick={() => {
                                                    if (item.oldId) {
                                                        if (item.oldLang === item.lang) {
                                                            navigate(`/contrast/${item.oldId}/${item.projectId}?type=${item.type}`)
                                                        } else {
                                                            toDetail(item.type, item.projectId)
                                                        }
                                                    } else {
                                                        toDetail(item.type, item.projectId)
                                                    }
                                                }} src={require('@/assets/image/user/icon-review-black.png')} alt='edit'/>
                                                {item.status === "0" && (
                                                    <img onClick={() => {
                                                        setUpdateModalOpen(true)
                                                        setCheckData({...item})
                                                    }} src={require('@/assets/image/user/iconly_success.png')}
                                                         alt='edit'/>)}
                                                {item.status === "0" && (
                                                    <img onClick={() => {
                                                        setIsModalOpen(true)
                                                        setCheckData({...item})
                                                    }}
                                                         src={require('@/assets/image/user/iconly_fail.png')} alt='edit'/>)}
                                            </div>
                                        )
                                    }
                                    {
                                        color === 'dark' && (
                                            <div className='audit_operate_icon'>
                                                <img src={require('@/assets/image/user/icon-review-white.png')} alt='edit'/>
                                                {
                                                    item.status === "0" && (
                                                        <img onClick={() => {
                                                            setUpdateModalOpen(true)
                                                            setCheckData({...item})
                                                        }} src={require('@/assets/image/user/iconly_success_dark.png')}
                                                             alt='edit'/>
                                                    )
                                                }
                                                {
                                                    item.status === "0" && (
                                                        <img onClick={() => {
                                                            setIsModalOpen(true)
                                                            setCheckData({...item})
                                                        }} src={require('@/assets/image/user/iconly_fail_dark.png')}
                                                             alt='edit'/>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className='wiki_page_block'>
                    <Pagination total={tableData.total} size={pageSize} page={pageNum}
                                onChange={changePage}></Pagination>
                </div>
                <Modal open={isModalOpen}>
                    <div className='project_comment_dialog'>
                        <img onClick={() => setIsModalOpen(false)} className='project_comment_dialog_close'
                             src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                        <div className='project_comment_dialog_title'>{t('ReviewComments')}</div>
                        <Input.TextArea value={examineReport} onChange={e => setExamineReport(e.target.value)}
                                        placeholder={t('ReviewCommentsPlaceholder')} className='project_textarea'
                                        autoSize={{minRows: 6, maxRows: 6}}></Input.TextArea>
                        <div className='project_handle_btn'>
                            <div onClick={() => setIsModalOpen(false)}>{t('cancle')}</div>
                            <div onClick={() => submitAudit(auditStatusObj["reviewFailed"].type)}
                                 className='project_handle_btn_confirm'>{t('confirm')}</div>
                        </div>
                    </div>
                </Modal>
                <Modal open={updateModalOpen}>
                    <div className='project_comment_dialog'>
                        <img onClick={() => setUpdateModalOpen(false)} className={'project_comment_dialog_close'}
                             src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                        <div className='project_comment_dialog_title'>{t('Approved')}</div>
                        <div className='project_comment_dialog_text'>{t('ApprovedText')}</div>
                        <div className='project_handle_btn'>
                            <div onClick={() => setUpdateModalOpen(false)}>{t('cancle')}</div>
                            <div onClick={() => submitAudit(auditStatusObj["approved"].type)}
                                 className='project_handle_btn_confirm'>{t('confirm')}</div>
                        </div>
                    </div>
                </Modal>
            </div>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default Review;
