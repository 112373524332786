import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import PageFooter from "@/components/PageFooter";
import './investors_list_module.scss'
import {Collapse, Input} from "antd";
import {useEffect, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import Pagination from "@/components/Pagination";
import {useSelector} from "react-redux";
import {Link, useSearchParams} from 'react-router-dom'
import investorApi from "../../../api/investor";
import useSyncCallback from "../../../utils/useSyncCallback";
import dayjs from "dayjs";
import commonApi from "../../../api/common";
import {getTranslation, getTranslationBatch} from "../../../utils/middle";


function List() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const color = useSelector(state => state.color.value)
    const lang = useSelector(state=>state.language.value)
    const [tableData, setTableData] = useState({
        list: [],
        total: 0
    })
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const [tagObj,setTagObj] = useState({
        tag: false,
        coinStatus: false,
        financingStatus: false,
        Region: false
    })
    const [tagCheckList,setTagCheckList] = useState([])
    const [foundedCheckList,setFoundedCheckList] = useState([])
    const [timeCheckList,setTimeCheckList] = useState([])
    const [regionCheckList,setRegionCheckList] = useState([])
    const [pageNum, setPageNum] = useState(1)
    const [tagList, setTagList] = useState([])
    const [foundedList, setFoundedList] = useState([])
    const [timeList, setTimeList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [region, setRegion] = useState([])
    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const getTypeList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: lang.value == 0?'organization_type_en':'organization_type',
            dictLabel: text
        }).then(res=>{
            setTagList(()=>{
                return res.rows.map(item => {
                    return {
                        label: item.dictLabel,
                        value: item.dictLabel
                    }
                })
            })
        })
    })

    const getFoundedList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'establish_time',
            dictLabel: text
        }).then(res=>{
            setFoundedList(()=>{
                return res.rows.map(item => {
                    return {
                        label: item.dictLabel,
                        value: item.dictLabel
                    }
                })
            })
        })
    })

    const getTimeList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'time_frame',
            dictLabel: text
        }).then(res=>{
            setTimeList(()=>{
                return res.rows.map(item => {
                    return {
                        label: item.dictLabel,
                        value: item.dictValue
                    }
                })
            })
        })
    })

    const getRegionList = useSyncCallback((text)=>{
        if (text) {
            setRegionList(()=>{
                return region.filter(item=>{
                    return item.label.includes(text)
                })
            })
        }else {
            commonApi.queryDictData({
                dictType: 'area'
            }).then(res=>{
                if (lang.value === 0) {
                    const arrList = res.rows.map(item=>item.dictLabel)
                    getTranslationBatch(arrList.toString(),"zh","en",(item)=>{
                        console.log(res)
                        const listText = item.data.TargetTextList.map((result,index)=>{
                            return {
                                label: result,
                                value: result
                            }
                        })
                        setRegionList(listText)
                        setRegion(listText)
                    })
                }else {
                    setRegionList(()=>{
                        const finList = res.rows.map(item=>{
                            return {
                                label: item.dictLabel,
                                value: item.dictLabel
                            }
                        })
                        setRegion(finList)
                        return finList
                    })
                }
            })
        }
    })

    useEffect(()=>{
        getTypeList()
        getFoundedList()
        getTimeList()
        getRegionList()
        if (searchParams.get("location")) {
            setRegionCheckList((e)=>{
                const list = [...e]
                const key = list.indexOf(searchParams.get("location"))
                if (key !== -1) {
                    list.splice(key,1)
                    return list
                }
                list.push(searchParams.get("location"))
                return list
            })
        }
        if (searchParams.get("founded")) {
            setFoundedCheckList((e)=>{
                const list = [...e]
                const key = list.indexOf(searchParams.get("founded"))
                if (key !== -1) {
                    list.splice(key,1)
                    return list
                }
                list.push(searchParams.get("founded"))
                return list
            })
        }
    },[])

    useEffect(() => {
        getList()
    }, [tagCheckList,foundedCheckList,timeCheckList,regionCheckList]);

    const items = [
        {
            key: '1',
            label: (
                <div className={'investors_list_tag'}>
                    <span>{t('type')}</span>
                    <img
                        src={tagObj.tag?require('@/assets/image/project/jian.png'):require('@/assets/image/project/jia.png')}
                        alt='icon'/>
                </div>
            ),
            children: (
                <div className={'included_radio_check'}>
                    <Input prefix={<SearchOutlined />} onChange={e=>getTypeList(e.target.value)} className={"included_form_input investors_form_input"} placeholder={t('Search')}/>
                    {
                        tagList.map((item,index)=> {
                            return (
                                <div onClick={()=>{
                                    setTagCheckList((e)=>{
                                        const list = [...e]
                                        const key = list.indexOf(item.value)
                                        if (key !== -1) {
                                            list.splice(key,1)
                                            return list
                                        }
                                        list.push(item.value)
                                        return list
                                    })
                                }} className={'investors_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                    {
                                        tagCheckList.indexOf(item.value) !== -1 &&
                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                    }
                                    {
                                        tagCheckList.indexOf(item.value) === -1 &&
                                        (<div className={'radio_no_check'}></div>)
                                    }
                                    <span>{item.label}</span>
                                </div>
                            )
                        })
                    }
                </div>
            ),
            showArrow: false
        },
        {
            key: '2',
            label: (
                <div className={'investors_list_tag'}>
                    <span>{t('founded')}</span>
                    <img
                        src={tagObj.coinStatus ? require('@/assets/image/project/jian.png') : require('@/assets/image/project/jia.png')}
                        alt='icon'/>
                </div>
            ),
            children: (
                <div className={'included_radio_check'}>
                    <Input prefix={<SearchOutlined />} onChange={e=>getFoundedList(e.target.value)} className={"included_form_input investors_form_input"} placeholder={t('Search')}/>
                    {
                        foundedList.map((item,index)=> {
                            return (
                                <div onClick={()=>{
                                    setFoundedCheckList((e)=>{
                                        const list = [...e]
                                        const key = list.indexOf(item.value)
                                        if (key !== -1) {
                                            list.splice(key,1)
                                            return list
                                        }
                                        list.push(item.value)
                                        return list
                                    })
                                }} className={'investors_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                    {
                                        foundedCheckList.indexOf(item.value) !== -1 &&
                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                    }
                                    {
                                        foundedCheckList.indexOf(item.value) === -1 &&
                                        (<div className={'radio_no_check'}></div>)
                                    }
                                    <span>{item.label}</span>
                                </div>
                            )
                        })
                    }
                </div>
            ),
            showArrow: false
        },
        {
            key: '3',
            label: (
                <div className={'investors_list_tag'}>
                    <span>{t('TimePeriodOfRounds')}</span>
                    <img
                        src={tagObj.financingStatus ? require('@/assets/image/project/jian.png') : require('@/assets/image/project/jia.png')}
                        alt='icon'/>
                </div>
            ),
            children: (
                <div className={'included_radio_check'}>
                    <Input prefix={<SearchOutlined />} onChange={e=>getTimeList(e.target.value)} className={"included_form_input investors_form_input"} placeholder={t('Search')}/>
                    {
                        timeList.map((item,index)=> {
                            return (
                                <div onClick={()=>{
                                    setTimeCheckList((e)=>{
                                        const list = [...e]
                                        const key = list.indexOf(item.value)
                                        if (key !== -1) {
                                            list.splice(key,1)
                                            return list
                                        }
                                        list.push(item.value)
                                        return list
                                    })
                                }} className={'investors_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                    {
                                        timeCheckList.indexOf(item.value) !== -1 &&
                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                    }
                                    {
                                        timeCheckList.indexOf(item.value) === -1 &&
                                        (<div className={'radio_no_check'}></div>)
                                    }
                                    <span>{item.label}</span>
                                </div>
                            )
                        })
                    }
                </div>
            ),
            showArrow: false
        },
        {
            key: '4',
            label: (
                <div className={'investors_list_tag'}>
                    <span>{t('Region')}</span>
                    <img
                        src={tagObj.Region ? require('@/assets/image/project/jian.png') : require('@/assets/image/project/jia.png')}
                        alt='icon'/>
                </div>
            ),
            children: (
                <div className={'included_radio_check'}>
                    <Input prefix={<SearchOutlined />} onChange={e=> {
                        if (!e.target.value) {
                            setRegionList([])
                            setRegion([])
                        }
                        getRegionList(e.target.value)
                    }} className={"included_form_input investors_form_input"} placeholder={t('Search')}/>
                    {
                        regionList.map((item,index)=> {
                            return (
                                <div onClick={()=>{
                                    setRegionCheckList((e)=>{
                                        const list = [...e]
                                        const key = list.indexOf(item.value)
                                        if (key !== -1) {
                                            list.splice(key,1)
                                            return list
                                        }
                                        list.push(item.value)
                                        return list
                                    })
                                }} className={'investors_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                    {
                                        regionCheckList.indexOf(item.value) !== -1 &&
                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                    }
                                    {
                                        regionCheckList.indexOf(item.value) === -1 &&
                                        (<div className={'radio_no_check'}></div>)
                                    }
                                    <span>{item.label}</span>
                                </div>
                            )
                        })
                    }
                </div>
            ),
            showArrow: false
        },
    ];

    const getList = useSyncCallback(()=>{
        investorApi.queryInvestorList({
            pageNum,
            pageSize: 12,
            lang: lang.value,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
            label: tagCheckList.length > 0?tagCheckList.toString():void 0,
            location: regionCheckList.length > 0?regionCheckList.toString():void 0,
            establishTime: foundedCheckList.length > 0?foundedCheckList.toString():void 0,
            name: void 0,
            timeFrame: timeCheckList.length > 0?timeCheckList.toString():void 0
        }).then(res=>{
            setTableData(e=>{
                let obj = {...e}
                obj.list = res.rows
                obj.total = res.total
                return {...obj}
            })
        })
    })
    function changeTag(e) {
        const data = {
            tag: false,
            coinStatus: false,
            financingStatus: false,
            Region: false
        }
        for(const item of e){
            switch (item) {
                case '1':
                    data.tag = true
                    break
                case '2':
                    data.coinStatus = true
                    break
                case '3':
                    data.financingStatus = true
                    break
                case '4':
                    data.Region = true
                    break
            }
        }
        setTagObj(()=>data)
    }

    function changePage(page) {
        setPageNum(page)
        getList()
    }
    return (
        <div className={'main_back'}>
            <PageHeader grayBorder={true} other={true}></PageHeader>
            <div className={'investors_list_main_view'}>
                <div className={'investors_list_left_menu'}>
                    <div className={'investors_list_left_menu_top_grid'}>
                        <div className={'investors_list_left_menu_top_grid_item'} onClick={() => checkHottest(0)}>
                            {requestType !== 0 && (
                                <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                            {(requestType === 0 && requestSort === 1) && (
                                <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                            {(requestType === 0 && requestSort === 0) && (
                                <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                            {t('Hottest')}
                        </div>
                        <div className={'investors_list_left_menu_top_grid_item'} onClick={() => checkRecently(1)}>
                            {requestType !== 1 && (
                                <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                            {(requestType === 1 && requestSort === 1) && (
                                <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                            {(requestType === 1 && requestSort === 0) && (
                                <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                            {t('Recently')}
                        </div>
                        <div className={'investors_list_left_menu_top_grid_item'}>
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>
                            {t('Portfolio')}
                        </div>
                        <div className={'investors_list_left_menu_top_grid_item'}>
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>
                            {t('Like')}
                        </div>
                        <div className={'investors_list_left_menu_top_grid_item'}>
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>
                            {t('collect')}
                        </div>
                    </div>
                    <Collapse items={items} bordered={false} onChange={changeTag}/>
                </div>
                <div className={'investors_list_right_table'}>
                    {
                        (tagCheckList.length > 0 || foundedCheckList.length > 0 || timeCheckList.length > 0 || regionCheckList.length > 0) && (
                            <div className={'investors_tag_list'}>
                                <div className={'investors_tag_list_clear_btn'} onClick={()=>{
                                    setTagCheckList([])
                                    setFoundedCheckList([])
                                    setTimeCheckList([])
                                    setRegionCheckList([])
                                }}>{t('clearAll')}</div>
                                {
                                    tagCheckList.map((item, index) => {
                                        let labelName;
                                        for (const data of tagList) {
                                            if (data.value === item) {
                                                labelName = data.label
                                                break;
                                            }
                                        }
                                        return (
                                            <div className={'investors_tag_list_clear_btn_tag'}>
                                                {labelName}
                                                <img src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    foundedCheckList.map((item, index) => {
                                        return (
                                            <div className={'investors_tag_list_clear_btn_tag'}>
                                                {item}
                                                <img src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    timeCheckList.map((item, index) => {
                                        let labelName;
                                        for (const data of timeList) {
                                            if (data.value === item) {
                                                labelName = data.label
                                                break;
                                            }
                                        }
                                        return (
                                            <div className={'investors_tag_list_clear_btn_tag'}>
                                                {labelName}
                                                <img src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    regionCheckList.map((item, index) => {
                                        return (
                                            <div className={'investors_tag_list_clear_btn_tag'}>
                                                {item}
                                                <img src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    <div className={'investors_list_right_table_block'} style={tagCheckList.length === 0?{paddingTop: 57}:{paddingTop: 7}}>
                        {
                            tableData.list.map((item,index)=>{
                                return (
                                    <Link to={`/investors/detail/${item.id}`}>
                                        <div className={'investors_table_item'}>
                                            <div className={'investors_table_item_top'}>
                                                <img src={baseUrl+item.logoUrl} alt='logo'/>
                                                <div className={'investors_table_item_text'}>
                                                    <div className={'investors_table_item_title'}>{item.name}</div>
                                                    <div className={'investors_table_item_content investors_table_item_content_overflow'}>{item.content}</div>
                                                </div>
                                            </div>
                                            <div className={'investors_table_item_bottom'}>
                                                <div>
                                                    <span>{t('Founded')}</span>
                                                    <span className={'investors_table_item_bottom_data'}>{dayjs(item.establishTime).format('YYYY')}</span>
                                                </div>
                                                <div>
                                                    <span>{t('Portfolio')}</span>
                                                    <span className={'investors_table_item_bottom_data'}>{item.investDetailsList.length}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                    <div className={'investors_table_page'}>
                        <Pagination total={tableData.total} size={12} page={pageNum} onChange={changePage}></Pagination>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default List;
