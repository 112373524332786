import "../../Included/included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, DatePicker, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import { SearchOutlined } from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Radio from '@/components/Radio/index'
import useSyncCallback from "../../../utils/useSyncCallback";
import characterApi from "../../../api/character";
import meetingApi from "../../../api/meeting";
import dayjs from "dayjs";
import message from '@/utils/message'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {meetingContract, playerContract, web3} from "../../../utils/web3Utils";
import {projectTypeObj} from "../../../enum/projectType";
import commonApi from "../../../api/common";
import userApi from "../../../api/user";
import {auditStatusObj} from "../../../enum/auditStatus";
import {getTranslation, setReview} from "../../../utils/middle";
import Language from "../../../components/Language";
import Modal from "../../../components/Modal";
import investorApi from "../../../api/investor";


function Meeting() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {id}=useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [form] = Form.useForm()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const language = useSelector(state=>state.language.value)
    const userInfo = useSelector(state=>state.user.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [operation,setOperation] = useState(null)
    const [remarkModel, setRemarkModel] = useState(false)
    const [auditReport,setAuditReport] = useState(null)
    const [eventList,setEventList] = useState([])
    const [memberPage,setMemberPage] = useState(1)
    const [memberlList, setMemberList] = useState([])
    const [memberListData,setMemberListData] = useState([])
    const [checkMemberList,setCheckMemberList] = useState([])
    const [investmentList,setInvestmentList] = useState([])
    const [staked, setStaked] = useState(0)
    const [meetingUrl, setMeetingUrl] = useState('')
    const [theme, setTheme] = useState('')
    const [website, setWebsite] = useState('')
    const [content, setContent] = useState('')
    const [timePicker, setTimePicker] = useState('')
    const [source, setSource] = useState('')
    const [teamMember,setTeamMember] = useState([])
    const [mainId,setMainId] = useState('')
    const [createBy, setCreateBy] = useState('')
    const [createTime, setCreateTime] = useState('')
    const [spinning, setSpinning] = useState(false)
    const [thisId, setThisId] = useState('')
    const [lang, setLang] = useState(0)
    const [oldLang, setOldLang] = useState(0)
    const [qmHash, setQmHash] = useState('')

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                if (searchParams.get("type")){
                    getAuditDetail()
                }else {
                    getDetail()
                }
                getMember()
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                })
            })
        }
    },[])

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    const getMember = useSyncCallback((name = void 0)=>{
        characterApi.queryCharacterList({
            pageNum: memberPage,
            pageSize: 10,
            name,
            sort: 0,
            lang: 0,
            type: 0
        }).then(res=>{
            let member = res.rows.map(item=>{
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setMemberList([...member])
            setMemberListData([...res.rows])
        })
    })

    const addMemberValue = useSyncCallback(()=>{
        let list = memberListData.filter(item=>{
            for (const data of teamMember) {
                if (item.id === data) return item
            }
        })
        setCheckMemberList([...list])
    })

    const delMemberValue = useSyncCallback((id)=>{
        let list = teamMember.filter(item=>item !== id)
        setTeamMember([...list])
        addMemberValue()
    })

    const submitForm = useSyncCallback((type)=>{
        setSpinning(true)
        const data = {
            mainId,
            meetingUrl,theme,website,content,startTime: dayjs((timePicker[0])).format('YYYY-MM-DD'),endTime: dayjs((timePicker[1])).format('YYYY-MM-DD'),source,participants: JSON.stringify(checkMemberList),lang: 0,submitType: type,remark: projectTypeObj.meeting.type,createBy,createTime,updateTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        if (type === 0) {
            meetingApi.updateMeetingDraft({...data, id: thisId}).then(res=>{
                setSpinning(false)
                message.success(t('saveSuccess'))
            }).catch(err=>{
                meetingApi.submitMeetingForm(data).then(res=>{
                    setSpinning(false)
                    message.success(t('saveSuccess'))
                })
            })
        }else if (type === 1) {
            if (staked < 100 ) {
                setSpinning(false)
                message.fail(t('NoIncluded'))
                return navigate("/user/wiki")
            }
            meetingContract.methods.projectBase(mainId).call().then(res=>{
                const dataBase = web3.utils.fromWei(res["0"],'ether')
                const firstQmHash = res.firstQmHash
                console.log(res)
                if ((dataBase === '0.') || (qmHash === firstQmHash && searchParams.get("update")==='1')) {
                    data.mainId = void 0
                    meetingApi.submitMeetingForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")}).then(res=>{
                        const obj = res.data
                        meetingContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        if(searchParams.get("draftId")) {
                                            userApi.delUserDraft(searchParams.get("draftId")).then(res=>{
                                                navigate(-1)
                                            })
                                        }
                                    })
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                            }
                            setSpinning(false)
                        }).catch((err)=>{
                            console.log(err)
                            meetingApi.delMeeting(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }else {
                    if(createBy == userInfo.userId && searchParams.get("update") !== '1') {
                        setSpinning(false)
                        return message.fail(t("createUpdateFail"))
                    }
                    meetingApi.updateMeetingDetail({...data,oldLang, oldId: thisId}).then(res=>{
                        if (res.data.msg) {
                            setSpinning(false)
                            if (language.value === "0"){
                                return getTranslation(res.data.msg,'zh','en',(res)=>{
                                    message.fail(res.data.TargetText)
                                })
                            }else {
                                return message.fail(res.data.msg)
                            }

                        }
                        const obj = res.data
                        meetingContract.methods.updateWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                    })
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                            }
                            setSpinning(false)
                        }).catch((err)=>{
                            console.log(err)
                            meetingApi.delMeeting(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }
            })
        }else {
            setReview(data,(res)=>{
                console.log(res)
                navigate('/meeting/review?id='+res.data)
                setSpinning(false)
            })
        }
    })

    const getAuditDetail = useSyncCallback(()=>{
        meetingApi.queryMeetingDetailFromDB({id}).then(res=>{
            console.log(res)
            setMeetingUrl(res.data.meetingUrl)
            setTheme(res.data.theme)
            setWebsite(res.data.website)
            setContent(res.data.content)
            setTimePicker([dayjs(res.data.startTime),dayjs(res.data.endTime)])
            setSource(res.data.source)
            setCheckMemberList(JSON.parse(res.data.participants))
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            setThisId(res.data.id)
            setLang(res.data.lang)
            setOldLang(res.data.lang)
            setQmHash(res.data.qmHash)
            setTeamMember(()=>{
                return JSON.parse(res.data.participants).map(item=>{
                    return item.id
                })
            })
            setMainId(res.data.mainId)
            form.setFieldsValue({
                ConferenceTheme: res.data.theme,
                ConferenceWebsite: res.data.website,
                Contents: res.data.content,
                MeetingTime: [dayjs(res.data.startTime),dayjs(res.data.endTime)],
                source: res.data.source
            })
        })
    })

    const getDetail = useSyncCallback(()=>{
        meetingApi.queryMeetingDetail({id}).then(res=>{
            console.log(res)
            setMeetingUrl(res.data.meetingUrl)
            setTheme(res.data.theme)
            setWebsite(res.data.website)
            setContent(res.data.content)
            setTimePicker([dayjs(res.data.startTime),dayjs(res.data.endTime)])
            setSource(res.data.source)
            setCheckMemberList(JSON.parse(res.data.participants))
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            setThisId(res.data.id)
            setLang(res.data.lang)
            setQmHash(res.data.qmHash)
            setOldLang(res.data.lang)
            setTeamMember(()=>{
                return JSON.parse(res.data.participants).map(item=>{
                    return item.id
                })
            })
            setMainId(res.data.mainId)
            form.setFieldsValue({
                ConferenceTheme: res.data.theme,
                ConferenceWebsite: res.data.website,
                Contents: res.data.content,
                MeetingTime: [dayjs(res.data.startTime),dayjs(res.data.endTime)],
                source: res.data.source
            })
        })
    })

    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title'}>{t('MeetingTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <Language lang={lang} onChange={e => setLang(e)}></Language>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form}>
                        <Form.Item name="MeetingDiagram"
                                   label={labelNode(t('MeetingDiagram'), t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={e => {
                                setMeetingUrl(e.data)
                            }}>
                                {
                                    !meetingUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    meetingUrl && (
                                        <img src={baseUrl + meetingUrl} className={'included_upload_logo'} alt='icon'/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'ConferenceTheme'}
                                       label={labelNode(t('ConferenceTheme'))}
                                       rules={[{required: true}]}>
                                <Input value={theme} onChange={e => setTheme(e.target.value)}
                                       className={"included_form_input"} placeholder={t("projectNamePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'ConferenceWebsite'}
                                       label={labelNode(t('ConferenceWebsite'))} rules={[{required: true}]}>
                                <Input className={"included_form_input"} value={website}
                                       onChange={e => setWebsite(e.target.value.replace(/\s/g, ""))}
                                       placeholder={t("ConferenceWebsitePlaceholder")}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'Contents'} label={labelNode(t('Contents'))} rules={[{required: true}]}>
                            <Input.TextArea className={'included_form_textarea included_form_input'} value={content}
                                            onChange={e => setContent(e.target.value)}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'MeetingTime'} label={labelNode(t('MeetingTime'))}
                                       rules={[{required: true}]}>
                                <DatePicker.RangePicker defaulValue={timePicker} value={timePicker} showTime
                                                        onChange={e => {
                                                            setTimePicker(e)
                                                        }} className={"included_form_input included_form_datepicker"}
                                                        inputReadOnly/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'source'}
                                       label={labelNode(t('source'))} rules={[{required: true}]}>
                                <Input value={source} onChange={e => setSource(e.target.value)}
                                       className={"included_form_input"}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'Participants'} label={labelNode(t('Participants'))}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false}
                                    className={'included_form_add_input included_form_input'}
                                    options={memberlList} onSearch={getMember} value={teamMember} onChange={e => {
                                    setTeamMember(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addMemberValue}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_form_team_list'}>
                                {
                                    checkMemberList.map((item, index) => {
                                        return (
                                            <div className={'included_form_team_list_item'}>
                                                <img src={baseUrl + item.headImgUrl}
                                                     style={{width: 60, height: 60, borderRadius: '50%'}}
                                                     alt='logo'/>
                                                <div>
                                                    <div
                                                        className={'included_form_team_list_item_name'}>{item.name}</div>
                                                    <div
                                                        className={'included_form_team_list_item_content text-overflow-line-2-style'}>{item.content}</div>
                                                </div>
                                                <img className={'included_form_team_list_item_media'}
                                                     src={require('../../../assets/image/included/media_logo.png')}
                                                     alt='logo'/>
                                                <img className={'included_tag_item_close'}
                                                     onClick={() => delMemberValue(item.id)}
                                                     src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Form>
                    <div className={'included_form_handler_button'}>
                        <div className={'included_form_handler_button_left'}>
                            <div onClick={() => submitForm(2)}
                                 className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                            <div onClick={() => submitForm(0)}
                                 className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                        </div>
                        <div onClick={() => {
                            meetingApi.queryCheckName(theme).then(res=>{
                                console.log(res)
                                if(res.data === 0){
                                    submitForm(1)
                                }else {
                                    if(searchParams.get("type") === "draft") {
                                        setRemarkModel(true)
                                    }else {
                                        submitForm(1)
                                    }
                                }
                            })
                        }}
                             className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default Meeting;
