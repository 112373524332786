import './login_module.scss'
import {Button, Input} from "antd";
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import loginApi from '@/api/login'
import message from '@/utils/message'
import { useDispatch } from 'react-redux'
import {Login} from '@/features/auth/authSlice'

function Sign({onButtonClick}) {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [signType,setSignType] = useState(0)
    const [userName,setUserName] = useState('')
    const [password,setPassword] = useState('')
    const [difficulty,setDifficulty] = useState(0)
    const [code,setCode] = useState('')
    const [codeText,setCodeText] = useState(t("getCode"))
    const [codeInterval,setCodeInterval] = useState(null)
    const [flag,setFlag] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [inviteCode, setInviteCode] = useState()
    const [inviteId, setInviteId] = useState()
    const passwordStatus = [
        {type: 'Week',status: 0},
        {type: 'Medium',status: 1},
        {type: 'Medium',status: 1},
        {type: 'Strong',status: 2},
        {type: 'Strong',status: 2},
    ]

    useEffect(() => {
        if (searchParams.get("invite")){
            setInviteCode(searchParams.get("invite"))
            setInviteId(searchParams.get("inviteId"))
        }
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(codeInterval)
        };
    }, [signType])

    function handleClick() {
        onButtonClick(1)
    }

    function toSignUp() {
        console.log('注册')
        let data = {
            userName,
            password,
            code,
            remark: inviteCode,
            invitationId: inviteId
        }
        console.log(data)
        loginApi.userRegistration(data).then(()=>{
            message.success(t("success"))
            setSignType(0)
        })
    }

    function getEmailCode() {
        if (flag) return
        let data = {
            email:userName
        }
        loginApi.getVerificationCode(data).then(()=> {
            message.success(t("success"))
            setFlag(true)
            let count = 60
            const interval = setInterval(()=>{
                count--
                setCodeText(`${t('Resend')}(${count})`)
                if (count === 0) {
                    setCodeText(t('getCode'))
                    clearInterval(interval)
                    setFlag(false)
                }
            },1000)
            setCodeInterval(interval)
        })
    }

    function toSignIn() {
        console.log('登录')
        let data = {
            username: userName,
            password
        }
        loginApi.userLogin(data).then(res=>{
            message.success(t("success"))
            dispatch(Login(res.data.tokenValue))
            navigate('/index',{replace: true})
        })
    }

    return (
        <>
            <div className={'login_input_block'}>
                <div className={'login_sign_type'}>
                    <div onClick={()=>{setSignType(0)}} className={`${signType === 0?'login_sign_check':''}`}>{t("signIn")}</div>
                    <div onClick={()=>{setSignType(1)}} className={`${signType === 1?'login_sign_check':''}`}>{t("signUp")}</div>
                </div>
                {
                    signType === 0 && (
                        <>
                            <div className={'login_input'} style={{marginBottom: '36px'}}>
                                <div className={'login_input_label'}>{t("Email")}</div>
                                <Input className={"login_input_el"} value={userName} onChange={(e)=>{setUserName(e.target.value)}}/>
                            </div>
                            <div className={'login_input'}>
                                <div className={'login_input_label'}>{t("Password")}<span onClick={()=>onButtonClick(2)}>{t("forgetPwd")}</span></div>
                                <Input type="password" className={"login_input_el"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                            </div>
                            <Button onClick={toSignIn} className={'login_sign_button'}>{t("signIn")}</Button>
                            <div onClick={()=>{setSignType(1)}} className={'login_input_no_account'}>{t("noAccount")}<span>{t("signUp")}</span></div>
                        </>
                    )
                }
                {
                    signType === 1 && (
                        <>
                            <div className={'login_input'} style={{marginBottom: '36px'}}>
                                <div className={'login_input_label'}>{t("Email")}</div>
                                <Input className={"login_input_el"} value={userName} onChange={(e)=>{setUserName(e.target.value)}}/>
                            </div>
                            <div className={'login_input'} style={{marginBottom: '36px'}}>
                                <div className={'login_input_label'}>{t("VerificationCode")}</div>
                                <div className={'login_div_el'}>
                                    <Input className={"login_input_el"} value={code} onChange={(e)=>{setCode(e.target.value)}}/>
                                    <Button onClick={getEmailCode} className={"login_email_getCode"}>{codeText}</Button>
                                </div>
                            </div>
                            <div className={'login_input'}>
                                <div className={'login_input_label'}>{t("Password")}</div>
                                <Input type="password" className={"login_input_el"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                                {
                                    password && (
                                        <div className={'login_password_strong_block'}>
                                            {
                                                passwordStatus.map(item=>{
                                                    return <div className={`login_password_strong ${item.status <= difficulty?'login_password_strong_color_'+difficulty:''}` }></div>
                                                })
                                            }
                                            <div className={`login_password_strong_font_${difficulty}`}>{difficulty === 0?'Week':difficulty === 1?'Medium':'Strong'}</div>
                                        </div>
                                    )
                                }
                            </div>
                            <Button onClick={toSignUp} className={'login_sign_button'} style={{marginTop: '55px'}}>{t("signUp")}</Button>
                            <div onClick={()=>{setSignType(0)}} className={'login_input_no_account'}>{t("haveAccount")}<span>{t("signIn")}</span></div>
                        </>
                    )
                }
                <div className={'login_block_or'}>Or</div>
                <div className={'login_ConnectWallet_button'} onClick={handleClick}>
                    <img className={'login_wallet_icon'} src={require("@/assets/image/login/wallet.png")} alt='wallet'/>
                    {t("ConnectWallet")}
                </div>
            </div>
        </>
    )
}

export default Sign;
