import './kol_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import { Input } from 'antd'
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import kolApi from '@/api/kol'
import projectsApi from '@/api/projects'
import useSyncCallback from "@/utils/useSyncCallback";
import message from "@/utils/message";
import dayjs from "dayjs";
import commonApi from "@/api/common";
import {projectTypeIndex, projectTypeObj} from "../../../enum/projectType";
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import CreateInfo from "../../../components/CreateInfo";
import {getReview} from "../../../utils/middle";
import {getMediaIcon} from "../../../enum/mediaType";

function Review() {
    const {id} = useParams()
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [createInfo, setCreateInfo] = useState({})
    const [detailInfo,setDetailInfo] = useState({
        smcList: []
    })

    useEffect(()=>{
        getKolDetail()
    },[])


    const getKolDetail = useSyncCallback(()=> {
        getReview(searchParams.get("id"),(res)=>{
            setDetailInfo({...res.data})
        })
    })

    return (
        <div className='main_back main_back_white'>
            <PageHeader other={true}></PageHeader>
            <div className='kol_detail_main_block'>

                <div className={'router_line_block'}>
                    <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180' alt='arrow'/>
                    <Link to={-1}>
                        <span className={'router_line_block_color'}>{t('back')}</span>
                    </Link>
                </div>
                <div className='kol_detail_main'>
                    <div className='kol_detail_main_left'>
                        <div className='kol_detail_left_info'>
                            <div className='kol_detail_left_info_content'>
                                <img className='kol_detail_info_image'
                                     src={baseUrl + detailInfo.kolUrl} alt='Empty'/>
                                <div className='kol_detail_info_text'>
                                    <div className='kol_detail_info_name'>
                                        {detailInfo.nickname}
                                        <img className='kol_detail_popular_figures_kol_icon'
                                             src={require('@/assets/image/kol/kol_icon.png')} alt='icon'/>
                                    </div>
                                    <div className='kol_detail_info_context'>{detailInfo.country}</div>
                                </div>
                            </div>
                        </div>
                        <div className='kol_detail_info_link_list'>
                            {
                                detailInfo.smcList.map(item => {
                                    return (item.webUrl&&
                                        <div onClick={() => {
                                            let url = item.webUrl
                                            if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                                window.open(item.webUrl)
                                            }else {
                                                window.open("http://"+url.split("://")[0])
                                            }
                                        }} className='kol_detail_info_link'>
                                            <img
                                                src={getMediaIcon(item.webType, color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='kol_detail_details'>
                            <div className='kol_detail_details_title'>{t('details')}</div>
                            <div className='kol_detail_details_content'>{detailInfo.content}</div>
                        </div>
                    </div>
                    <div className='kol_detail_main_right'>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Review;
