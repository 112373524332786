import '../../Project/Detail/project_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "../../../utils/useSyncCallback";
import moreApi from "../../../api/more";
import dayjs from "dayjs";
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import {getReview} from "../../../utils/middle";
import {getMediaIcon} from "../../../enum/mediaType";

function Detail() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [searchParams,setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({})

    const getDetail = useSyncCallback(()=>{
        getReview(searchParams.get("id"),(res)=>{
            setDetailInfo(res.data)
        })
    })

    useEffect(() => {
        getDetail()
    }, []);
    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>

                <div className={'router_line_block'}>
                    <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180' alt='arrow'/>
                    <Link to={-1}>
                        <span className={'router_line_block_color'}>{t('back')}</span>
                    </Link>
                </div>
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl + detailInfo.logoUrl} alt='Empty'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>{detailInfo.name}</div>
                                    <div className={'project_detail_info_context'}>{detailInfo.synopsis}</div>
                                    <div className={'project_detail_info_tag'}>
                                        {
                                            detailInfo.label && (
                                                detailInfo.label.split(',').map(item => {
                                                    return (
                                                        <div className={'project_detail_info_tag_item'}>
                                                            <img
                                                                src={color === 'white' ? require('@/assets/image/project/tool.png') : require('@/assets/image/project/tool_dark.png')}
                                                                alt='tool'/>{item}
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'project_detail_info_link_list'}>
                            {detailInfo.officialWebsite && <div className={'project_detail_info_link'} onClick={() => {
                                let url = detailInfo.officialWebsite
                                if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                    window.open(detailInfo.officialWebsite)
                                } else {
                                    window.open("http://" + url.split("://")[0])
                                }
                            }}>
                                <img
                                    src={getMediaIcon("Website", color)}/>
                                website
                            </div>}
                            {
                                detailInfo.reportAddress && (
                                    <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                             color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottomLeft"
                                             title={'Audit Report From XXX Company'}>
                                        <div className={'project_detail_info_link'}>
                                            <img src={require('@/assets/image/project/audit1.png')} alt='audit1'/>
                                            Audit Report
                                        </div>
                                    </Tooltip>
                                )
                            }
                        </div>
                        <div className={'project_detail_text_info'}>
                            {/*<div><span>{t('founded')}: </span>{dayjs(detailInfo.createTime).format('YYYY')}</div>*/}
                            <div><span>{t('ecosystem')}:</span> {detailInfo.ecology}</div>
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                        </div>
                    </div>
                    <div className={'project_detail_main_right'}></div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Detail;
