import '../user_module.scss'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function People(props) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const arrList = props.list || []
    const color = useSelector(state => state.color.value)
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_4'}>
                {
                    arrList.map((item)=>{
                        return (
                            <Link to={`/people/detail/${item.id}`}>
                                <div className={'collection_people_list_character_list_item'}>
                                    <img className={'collection_people_list_popular_figures_item_img'}
                                         src={baseUrl + item.headImgUrl}
                                         alt='logo'/>
                                    <div
                                        className={'collection_people_list_popular_figures_item_name'}>{item.name}</div>
                                    <div className={'collection_people_list_popular_figures_item_video_img'}>
                                        <img
                                            src={color === 'white' ? require('@/assets/image/people/twitter.png') : require('@/assets/image/people/twitter_dark.png')}/>
                                        <img
                                            src={color === 'white' ? require('@/assets/image/people/ins.png') : require('@/assets/image/people/ins_dark.png')}/>
                                    </div>
                                    {/*TODO tag那里获取*/}
                                    <div className={'collection_people_list_popular_figures_item_tag'}>Coin Center</div>
                                    <div className={'collection_people_list_popular_figures_item_tag2'}>Co-Founder</div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default People;
