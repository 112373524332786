import './investors_review_module.scss'
import {Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip, Dropdown} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import investorApi from "../../../api/investor";
import useSyncCallback from "../../../utils/useSyncCallback";
import dayjs from 'dayjs'
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import {getReview} from "../../../utils/middle";
import {getMediaIcon} from "../../../enum/mediaType";

function Detail() {
    const [searchParams,setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const [listType,setListType] = useState(1)
    const [detailInfo, setDetailInfo] = useState({})

    const items = [
        {
            key: '1',
            label: (
                <span className={'Dropdown_tag_text'}>All</span>
            ),
        },{
            key: '2',
            label: (
                <span className={'Dropdown_tag_text'}>with token</span>
            ),
        },{
            key: '3',
            label: (
                <span className={'Dropdown_tag_text'}>No token</span>
            ),
        }
    ]

    useEffect(()=>{
        getDetail()
    },[])

    const getDetail = useSyncCallback(()=>{
        getReview(searchParams.get('id'),(res)=>{
            console.log(res)
            setDetailInfo(res.data)
        })
    })

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'investors_detail_main_block'}>

                <div className={'router_line_block'}>
                    <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180' alt='arrow'/>
                    <Link to={-1}>
                        <span className={'router_line_block_color'}>{t('back')}</span>
                    </Link>
                </div>
                <div className={'investors_detail_main'}>
                    <div className={'investors_detail_main_left'}>
                        <div className={'investors_detail_left_info'}>
                            <div className={'investors_detail_left_info_content'}>
                                <img className={'investors_detail_info_image'}
                                     src={baseUrl + detailInfo.logoUrl} alt='Empty'/>
                                <div className={'investors_detail_info_text'}>
                                    <div className={'investors_detail_info_name'}>{detailInfo.name}</div>
                                    <div
                                        className={'investors_detail_info_context text-overflow-style'}>{detailInfo.content}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'investors_detail_info_link_list'}>
                            {detailInfo.officialWebsite && <div className={'investors_detail_info_link'} onClick={() => {
                                let url = detailInfo.officialWebsite
                                if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                    window.open(detailInfo.officialWebsite)
                                } else {
                                    window.open("http://" + url.split("://")[0])
                                }
                            }}>
                                <img src={getMediaIcon("Website", color)}/>
                                Website
                            </div>}
                            {
                                detailInfo.smcList?.map((item, index) => {
                                    return (item.webUrl &&
                                        <div className={'investors_detail_info_link'} onClick={() => {
                                            let url = item.webUrl
                                            if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                                window.open(item.webUrl)
                                            }else {
                                                window.open("http://"+url.split("://")[0])
                                            }
                                        }}>
                                            <img
                                                src={getMediaIcon(item.webType, color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={'investors_detail_text_info'}>
                            <div><span>{t('founded')}: </span>{dayjs(detailInfo.establishTime).format('YYYY')}</div>
                            <div style={{marginLeft: 75}}><span>{t('location')}: </span>{detailInfo.location}</div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'}>{t('details')}</div>
                            <div className={'investors_detail_details_content'}>{detailInfo.content}</div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'}>{t('TeamMembers')}</div>
                            <div className={'investors_form_team_list'}>
                                {
                                    detailInfo.workExperienceList && detailInfo.workExperienceList.map((item, index) => {
                                        return (
                                            <div key={index} className={'investors_form_team_list_item'}>
                                                <img src={baseUrl + item.url}
                                                     style={{width: 60, height: 60, borderRadius: '50%'}}
                                                     alt='logo'/>
                                                <div>
                                                    <div className={'investors_form_team_list_item_name'}>{item.name}</div>
                                                    <div>{item.post}</div>
                                                </div>
                                                <img className={'investors_form_team_list_item_media'}
                                                     src={require('../../../assets/image/included/media_logo.png')}
                                                     alt='logo'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'investors_detail_details'}>
                            <div className={'investors_detail_details_title'}>{t('KeyEvents')}</div>
                            <TimeLine list={detailInfo.eventList}/>
                        </div>
                        <div className={'investors_detail_details investors_detail_details_investments'}>
                            <div className={'investors_detail_details_title investors_detail_details_top_handle'}>
                                {t('Investments')}
                                <div className={'investors_detail_handle_btn'}>
                                    <div onClick={() => setListType(1)}
                                         className={listType === 1 ? 'investors_detail_handle_portfolio' : ''}>{t('Portfolio2')}</div>
                                    <div onClick={() => setListType(2)}
                                         className={listType === 2 ? 'investors_detail_handle_portfolio' : ''}>{t('Rounds')}</div>
                                </div>
                            </div>
                            <div className={'investors_detail_tag_check'}>
                                <div>
                                    Tag
                                    <img src={require('../../../assets/image/investors/nocheck_icon.png')}
                                         alt='noCheck'/>
                                </div>
                                <Dropdown menu={{items}} trigger={['click']}>
                                    <div>
                                        All
                                        <img src={require('../../../assets/image/investors/check_icon.png')}
                                             alt='check'/>
                                    </div>
                                </Dropdown>
                            </div>
                            {
                                listType === 1 ? (
                                    <div className={'investors_detail_investors_list'}>
                                        {
                                            detailInfo.investDetailsList?.map((item, index) => {
                                                return (
                                                    <div className={'investors_table_item_block'}>
                                                        <div className={'investors_table_item_top'}>
                                                            <img src={baseUrl + item.logo} alt='logo'/>
                                                            <div className={'investors_table_item_text'}>
                                                                <div
                                                                    className={'investors_table_item_title'}>{item.projectName}</div>
                                                                <div
                                                                    className={'investors_table_item_content'}>{item.synopsis}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : (
                                    <div style={{marginTop: 50}} className={'investors_overseas_table'}>
                                        <div className={'investors_form_bottom_table'}>
                                            <div className={'investors_form_bottom_table_head_line'}>
                                                <div className={'investors_form_bottom_table_head_sp'}>
                                                    <img src={require("../../../assets/image/included/icon_name.png")}
                                                         alt='name'/>
                                                    {t('name')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_round.png")}
                                                         alt='round'/>
                                                    {t('Round')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_amount.png")}
                                                         alt='amount'/>
                                                    {t('amount')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img
                                                        src={require("../../../assets/image/included/icon_valuation.png")}
                                                        alt='valuation'/>
                                                    {t('valuation')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_time.png")}
                                                         alt='time'/>
                                                    {t('date')}
                                                </div>
                                                <div className={'investors_form_bottom_table_head'}>
                                                    <img
                                                        src={require("../../../assets/image/included/icon_investor.png")}
                                                        alt='investor'/>
                                                    {t('leadInvestor')}
                                                </div>
                                            </div>
                                            {
                                                detailInfo.investDetailsList?.map((item, index) => {
                                                    return (
                                                        <div className={'investors_form_bottom_table_item_line'}
                                                             style={{marginBottom: 10}}>
                                                            <div className={'investors_form_bottom_table_item'}>
                                                                <img className={'investors_name_icon'}
                                                                     src={baseUrl + item.logo}
                                                                     alt='project1'/>
                                                                <span>{item.projectName}</span>
                                                                <img className={'investors_link_icon'}
                                                                     src={color === 'white' ? require('@/assets/image/project/link_icon.png') : require('@/assets/image/project/link_icon_dark.png')}
                                                                     alt='link'/>
                                                            </div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>{item.round}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>$ {item.amount}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>$ {item.valuation}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>{item.time}</div>
                                                            <div
                                                                className={'investors_form_bottom_table_item'}>{item.led == '1' ? 'YES' : item.led == '0' ? 'NO' : '--'}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={'investors_detail_main_right'}></div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Detail;
