import './language_components.scss'
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

function Language({lang,onChange}) {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <>
            {searchParams.get('type') === 'lang' && <div className='lang_block'>
                <div onClick={() => onChange("1")} className={lang.toString() === '1' ? 'lang_block_check' : ''}>{t('CN')}</div>
                <div onClick={() => onChange("0")} className={lang.toString() === '0' ? 'lang_block_check' : ''}>{t('EN')}</div>
            </div>}
        </>
    )
}

export default Language;
