import './project_history_module.scss'
import {useTranslation} from "react-i18next";
import PageHeader from "../../../components/PageHeader";
import PageFooter from "../../../components/PageFooter";
import { Table, Tooltip } from 'antd'
import Pagination from "../../../components/Pagination";
import {useSelector} from "react-redux";
import commonApi from '@/api/common'
import {useEffect, useState} from "react";
import useSyncCallback from "../../../utils/useSyncCallback";
import {useSearchParams} from "react-router-dom";

function History() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const lang = useSelector(state => state.language.value)
    const color = useSelector(state => state.color.value)
    const [dataSource,setDataSource] = useState([])
    const [searchParams,setSearchParams] = useSearchParams()
    const [pageNum, setPageNum] = useState(1)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: t('Avatar'),
            dataIndex: 'avatar',
            key: 'avatar',
            render: (text,record) => {
                if (text) {
                    return (<img src={baseUrl + text} className={'project_history_avatar'} alt='text'/>)
                }else {
                    return (
                        <div style={{margin: '0 auto'}} className={'pageHeader_avatar_image'}>
                            <span>{record.nickname ? record.nickname[0] : ''}</span>
                        </div>
                    )
                }
            },
        },
        {
            title: t('nickname'),
            dataIndex: 'nickname',
            key: 'nickname'
        },
        {
            title: 'IPFS',
            dataIndex: 'IPFS',
            key: 'IPFS',
            render: (text) => subText(text)
        },
        {
            title: 'TX Hash',
            dataIndex: 'TXHash',
            key: 'TXHash',
            render: (text) => subText(text)
        },
        {
            title: t('time'),
            dataIndex: 'time',
            key: 'time',
        },
    ];

    function subText(text) {
        if (!text) return;
        let content = text.toString()
        return (
            <Tooltip overlayInnerStyle={{color: color === 'white'?'#5D5D5D':'#C3CBD3', fontSize: 14, fontWeight: 500}}
                     color={color === 'white' ? '#FFFFFF' : '#0F1114'} placement="bottomLeft"
                     title={text}>
                <span className={'project_history_table_td'}>{content.substr(0, 6) + "..." + content.substr(36)}</span>
            </Tooltip>);
    }

    function changePage(e) {
        setPageNum(e)
        getHistory()
    }

    const getHistory = useSyncCallback(()=>{
        commonApi.queryCreateHistory({
            pageNum,
            pageSize: 9,
            status: 1,
            createBy: searchParams.get('create'),
            lang: lang.value
        }).then(res=>{
            console.log('res',res)
            setTotal(res.total)
            setDataSource(()=>{
                return res.rows.map(item=>{
                    return {
                        avatar:item.url,
                        nickname: item.nickName,
                        IPFS: item.qmHash,
                        TXHash: item.transactionHash,
                        time: item.createTime
                    }
                })
            })
        })
    })

    useEffect(() => {
        getHistory()
    }, []);

    return (
        <div className={'main_back'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_history_block'}>
                <div className={'project_history_block_title'}>
                    <span className={'project_history_block_title_large'}>{t('IncludeInformation')}</span>
                    <span>{t('HistoricalInclusion')}</span>
                </div>
                <Table pagination={false} dataSource={dataSource} columns={columns} />
                <div className={'project_history_page'}>
                    <Pagination total={total} size={9} page={pageNum} onChange={changePage}></Pagination>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default History;
