import request from '@/utils/request'

class Api {
    submitInvestorData(data) {
        return request.post('/investorInclusion/add',data)
    }

    queryInvestorList(data) {
        return request.get('/investorInclusion/esList',data)
    }

    queryInvestorDetail(data) {
        return request.get('/investorInclusion/getEsInfo',data)
    }

    queryInvestmentOverview(data) {
        return request.get('/investorInclusion/getInvestmentSurvey',data)
    }

    updateInvestmentOverview(data) {
        return request.post('/investorInclusion/update',data)
    }

    queryInvestmentDetailFromDB(data) {
        return request.get('/investorInclusion/getInfo',data)
    }

    delInvestor(id) {
        return request.del(`/investorInclusion/${id}`)
    }

    updateInvestorDraft(data) {
        return request.post('/investorInclusion/edit',data)
    }

    queryRecommendList(data) {
        return request.get('/investorInclusion/getjointInvestmentList',data)
    }

    queryCheckName(name) {
        return request.get('/investorInclusion/checkName',{name})
    }
}
export default new Api();
