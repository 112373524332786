import '../../../Meeting/Detail/meeting_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "@/utils/useSyncCallback";
import meetingApi from "@/api/meeting";
import dayjs from 'dayjs'
import Comment from "@/components/Comment";
import DetailHandle from "@/components/DetailHandle";
import {projectTypeObj} from "@/enum/projectType";
import CreateInfo from "@/components/CreateInfo";
import commonApi from "@/api/meeting";
import {getMediaIcon} from "../../../../enum/mediaType"

function Detail({id,newDetail}) {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const color = useSelector(state => state.color.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({})
    const [createInfo, setCreateInfo] = useState({})

    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        meetingApi.queryMeetingDetail({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        meetingApi.queryMeetingDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryMeetingDetailFromDB({id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    useEffect(() => {
        if (newDetail){
            getAuditDetail()
        }else {
            getDetail()
        }
    }, []);

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'meeting_detail_main_block'}>
                <RouterLine name={detailInfo.theme}/>
                <div className={'meeting_detail_main'}>
                    <div className={'meeting_detail_main_left'}>
                        <div className={'meeting_detail_left_info'}>
                            <div className={'meeting_detail_left_info_content'}>
                                <img className={'meeting_detail_info_image'} style={{borderRadius: 20}}
                                     src={baseUrl+detailInfo.meetingUrl} alt='project'/>
                            </div>
                        </div>
                        <div className={'meeting_detail_left_text'}>{detailInfo.theme}</div>
                        <div className={'meeting_detail_info_link_list'}>
                            <div className={'meeting_detail_info_link_time'}>
                                <img src={require('@/assets/image/meeting/meeting_time_icon.png')} alt='time' />
                                {dayjs(detailInfo.startTime).format('YYYY-MM-DD HH:mm')}~{dayjs(detailInfo.endTime).format('YYYY-MM-DD HH:mm')}
                            </div>
                            <div className={'meeting_detail_info_link'} onClick={()=>{
                                window.open(detailInfo.website)
                            }}>
                                <img
                                    src={getMediaIcon("Website",color)}/>
                                website
                            </div>
                        </div>

                        <div className={'meeting_detail_details_content'}>{detailInfo.content}</div>
                        <div className={'meeting_detail_details'}>
                            <div className={'meeting_detail_details_title'}>{t('Participants')}</div>
                            <div className={'meeting_form_team_list'}>
                                {
                                    JSON.parse(detailInfo.participants || '[]').map(item=>{
                                        return (
                                            <div className={'meeting_form_team_list_item'}>
                                                <img src={baseUrl+item.headImgUrl} style={{width: 60,height: 60,borderRadius: '50%'}}
                                                     alt='logo'/>
                                                <div style={{width: '100%'}}>
                                                    <div className={'meeting_form_team_list_item_name'}>{item.name}</div>
                                                    <div className={'meeting_form_team_list_item_content'}>{item.content}</div>
                                                </div>
                                                <img className={'meeting_form_team_list_item_media'}
                                                     src={require('@/assets/image/included/media_logo.png')}
                                                     alt='logo'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        <Comment id={id}></Comment>
                    </div>
                    <div className={'meeting_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'meeting_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'meeting_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('otherMeetings')}</span>
                            </div>
                            <div className={'meeting_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'meeting_contributor_title'}>This is the conference theme This isthe conference...</div>
                                    <div className={'meeting_contributor_text'}>
                                        <img src={require('@/assets/image/meeting/time_icon.png')} alt='time' />
                                        2024-11-15  11：00~2024-12-15   11：00
                                    </div>
                                </div>
                            </div>
                            <div className={'meeting_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'meeting_contributor_title'}>This is the conference theme This isthe
                                        conference...
                                    </div>
                                    <div className={'meeting_contributor_text'}>
                                        <img src={require('@/assets/image/meeting/time_icon.png')} alt='time'/>
                                        2024-11-15 11：00~2024-12-15 11：00
                                    </div>
                                </div>
                            </div>
                            <div className={'meeting_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'meeting_contributor_title'}>This is the conference theme This isthe
                                        conference...
                                    </div>
                                    <div className={'meeting_contributor_text'}>
                                        <img src={require('@/assets/image/meeting/time_icon.png')} alt='time'/>
                                        2024-11-15 11：00~2024-12-15 11：00
                                    </div>
                                </div>
                            </div>
                            <div className={'meeting_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'meeting_contributor_title'}>This is the conference theme This isthe
                                        conference...
                                    </div>
                                    <div className={'meeting_contributor_text'}>
                                        <img src={require('@/assets/image/meeting/time_icon.png')} alt='time'/>
                                        2024-11-15 11：00~2024-12-15 11：00
                                    </div>
                                </div>
                            </div>
                            <div className={'meeting_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'meeting_contributor_title'}>This is the conference theme This isthe
                                        conference...
                                    </div>
                                    <div className={'meeting_contributor_text'}>
                                        <img src={require('@/assets/image/meeting/time_icon.png')} alt='time'/>
                                        2024-11-15 11：00~2024-12-15 11：00
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'meeting_comment_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'meeting_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'meeting_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'meeting_textarea'}
                                    autoSize={{minRows: 6, maxRows: 6}}></Input.TextArea>
                    <div className={'meeting_handle_btn'}>
                    <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'meeting_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'meeting_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'meeting_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'meeting_comment_dialog_title'}>{t('update')}</div>
                    <div className={'meeting_detail_update_block'}>
                        <div className={'meeting_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'meeting_detail_update_btn_list'}>
                            <Link to={`/meeting/update/${id}`}>
                                <div>{t('updateContent')}</div>
                            </Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'meeting_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
